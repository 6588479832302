import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
  percentage: 0
};

const reducer = ( state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROGRESS_CHANGE:
      return updateObject(state, action.payload);
    default:
      return state
  }
};

export default reducer;
