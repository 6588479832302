import React, {Component} from "react";
import {
  Button, Card, Col, Container, Form, FormCheckbox, FormInput, FormRadio, FormSelect, ListGroup, ListGroupItem, Row
} from "shards-react";
import {connect} from 'react-redux'

import PageTitle from "../components/common/PageTitle";
import * as actions from '../store/actions'
import contentArrays from "../utils/arrays";


import validator from 'validator'

class NewUser extends Component {

  state = {
    form: {
      nivel: {
        value: 'analista',
        valid: false,
        shouldValidate: false,
        touched: false
      },
      nome: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 3
        }
      },
      email: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isEmail: true
        }
      },
      senha: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 6
        }
      },
      confirmarSenha: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          equals: 'senha'
        }
      },
      rg: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 9
        }
      },
      cpf: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 10
        }
      },
      funcao: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 3
        }
      },
      empresa: {
        value: null,
        valid: false,
        shouldValidate: false,
        touched: false,
      },
      acesso: {
        value: 'null',
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isIn: ['sp', 'spa', 'sp-spa']
        }
      },
      cnpj: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 10
        }
      },
      contato: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 10
        }
      },
      razaoSocial: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 3
        }
      },
      inscricaoEstadual: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 10
        }
      },
      prestadora: {
        value: null,
        valid: false,
        shouldValidate: false,
        touched: false,
      },
      cep: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 8
        }
      },
      estado: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 2
        }
      },
      cidade: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 2
        }
      },
      numero: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isInt: true
        }
      },
      logradouro: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 3
        }
      },
      bairro: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 3
        }
      },
      complemento: {
        value: null,
        valid: false,
        shouldValidate: false,
        touched: false,
      }
    }
  };

  componentDidMount() {
    this.props.onLoadCompaniesSimpleList();
    this.props.onLoadProvidersSimpleList();
  }

  onFormSendHandler = e => {
    e.preventDefault();
    let formData = new FormData();
    for (let key in this.state.form) {
      if (this.state.form.hasOwnProperty(key)) {
        let itemName = key;
        if (this.state.form[key].name) {
          itemName = this.state.form[key].name
        }
        if (this.state.form[key].store) {
          formData.append(itemName, this.state.form[key].store)
        } else {
          formData.append(itemName, this.state.form[key].value);
        }
        console.log(itemName, this.state.form[key]);
      }
    }
    console.log(formData)
    this.props.onSendNewUser(formData);

  };

  inputChangedHandler(e, key) {
    console.log(key);
    const obj = {...this.state.form[key]};
    const incomingValue = e.target.value;

    let valid = true;

    if (obj.validator) {

      if (obj.validator.isEmail) {
        valid = validator.isEmail(incomingValue) && valid;
      }
      if (obj.validator.isLength) {
        valid = validator.isLength(incomingValue,
          {min: obj.validator.isLength}) && valid;
      }
      if (obj.validator.equals) {
        valid = validator.equals(incomingValue,
          this.state.form[obj.validator.equals].value) && valid;
      }
      if (obj.validator.isIn) {
        valid = validator.isIn(incomingValue,
          obj.validator.isIn) && valid;
      }
      if (obj.validator.isInt) {
        valid = validator.isInt(incomingValue) && valid;
      }

    }

    obj.valid = valid;
    obj.value = incomingValue;
    obj.touched = true;

    console.log(obj);

    this.setState(oldState => ({
      form: {
        ...oldState.form,
        [key]: obj
      }
    }))
  }

  render() {
    const dadosEndereco = (<ListGroupItem className="p-3">
      <h6 className="m-0 pb-3">Endereço</h6>

      <Row form>
        <Col md="4" className="form-group">
          <label htmlFor="inputCEP">CEP</label>
          <FormInput
            id="inputCEP"
            placeholder="00000-000"
            invalid={!this.state.form.cep.valid && this.state.form.cep.touched && this.state.form.cep.shouldValidate}
            onChange={event => this.inputChangedHandler(event,
              'cep')}
          />
        </Col>
        <Col md="4" className="form-group">
          <label htmlFor="inputEstado">Estado</label>
          <FormSelect id="inputEstado" onChange={event => this.inputChangedHandler(event,
            'estado')}>
            <option>Escolha...</option>
            {contentArrays.estados.map(item => (<option value={item} key={item}>{item}</option>
            ))}
          </FormSelect>
        </Col>
        <Col md="4" className="form-group">
          <label htmlFor="inputNumero">Numero</label>
          <FormInput
            id="inputNumero"
            placeholder="113"
            invalid={!this.state.form.numero.valid && this.state.form.numero.touched && this.state.form.numero.shouldValidate}
            onChange={event => this.inputChangedHandler(event,
              'numero')}/>
        </Col>
      </Row>

      <Row form>
        <Col className="form-group">
          <label htmlFor="inputLogradouro">Logradouro</label>
          <FormInput
            id="inputLogradouro"
            placeholder="Rodovia São Miguel"
            invalid={!this.state.form.logradouro.valid && this.state.form.logradouro.touched && this.state.form.logradouro.shouldValidate}
            onChange={event => this.inputChangedHandler(event,
              'logradouro')}/>
        </Col>
        <Col className="form-group">
          <label htmlFor="inputCidade">Cidade</label>
          <FormInput
            id="inputCidade"
            placeholder="São João da Boa Vista"
            invalid={!this.state.form.cidade.valid && this.state.form.cidade.touched && this.state.form.cidade.shouldValidate}
            onChange={event => this.inputChangedHandler(event,
              'cidade')}/>
        </Col>
      </Row>

      <Row form>
        <Col md="6" className="form-group">
          <label htmlFor="inputBairro">Bairro</label>
          <FormInput
            id="inputBairro"
            placeholder="Jardim Ipiranga"
            invalid={!this.state.form.bairro.valid && this.state.form.bairro.touched && this.state.form.bairro.shouldValidate}
            onChange={event => this.inputChangedHandler(event,
              'bairro')}/>
        </Col>
        <Col md="6" className="form-group">
          <label htmlFor="inputComplemento">Complemento <small>(Não obrigatório)</small></label>
          <FormInput
            id="inputComplemento"
            placeholder="Bloco J"
            invalid={!this.state.form.numero.valid && this.state.form.numero.touched && this.state.form.numero.shouldValidate}
            onChange={event => this.inputChangedHandler(event,
              'complemento')}/>
        </Col>
      </Row>

    </ListGroupItem>);

    const dadosFuncionario = (<Row form>
      <Col md="3" className="form-group">
        <label htmlFor="inputRG">RG</label>
        <FormInput
          id="inputRG"
          placeholder="00.000.000-00"
          invalid={!this.state.form.rg.valid && this.state.form.rg.touched && this.state.form.rg.shouldValidate}
          onChange={event => this.inputChangedHandler(event,
            'rg')}/>
      </Col>
      <Col md="3" className="form-group">
        <label htmlFor="inputCPF">CPF</label>
        <FormInput
          id="inputCPF"
          placeholder="000.000.000-00"
          invalid={!this.state.form.cpf.valid && this.state.form.cpf.touched && this.state.form.cpf.shouldValidate}
          onChange={event => this.inputChangedHandler(event,
            'cpf')}/>
      </Col>
      <Col md="3" className="form-group">
        <label htmlFor="inputFuncao">Função</label>
        <FormInput
          id="inputFuncao"
          placeholder="Gerente"
          invalid={!this.state.form.funcao.valid && this.state.form.funcao.touched && this.state.form.funcao.shouldValidate}
          onChange={event => this.inputChangedHandler(event,
            'funcao')}
        />
      </Col>
      <Col md="3" className="form-group">
        <label htmlFor="inputEmpresa">Empresa</label>
        <FormSelect
          id="inputEmpresa"
          invalid={!this.state.form.empresa.valid && this.state.form.empresa.touched && this.state.form.empresa.shouldValidate}
          onChange={event => this.inputChangedHandler(event, 'empresa')}>
          <option>Escolha...</option>
          {this.props.misc.empresas ? this.props.misc.empresas.map(el => (
            <option value={el.id} key={el.id}>{el.nome}</option>
          )) : null}
        </FormSelect>
      </Col>
    </Row>);

    const dadosEmpresa = ([<Row form key='r1'>
      <Col md="4" className="form-group">
        <label htmlFor="inputAcesso">Acesso</label>
        <FormSelect id="inputAcesso"
                    invalid={!this.state.form.acesso.valid && this.state.form.acesso.touched && this.state.form.acesso.shouldValidate}
                    onChange={event => this.inputChangedHandler(event,
                      'acesso')}>
          <option value=''>Escolha...</option>
          <option value='sp'>Spray Plan</option>
          <option value='sp-spa'>Spray Plan e SPA</option>
          <option value='spa'>SPA</option>
        </FormSelect>
      </Col>
      <Col md="4" className="form-group">
        <label htmlFor="inputCNPJ">CNPJ</label>
        <FormInput
          id="inputCNPJ"
          placeholder="00.000.000/0000-00"
          invalid={!this.state.form.cnpj.valid && this.state.form.cnpj.touched && this.state.form.cnpj.shouldValidate}
          onChange={event => this.inputChangedHandler(event,
            'cnpj')}/>
      </Col>
      <Col md="4" className="form-group">
        <label htmlFor="inputContato">Contato</label>
        <FormInput
          id="inputContato"
          placeholder="(00) 0000-0000"
          invalid={!this.state.form.contato.valid && this.state.form.contato.touched && this.state.form.contato.shouldValidate}
          onChange={event => this.inputChangedHandler(event,
            'contato')}/>
      </Col>
    </Row>, <Row form key='r2'>
      <Col md="6" className="form-group">
        <label htmlFor="inputRazaoSocial">Razão Social</label>
        <FormInput
          id="inputRazaoSocial"
          placeholder="Loures e Lino LTDA"
          invalid={!this.state.form.razaoSocial.valid && this.state.form.razaoSocial.touched && this.state.form.razaoSocial.shouldValidate}
          onChange={event => this.inputChangedHandler(event,
            'razaoSocial')}/>
      </Col>
      <Col md="6" className="form-group">
        <label htmlFor="inputInscricaoEstadual">Inscrição Estadual</label>
        <FormInput
          id="inputInscricaoEstadual"
          type="text"
          placeholder="000.000.000.000"
          invalid={!this.state.form.inscricaoEstadual.valid && this.state.form.inscricaoEstadual.touched && this.state.form.inscricaoEstadual.shouldValidate}
          onChange={event => this.inputChangedHandler(event,
            'inscricaoEstadual')}/>
      </Col>
    </Row>]);

    const dadosPiloto = (<Row form>
      <Col md="4" className="form-group">
        <label htmlFor="inputPrestadora">Prestadora</label>
        <FormSelect
          id="inputPrestadora"
          onChange={event => this.inputChangedHandler(event,
            'prestadora')}>
          <option>Escolha...</option>
          {this.props.misc.prestadoras ? this.props.misc.prestadoras.map(element => (
            <option value={element.id} key={element.id}>{element.nome}</option>
          )) : <option>Carregando...</option>}
        </FormSelect>
      </Col>
    </Row>);


    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Novo Usuário" subtitle="Cadastro" className="text-sm-left"/>
        </Row>

        <Row>
          <Col>
            <Card small>
              <ListGroup flush>
                <ListGroupItem>
                  <Row>
                    <Col>
                      <Form>
                        <ListGroupItem className="p-3">

                          <h6 className="m-0 pb-3">Dados Gerais</h6>

                          <Row form>
                            <Col className="text-center pb-3">
                              <fieldset aria-required={true} onChange={event => this.inputChangedHandler(event,
                                'nivel')}>
                                <FormRadio inline name="nivel" value="analista" defaultChecked>Dominus Soli - Interno</FormRadio>
                                <FormRadio inline name="nivel" value="ministerio">Ministério Público / Outras Entidades</FormRadio>
                              </fieldset>
                            </Col>
                          </Row>

                          <Row form>
                            <Col md="6" className="form-group">
                              <label htmlFor="inputNome">Nome </label>
                              <FormInput
                                id="inputNome"
                                placeholder="John Doe"
                                invalid={!this.state.form.nome.valid && this.state.form.nome.touched && this.state.form.nome.shouldValidate}
                                onChange={event => this.inputChangedHandler(event,
                                  'nome')}/>
                            </Col>
                            <Col md="6" className="form-group">
                              <label htmlFor="inputEmail">Email</label>
                              <FormInput id="inputEmail" type="email" placeholder="pedro@dominio.com"
                                         invalid={!this.state.form.email.valid && this.state.form.email.touched && this.state.form.email.shouldValidate}
                                         onChange={event => this.inputChangedHandler(event,
                                           'email')}/>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6" className="form-group">
                              <label htmlFor="inputSenha">Senha</label>
                              <FormInput id="inputSenha" type="password" placeholder="Contendo no mínimo 6 caracteres"
                                         invalid={!this.state.form.senha.valid && this.state.form.senha.touched && this.state.form.senha.shouldValidate}
                                         onChange={event => this.inputChangedHandler(event,
                                           'senha')}/>
                            </Col>
                            <Col md="6" className="form-group">
                              <label htmlFor="inputConfirmarSenha">Confirmar Senha</label>
                              <FormInput id="inputConfirmarSenha" type="password"
                                         placeholder="Contendo no mínimo 6 caracteres"
                                         invalid={!this.state.form.confirmarSenha.valid && this.state.form.confirmarSenha.touched && this.state.form.confirmarSenha.shouldValidate}
                                         onChange={event => this.inputChangedHandler(event,
                                           'confirmarSenha')}/>
                            </Col>
                          </Row>
                        </ListGroupItem>

                        {['funcionario', 'empresa', 'piloto'].includes(this.state.form.nivel.value) ?
                          <ListGroupItem className="p-3">
                            <h6 className="m-0 pb-3">Dados Específicos</h6>

                            {this.state.form.nivel.value === 'funcionario' ? dadosFuncionario : null}
                            {this.state.form.nivel.value === 'empresa' ? dadosEmpresa : null}
                            {this.state.form.nivel.value === 'piloto' ? dadosPiloto : null}

                          </ListGroupItem> : null}
                        {['funcionario', 'empresa'].includes(this.state.form.nivel.value) ? dadosEndereco : null}

                        <ListGroupItem className="p-3">
                          <Row>
                            <small style={{color: 'red'}}>{this.props.misc.submit.user.error}</small>
                          </Row>
                          <Button type="submit" theme="success"
                                  onClick={e => this.onFormSendHandler(e)}>Cadastrar</Button>
                        </ListGroupItem>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onLoadCompaniesSimpleList: () => dispatch(actions.requestCompaniesSimpleList()),
    onLoadProvidersSimpleList: () => dispatch(actions.requestProvidersSimpleList()),
    onSendNewUser: (formData) => dispatch(actions.sendNewUser(formData))
  }
};
const mapStateToProps = state => {
  return {
    misc: state.misc
  }
};

export default connect(mapStateToProps,
  mapDispatchToProps)(
  NewUser);
