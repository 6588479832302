import React from "react";
import {Nav} from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import {Store} from "../../../flux";
import {connect} from "react-redux";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navItems: Store.getSidebarItems()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.onChange()
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    let isAnother = false;
    let nivel = this.props.authData.nivel;
    if (this.props.authData.nivel !== 'funcionario') {
      isAnother = true

    }
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(isAnother,
        nivel)
    });
  }

  render() {
    const {navItems: items} = this.state;
    const type = this.props.authData;

    const allowedItems = [];
    //
    //     // if (type.modalidade) {
    //     //   if (type.modalidade === 'sp') {
    //     //     if (ty)
    //     //   }else {
    //     //
    //     //   }
    //     // }

    if (type.nivel === 'moderador' || type.nivel === 'analista' || type.nivel === 'admin') {
      allowedItems.push('Principal',
        'Administração',
        'SPA Cadastros')
    } else if (type.nivel === 'funcionario') {
      if (type.modalidade === 'sp') {
        allowedItems.push('Principal',
          'Laudos')
      } else {
        if (type.funcao) {
          if (type.funcao.includes('[1]')) {
            allowedItems.push('Ambiental',
              'Nova OS',
              'Exibir Mapa',
              'Tabelas',
              'Aplicações',
              // 'Áreas de Proteção',
              'Ordens de Serviço');
          } else if (type.funcao.includes('[2]')) {
            allowedItems.push('Ambiental',
              'Exibir Mapa',
              'Tabelas',
              'Aplicações Pendentes',
              'Ordens de Serviço');
          } if (type.funcao.includes('[3]')) {
            allowedItems.push('Ambiental',
              'Exibir Mapa',
              'Tabelas',
              'Aplicações',
              // 'Áreas de Proteção',
              'Ordens de Serviço');
          }
        }
      }
    } else if (type.nivel === 'ministerio'){
      allowedItems.push(
        'Exibir Mapa',
        'Ambiental',
        'Tabelas',
        'Ordens de Serviço');
    }


    let hasAmbiental = false;
    let ambientalIdx = null;
    const filteredItems = items.filter((el, idx) => {
      if (el.title === 'Ambiental') {
        hasAmbiental = true;
      }
      return allowedItems.includes(el.title)
    });

    if (hasAmbiental) {
      filteredItems.forEach((el, idx) => {
        if (el.title === 'Ambiental') {
          ambientalIdx = idx;
        }
      });
      if (ambientalIdx !== null) {
        // console.log('asdasdasdas', filteredItems[ambientalIdx].items)
        filteredItems[ambientalIdx].items = filteredItems[ambientalIdx].items.filter(el => allowedItems.includes(el.title))
        // console.log('asdasdasdas', filteredItems[ambientalIdx].items)

        filteredItems[ambientalIdx].items.forEach((el, idx) => {
          if (el.title === 'Tabelas') {
            filteredItems[ambientalIdx].items[idx].items = filteredItems[ambientalIdx].items[idx].items.filter(el => allowedItems.includes(el.title))
          }
        });



      }
    }
    return (<div className="nav-wrapper">
        {filteredItems.map((nav, idx) => (<div key={idx}>
            <h6 className="main-sidebar__nav-title">{nav.title}</h6>
            {typeof nav.items !== "undefined" && nav.items.length && (<Nav className="nav--no-borders flex-column">
                {nav.items.map((item, idx) => (<SidebarNavItem key={idx} item={item}/>))}
              </Nav>)}
          </div>))}
      </div>)
  }
}


const mapStateToProps = state => {
  return {
    authData: state.auth.userInfo
  }
};

export default connect(mapStateToProps)(
  SidebarNavItems);
