import React from "react";
import {Redirect} from "react-router-dom";
// Layout Types
import {DefaultLayout, HeaderNavigation, IconSidebar, LoginLayout} from "./layouts";
// Route Views
import Analytics from "./views/Analytics";
import OnlineStore from "./views/OnlineStore";
import BlogOverview from "./views/BlogOverview";
import UserProfile from "./views/UserProfile";
import UserProfileLite from "./views/UserProfileLite";
import EditUserProfile from "./views/EditUserProfile";
import Login from "./views/Login";
import Register from "./views/Register";
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import FileManagerList from "./views/FileManagerList";
import FileManagerCards from "./views/FileManagerCards";
import TransactionHistory from "./views/TransactionHistory";
import Calendar from "./views/Calendar";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import HeaderNav from "./views/HeaderNavigation";
import IconSidebarView from "./views/IconSidebar";
// Custom Route Views
import Map from "./views/Map"
import NewApplicationReport from './views/NewApplicationReport'
import NewUser from './views/NewUser'
import ShowReports from './views/ShowReportsList';
import NewReactChart from './views/NewReactChart';
import NewServiceOrder from './views/NewServiceOrder'
import NewProtectedArea from './views/NewProtectedArea'
import AreaProtecaoLista from './views/AreaProtecaoLista'
import ShowAmbientalReportsList from './views/ShowAmbientalReportsList'
import OrdemServicoLista from './views/OrdemServicoLista';
import NewAmbientalData from "./views/NewAmbientalData";
import NewLocation from "./views/NewLocation";
import NewUserAmbiental from "./views/NewUserAmbiental";
import ManageOSList from "./views/ManageOSList";
import ManageUserList from "./views/ManageUserList";
import ManageAmbientalReportsList from "./views/ManageAmbientalReportsList";
import NewServiceCompany from "./views/NewServiceCompany";
import EffectivenessReport from './views/EffectivenessReport';

const BlankIconSidebarLayout = ({children}) => (
  <IconSidebar noNavbar noFooter>
    {children}
  </IconSidebar>
);

const LayoutWithoutNavigation = ({children}) => (
  <LoginLayout>
    {children}
  </LoginLayout>
);

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/map/ambiental"/>
  },
  {
    path: "/new-application-report",
    layout: DefaultLayout,
    component: NewApplicationReport
  },
  {
    path: "/new-ambiental-data",
    layout: DefaultLayout,
    component: NewAmbientalData
  },
  {
    path: "/new-user",
    layout: DefaultLayout,
    component: NewUser
  },
  {
    path: "/new-service-company",
    layout: DefaultLayout,
    component: NewServiceCompany
  },
  {
    path: "/show-reports",
    layout: IconSidebar,
    component: ShowReports
  },
  {
    path: "/show-ambiental-reports",
    layout: DefaultLayout,
    component: ShowAmbientalReportsList
  },
  {
    path: "/manage-ambiental-reports",
    layout: DefaultLayout,
    component: ManageAmbientalReportsList
  },
  {
    path: "/new-os",
    layout: DefaultLayout,
    component: NewServiceOrder
  },
  {
    path: "/show-os-list",
    layout: DefaultLayout,
    component: OrdemServicoLista
  },
  {
    path: "/manage-os-list",
    layout: DefaultLayout,
    component: ManageOSList
  },
  {
    path: "/manage-user-list",
    layout: DefaultLayout,
    component: ManageUserList
  },
  {
    path: "/new-area",
    layout: DefaultLayout,
    component: NewProtectedArea
  },
  {
    path: "/new-location",
    layout: DefaultLayout,
    component: NewLocation
  },
  {
    path: "/new-user-ambiental",
    layout: DefaultLayout,
    component: NewUserAmbiental
  },
  {
    path: "/show-area-list",
    layout: DefaultLayout,
    component: AreaProtecaoLista
  },
  {
    path: "/analytics",
    layout: DefaultLayout,
    component: NewReactChart
  },
  {
    path: "/ecommerce",
    layout: DefaultLayout,
    component: OnlineStore
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: UserProfile
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/edit-user-profile",
    layout: DefaultLayout,
    component: EditUserProfile
  },
  {
    path: "/login",
    layout: LayoutWithoutNavigation,
    component: Login
  },
  {
    path: "/register",
    layout: BlankIconSidebarLayout,
    component: Register
  },
  {
    path: "/forgot-password",
    layout: BlankIconSidebarLayout,
    component: ForgotPassword
  },
  {
    path: "/change-password",
    layout: BlankIconSidebarLayout,
    component: ChangePassword
  },
  {
    path: "/file-manager-list",
    layout: DefaultLayout,
    component: FileManagerList
  },
  {
    path: "/file-manager-cards",
    layout: DefaultLayout,
    component: FileManagerCards
  },
  {
    path: "/transaction-history",
    layout: DefaultLayout,
    component: TransactionHistory
  },
  {
    path: "/calendar",
    layout: DefaultLayout,
    component: Calendar
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: BlankIconSidebarLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/header-navigation",
    layout: HeaderNavigation,
    component: HeaderNav
  },
  {
    path: "/icon-sidebar-nav",
    layout: IconSidebar,
    component: IconSidebarView
  },
  {
    path: "/effectiveness-report",
    layout: DefaultLayout,
    component: EffectivenessReport
  },{
    path: "/map",
    layout: ({children}) => (
      <IconSidebar noFooter>
        {children}
      </IconSidebar>
    ),
    component: Map
  }
];
