export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_REDIRECT_PATH = 'AUTH_SET_REDIRECT_PATH';
export const AUTH_UPDATE_USER_INFO = 'AUTH_UPDATE_USER_INFO';

export const REPORT_UPDATE_NOTES = 'REPORT_UPDATE_NOTES';
export const REPORT_LOAD_NEW = 'REPORT_LOAD_NEW';
export const REPORT_START_LOADING_NEW = 'REPORT_START_LOADING_NEW';
export const REPORT_SUCCESS_LOADING_NEW = 'REPORT_SUCCESS_LOADING_NEW';
export const REPORT_ERROR_LOADING_NEW = 'REPORT_ERROR_LOADING_NEW';

export const REPORT_CHANGE_SUBMIT_PERCENTAGE = 'REPORT_CHANGE_SUBMIT_PERCENTAGE';

export const MISC_FETCH_COMPANIES = 'MISC_FETCH_COMPANIES';
export const MISC_FETCH_PILOTS = 'MISC_FETCH_PILOTS';
export const MISC_FETCH_PROVIDERS = 'MISC_FETCH_PROVIDERS';
export const MISC_FETCH_REPORTS = 'MISC_FETCH_REPORTS';
export const MISC_CREATE_NEW_USER = 'MISC_CREATE_NEW_USER';
export const MISC_FETCH_AMBIENTAL = 'MISC_FETCH_AMBIENTAL';
export const MISC_FETCH_OS_LIST = 'MISC_FETCH_OS_LIST';
export const MISC_FETCH_PRESTADOR = 'MISC_FETCH_PRESTADOR';
export const MISC_FETCH_TIPOAPLICACAO = 'MISC_FETCH_TIPOAPLICACAO';
export const MISC_START_LOADING_FILTERED_FETCH__REPORTS = 'MISC_START_LOADING_FILTERED_FETCH__REPORTS';
export const MISC_SUCCESS_LOADING_FILTERED_FETCH__REPORTS = 'MISC_SUCCESS_LOADING_FILTERED_FETCH__REPORTS';
export const MISC_ERROR_LOADING_FILTERED_FETCH__REPORTS = 'MISC_ERROR_LOADING_FILTERED_FETCH__REPORTS';

export const PROGRESS_CHANGE = 'PROGRESS_CHANGE';

export const DASHBOARD_FETCH_DEFAULT = 'DASHBOARD_FETCH_DEFAULT';
export const DASHBOARD_FETCH_DEFAULT_START = 'DASHBOARD_FETCH_DEFAULT_START';
export const DASHBOARD_FETCH_DEFAULT_ERROR = 'DASHBOARD_FETCH_DEFAULT_ERROR';
export const DASHBOARD_SELECT_REPORT = 'DASHBOARD_SELECT_REPORT';
export const DASHBOARD_TOGGLE_SELECTED = 'DASHBOARD_TOGGLE_SELECTED';
export const DASHBOARD_FILTER_START = 'DASHBOARD_FILTER_START';
export const DASHBOARD_FILTER_ERROR = 'DASHBOARD_FILTER_ERROR';
export const DASHBOARD_FILTER_SUCCESS = 'DASHBOARD_FILTER_SUCCESS';

export const AMBIENTAL_FETCH_PROTECTION_AREAS = 'AMBIENTAL_FETCH_PROTECTION_AREAS';
export const AMBIENTAL_START_LOADING_NEW = 'AMBIENTAL_START_LOADING_NEW';
export const AMBIENTAL_SUCCESS_LOADING_NEW = 'AMBIENTAL_SUCCESS_LOADING_NEW';
export const AMBIENTAL_ERROR_LOADING_NEW = 'AMBIENTAL_ERROR_LOADING_NEW';
export const AMBIENTAL_START_LOADING_LIST = 'AMBIENTAL_START_LOADING_LIST';
export const AMBIENTAL_SUCCESS_LOADING_LIST = 'AMBIENTAL_SUCCESS_LOADING_LIST';
export const AMBIENTAL_ERROR_LOADING_LIST = 'AMBIENTAL_ERROR_LOADING_LIST';
export const AMBIENTAL_DENY_OR_APPROVE = 'AMBIENTAL_DENY_OR_APPROVE'

export const FETCH_USERS = 'FETCH_USERS'
export const REMOVE_USER_FROM_ID = 'REMOVE_USER_FROM_ID'

export const REMOVE_OS_FROM_LIST = 'REMOVE_OS_FROM_LIST'
export const REMOVE_REPORT_UUID = 'REMOVE_REPORT_UUID'

export const LOCATION_ADD_NEW = 'LOCATION_ADD_NEW'

