import React, {Component} from 'react'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  FormInput,
  FormCheckbox,
  FormSelect,
  Button,
  ButtonGroup,
  InputGroup,
  InputGroupText,
  InputGroupAddon
} from "shards-react";
import classes from './FloatingFilter.module.css';
import {connect} from 'react-redux'
import * as actions from "../../../store/actions";
import {toastr} from "react-redux-toastr";

class floatingFilter extends Component {

  constructor(props) {

    super(props);

    this.state = {
      collapse: false,
      class: {
        card: "card-collapse",
        header: "card-header",
        body: "card-body",
        footer: "card-footer"
      },
      form: {
        fields: this.props.fields,
      },
    };

    this.toggle = this.toggle.bind(this);
    this.toggleField = this.toggleField.bind(this);
    this.handleChangeTratament = this.handleChangeTratament.bind(this);
    this.handleChangeInputNumber = this.handleChangeInputNumber.bind(this);
    this.handleChangeComboBox = this.handleChangeComboBox.bind(this);
    this.handleChangePeriod = this.handleChangePeriod.bind(this);

  }

  componentDidMount() {
    this.props.onLoadCompaniesSimpleList();
    this.props.onLoadProvidersSimpleList();
  }

  toggle() {
    let class_toggle;
    this.state.collapse ? class_toggle = "card-collapse" : class_toggle = "card";
    this.setState({
      ...this.state,
      collapse: !this.state.collapse,
      class: {card: class_toggle}
    });
  }

  toggleField(target) {

    let new_val = {...this.state};

    new_val.form.fields[target].toggle = !new_val.form.fields[target].toggle;

    this.setState({
      ...this.state,
      ...new_val
    });
  }

  handleChangeInputNumber(e, target) {

    const value = e.target.value;

    let newState = {...this.state};

    newState.form.fields[target].value.pop();
    newState.form.fields[target].value.push(value);

    this.setState({
      ...this.state,
      newState
    });
  }

  handleChangeCheckBox(e, target) {

    let newState = {...this.state};

    newState.form.fields[target].enabled = !this.state.form.fields[target].enabled;

    this.setState({
      ...this.state,
      ...newState
    })

  }

  handleChangeComboBox(e, target) {

    let new_val = {...this.state};
    let check_val = false;

    if (e.target.multiple) {

      if (true) {

        new_val.form.fields[target].value.map((value) => {
          if (value === e.target.value) {
            check_val = true;
          }
          return false;
        });


        if (!check_val) {
          new_val.form.fields[target].value.push(e.target.value);
        } else {
          new_val.form.fields[target].value.splice(new_val.form.fields[target].value.indexOf(e.target.value),
            1);
        }

      } else {

        new_val.form.fields[target].value = [];
        new_val.form.fields[target].value.push(e.target.value);

      }

    } else {
      new_val.form.fields[target].value = [];
      new_val.form.fields[target].value.push(e.target.value);
    }

    if (e.target.id === 'select_provider') {
      const newState = {...this.state};
      const prestadoraSelecionada = this.props.misc.prestadoras.filter((el) => el.id == e.target.value)[0];
      const elements = prestadoraSelecionada ? prestadoraSelecionada.pilotos.map((el, index) => ({
        id: 'pilot-' + index,
        name: el.nome,
        value: el.id
      })) : [];
      elements.unshift({
        id: 'pilot-select',
        name: 'Escolha...',
        value: 'first'
      });
      newState.form.fields[2].elements = elements;
      this.setState({
        ...this.state,
        ...newState
      });
    } else {
      this.setState({
        ...this.state,
        ...new_val
      });
    }

  }

  handleChangePeriod(e, target) {

    let new_val = {...this.state};
    if (e.target.id === 'input_period_start') {
      new_val.form.fields[target].value.inicio = e.target.value;
    } else if (e.target.id === 'input_period_end') {
      new_val.form.fields[target].value.fim = e.target.value;
    }
    this.setState({
      ...this.state,
      ...new_val
    });

  }

  handleChangeTratament(e, target) {

    let new_val = {...this.state};

    let elementIndex;
    const field = new_val.form.fields[target].elements.filter((el, index) => {
      if (el.id === e.target.id) {
        elementIndex = index;
      }
      return false;
    });

    if (!elementIndex && elementIndex !== 0) {
      return
    }

    new_val.form.fields[target].elements[elementIndex].checked = !new_val.form.fields[target].elements[elementIndex].checked;

    this.setState({
      ...this.state,
      ...new_val
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.misc.empresas !== prevProps.misc.empresas) {
      const newState = {...this.state};
      const elements = this.props.misc.empresas.map((el, index) => ({
        id: 'company-' + index,
        name: el.nome,
        value: el.id
      }));
      elements.unshift({
        id: 'company-select',
        name: 'Escolha...',
        value: 'first'
      });
      newState.form.fields[0].elements = elements;
      this.setState({
        ...this.state,
        ...newState
      });
    }
    if (this.props.misc.prestadoras !== prevProps.misc.prestadoras) {
      const newState = {...this.state};
      const elements = this.props.misc.prestadoras.map((el, index) => ({
        id: 'provider-' + index,
        name: el.nome,
        value: el.id
      }));
      elements.unshift({
        id: 'company-select',
        name: 'Escolha...',
        value: 'first'
      });
      newState.form.fields[1].elements = elements;
      this.setState({
        ...this.state,
        ...newState
      });
    }
  }

  onFilterHandler = (e) => {

    e.preventDefault();

    const filterData = this.state.form.fields.reduce((acc, cur) => {

        if (cur.value !== null && cur.value.length !== 0) {
          if (cur.id === 'input_period') {
            if (cur.value.inicio && cur.value.fim) {
              acc[cur.id] = cur.value;
            }
          } else if (cur.value === 'tratamentos') {
            const tratamentos = cur.elements.filter(el => !el.checked);
            if (tratamentos.length > 0) {
              acc.tratamentos = tratamentos.map(el => el.value);
            }
          } else if (cur.id === 'select_provider' || cur.id === 'select_pilot' || cur.id === 'select_company') {
            if (cur.value[0] !== 'first') {
              acc[cur.id] = cur.value;
            }
          } else if (cur.id === 'input_amount') {
            let amount = [9999];
            if (cur.enabled) {
              amount = cur.value
            }
            acc[cur.id] = amount;
          } else {
            acc[cur.id] = cur.value;
          }
        }
        return acc
      },
      {});
    this.props.onFilterDashboard(filterData,
      this.props.loading);
  };

  onClearHandler = (e) => {
    e.preventDefault();
    const newState = {...this.state};
    const array = newState.form.fields;
    array[0].value = ["first"];
    array[1].value = ["first"];
    array[2].value = ["first"];
    array[3].value = {inicio: undefined, fim: undefined};
    document.getElementById("input_period_start").value = "";
    document.getElementById("input_period_end").value = "";
    array[4].elements.forEach((el, index) => {
      array[4].elements[index].checked = true
    });
    array[5].value = ["50"];
    array[5].enabled = false;
    newState.form.fields = array;
    this.setState({
      ...this.state,
      ...newState
    })
  };

  render() {
    return (
      <Card
        className={[classes[this.state.class.card], classes["card-transparent"], classes["transition"] + " auth-form"].join(" ")}>
        <CardHeader className={[classes["card-header"]].join(" ")} onClick={this.toggle}>
          <div className={[classes["card-title"]].join(" ")}><i className="fas fa-filter"/> FILTROS</div>
          <div className="block-handle"/>
        </CardHeader>
        <Collapse open={this.state.collapse}>
          <CardBody
            className={[classes["card-transparent"], classes["GreenBorderTop"] + " pt-3 pb-0"].join(" ")}>
            <Form>
              {this.state.form.fields.map((value, index) => {

                let target = index;
                let current = value;
                let field;

                if (index === 0) {
                  if (this.props.userInfo.nivel === 'funcionario') {
                    return null;
                  }
                }

                if (current.type === 'amount') {
                  field = (
                    <FormGroup key={current.id}>
                      <label htmlFor={current.id} className={[classes["label"]].join(" ")}
                             onClick={() => this.toggleField(target)}>{current.toggle ? current.icon.opened : current.icon.closed}{current.label}</label>
                      <Collapse open={current.toggle}>
                        <InputGroup seamless>
                          <FormInput id={current.id}
                                     disabled={!current.enabled}
                                     placeholder={current.placeholder}
                                     type={"number"}
                                     className={[current.class].join(" ") + "text-center"}
                                     min={current.minValue}
                                     max={current.maxValue}
                                     value={current.value}
                                     onChange={(e) => this.handleChangeInputNumber(e, target)}/>
                          <InputGroupAddon type="append">
                            <InputGroupText>
                              <FormCheckbox
                                id="check_amount"
                                toggle
                                small
                                checked={current.enabled}
                                className={"m-0"}
                                onChange={(e) => this.handleChangeCheckBox(e, target)}>
                              </FormCheckbox>
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Collapse>
                    </FormGroup>
                  )
                }

                if (current.type === "period") {

                  field = (
                    <FormGroup key={current.id}>
                      <label htmlFor={current.id} className={[classes["label"], current.class].join(" ")}
                             onClick={() => this.toggleField(target)}>{current.label}{current.toggle ? current.icon.opened : current.icon.closed}</label>
                      <Collapse open={current.toggle}>
                        <InputGroup id={current.id} className={"d-flex my-auto date-range"}>
                          {current.elements.map((value, index) => {
                            return (
                              <FormInput
                                key={index}
                                type={"date"}
                                id={value.id}
                                disabled={!current.enabled}
                                value={index === 0 ? value.value.inicio : value.value.fim}
                                onChange={(e) => this.handleChangePeriod(e, target)}/>);
                          })}
                        </InputGroup>
                      </Collapse>
                    </FormGroup>);

                }

                if (current.type === "comboBox") {

                  field = (
                    <FormGroup key={current.id}>
                      <label htmlFor={current.id} className={[classes["label"], current.class].join(" ")}
                             onClick={() => this.toggleField(target)}>{current.label}{current.toggle ? current.icon.opened : current.icon.closed}</label>
                      <Collapse open={current.toggle}>
                        <FormSelect id={current.id} disabled={!current.enabled}
                                    onChange={(e) => this.handleChangeComboBox(e, target)}
                                    multiple={current.multiple} value={current.value[0]}>
                          {current.elements.map((value, index) => {
                            return (<option key={index} id={value.id} value={value.value}>{value.name}</option>);
                          })}
                        </FormSelect>
                      </Collapse>
                    </FormGroup>);

                }

                if (current.type === "checkBox") {

                  field = (
                    <FormGroup key={current.id}>
                      <label htmlFor={current.id} className={[classes["label"], current.class].join(" ")}
                             onClick={(e) => this.toggleField(target)}>{current.label}{current.toggle ? current.icon.opened : current.icon.closed}</label>
                      <Collapse open={current.toggle}>
                        {current.elements.map((value, index) => {
                          return (
                            <FormCheckbox key={index}
                                          id={value.id}
                                          disabled={!current.enabled}
                                          value={value.value}
                                          toggle
                                          small
                                          checked={value.checked}
                                          onChange={(e) => this.handleChangeTratament(e,
                                            target)}>
                              <span className={[classes["label"], value.class].join(" ")}>{value.name}</span>
                            </FormCheckbox>);
                        })}
                      </Collapse>
                    </FormGroup>);

                }

                return (field);

              })}
            </Form>
          </CardBody>
          <CardFooter
            className={[classes["GreenBorderBottom"]].join(" ") + " card-transparent border-top d-flex justify-content-start p-3"}>
            <ButtonGroup
              className={[classes["button-group"]].join(" ")}>
              <Button
                theme={"success"}
                className={[classes["button"]].join(" ")} onClick={(e) => this.onFilterHandler(e)}>
                {/*<i className="fas fa-filter"/>*/}
                Filtrar
              </Button>
              <Button theme={"danger"} className={[classes["button"]].join(" ")} onClick={e => this.onClearHandler(e)}>
                {/*<i className="fas fa-eraser"/>*/}
                Limpar
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Collapse>
      </Card>);
  };

}

const mapStateToProps = state => {
  return {
    misc: state.misc,
    loading: state.dashboard.loading
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onLoadCompaniesSimpleList: () => dispatch(actions.requestCompaniesSimpleList()),
    onLoadProvidersSimpleList: () => dispatch(actions.requestProvidersSimpleList()),
    onFilterDashboard: (options, loading) => dispatch(actions.requestFilteredDashboard(options,
      loading))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(floatingFilter);
