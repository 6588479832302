import React, {Component} from "react";
import {
	Button, Card, CardHeader, Col, Container, Form, FormCheckbox, FormInput, FormSelect, ListGroup, ListGroupItem, Row
} from "shards-react";
import {connect} from 'react-redux'

import {toastr} from 'react-redux-toastr'

import PageTitle from "../components/common/PageTitle";
import * as actions from '../store/actions'
import validator from "validator";
import contentArrays from "../utils/arrays";

class NewAmbientalData extends Component {

  	initialState = {
		form: {
			empresa: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				validator: {
				isIn: this.props.arrayEmpresas
				}
			},
			codigo: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				name: 'codigo',
				validator: {
				isLength: 3
				},
			},
			propriedade: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				name: 'propriedade',
				validator: {
				isLength: 3
				},
			},
			os: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				name: 'os',
				validator: {
				isLength: 3
				},
			},
			data: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				validator: {
				isDate: true
				}
			},
			umidadeRelativa: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				validator: {
				isFloat: true
				}
			},
			velocidadeVento: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				validator: {
				isFloat: true
				}
			},
			dpv: {
				value: 'Escolha...',
				valid: true,
				shouldValidate: true,
				touched: false,
				validator: {
				isIn: contentArrays.dpv
				}
			},
			temperatura: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				validator: {
				isFloat: true
				}
			},
			arquivoAplicacao: {
				value: 'Escolha um arquivo...',
				valid: false,
				shouldValidate: true,
				touched: false,
				store: false,
				name: 'file_aplicacao',
				validator: {
				isFile: true,
				type: 'kml'
				}
			},
			arquivoTrajeto: {
				value: 'Escolha um arquivo...',
				valid: false,
				shouldValidate: true,
				touched: false,
				store: false,
				name: 'file_trajeto',
				validator: {
				isFile: true,
				type: 'kml'
				}
			},
			arquivoAnexo1: {
				value: 'Escolha um arquivo...',
				valid: false,
				shouldValidate: true,
				touched: false,
				store: false,
				name: 'file_anexo_1',
				validator: {
				isFile: true,
				type: 'pdf'
				}
			},
			arquivoAnexo2: {
				value: 'Escolha um arquivo...',
				valid: false,
				shouldValidate: true,
				touched: false,
				store: false,
				name: 'file_anexo_2',
				validator: {
				isFile: true,
				type: 'pdf'
				}
			},
			arquivoAnexo3: {
				value: 'Escolha um arquivo...',
				valid: false,
				shouldValidate: true,
				touched: false,
				store: false,
				name: 'file_anexo_3',
				validator: {
				isFile: true,
				type: 'pdf'
				}
			},
			arquivoAnexo4: {
				value: 'Escolha um arquivo...',
				valid: false,
				shouldValidate: true,
				touched: false,
				store: false,
				name: 'file_anexo_4',
				validator: {
				isFile: true,
				type: 'pdf'
				}
			},
			prestador: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				validator: {
					isInt: true
				}
			},
			tipoAplicacao: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				validator: {
					isArrayTipoAplicacao: []
				}
			},
			modalidade: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				validator: {
					isInt: true
				}
			},
			areaAplicada: {
				value: null,
				valid: false,
				shouldValidate: true,
				touched: false,
				validator: {
				isFloat: true
				}
			},
		}
	};

	state = {...this.initialState};
	objectModalidades = {};

	componentDidMount() {
		this.props.onLoadCompaniesSimpleList();
		this.props.onLoadPrestadorSimpleList();
		this.props.onLoadTipoAplicacaoList();		
	}

	onFormSendHandler = e => {
		e.preventDefault();
		let counter = 0, validCounter = 0;
		let invalid = '';
		for (let key in this.state.form) {
			if (this.state.form.hasOwnProperty(key)) {
				if (this.state.form[key].valid) {
					validCounter++;
				} else {
				  invalid = key;
        }
				counter++;
			}
		}
		if (validCounter !== counter) {
			return toastr.warning('Não Enviado', 'Verifique as informações e tente novamente - [' + invalid + ']', {position: 'top-right'});
    	}
		let formData = new FormData();
		for (let key in this.state.form) {
			if (this.state.form.hasOwnProperty(key)) {
				let itemName = key;
				if (this.state.form[key].name) {
					itemName = this.state.form[key].name
				}
				if (this.state.form[key].store) {
					formData.append(itemName,
						this.state.form[key].store)
				} else {
					formData.append(itemName,
						this.state.form[key].value);
				}
				console.log(itemName,
					this.state.form[key]);
			}
		}

		this.props.onSendNewAmbientalData(formData, () => {
			this.setState({...this.state, ...this.initialState});
			const inputsList = document.getElementsByTagName('input');
			const selectsList = document.getElementsByTagName('select');

			console.log('INPUTS: ', inputsList)

			for (let input of inputsList) {
				input.value = ''
			}

			for (let select of selectsList) {
				select.selectedIndex = 0;
			}

		  // inputsArray.forEach(input => {
		  //   input.value = ''
	      // })
    	});
    	toastr.info('Cadastrando...', 'Enviando aplicação para o sistema...', {position: 'top-right'});

	};

	inputChangedHandler(e, key) {
		// console.log(key);
		// console.log(this.props.misc);
		let obj = {...this.state.form[key]};
		let incomingValue = e.target.value;

		let valid = true;

		if (obj.validator) {
			if (obj.validator.isInt) {
				valid = validator.isInt(incomingValue);
			}
			if (obj.validator.isArrayTipoAplicacao) {
				this.objectModalidades = this.props.misc.tipoAplicacoes.filter(el => Number(el.tipoAplicacaoId) == incomingValue);
				valid = valid;
			}
			if (obj.validator.isEmail) {
				valid = validator.isEmail(incomingValue) && valid;
			}
			if (obj.validator.isLength) {
				valid = validator.isLength(incomingValue,
					{min: obj.validator.isLength}) && valid;
			}
			if (obj.validator.equals) {
				valid = validator.equals(incomingValue,
					obj.validator.equals) && valid;
			}
			if (obj.validator.isIn) {
				valid = validator.isIn(incomingValue,
					obj.validator.isIn) && valid;
			}
			if (obj.validator.isFloat) {
				valid = validator.isFloat(incomingValue) && valid;
			}
			if (obj.validator.isDate) {
				valid = new Date(incomingValue) < new Date() && valid;
			}
			if (obj.validator.isFile) {
				//incomingValue = incomingValue.files[0].name
				valid = e.target.files.length === 1 && valid;
				if (valid) {
					incomingValue = e.target.files[0].name;
					const splitValue = incomingValue.split('.');
					if (splitValue[splitValue.length - 1].toLowerCase() !== obj.validator.type) {
						valid = false;
						incomingValue = 'Escolha um arquivo...'
					} else {
						obj.store = e.target.files[0]
            
					}
				} else {
					incomingValue = 'Escolha um arquivo...'
				}
			}
		}

		obj.valid = valid;
		obj.value = incomingValue;
		obj.touched = true;

		console.log(obj);

		this.setState(oldState => ({
			form: {
				...oldState.form,
				[key]: obj
			}
		}))
	}

	render() {

		console.log("state.form:");
		console.log(this.state.form);
		// let pilotos = [];
		if (this.props.misc.tipoAplicacoes && this.state.form.tipoAplicacao) {
      		if (this.state.form.tipoAplicacao.valid) {
        		this.objectModalidades = this.props.misc.tipoAplicacoes.filter(el => Number(el.tipoAplicacaoId) == this.state.form.tipoAplicacao.value);
      		}
    	}
		console.log(this.objectModalidades);

		return (<Container fluid className="main-content-container px-4">
			<Row noGutters className="page-header py-4">
				<PageTitle sm="4" title="Nova Aplicação" subtitle="Cadastro" className="text-sm-left"/>
			</Row>
			<Row>
				<Col>
					<Card small>
						<CardHeader className="border-bottom">
							<h6 className="m-0">Dados Cadastrais</h6>
						</CardHeader>


						<ListGroup flush>
							<ListGroupItem className="p-3">
								<Row>
									<Col>
										<Form>
											<Row form>
												<Col md="4" className="form-group">
													<label htmlFor="inputSelectEmpresa">Empresa</label>
													<FormSelect
														id="inputSelectEmpresa"
														invalid={!this.state.form.empresa.valid && this.state.form.empresa.touched && this.state.form.empresa.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'empresa')}>
														<option>Carregando...</option>
														{this.props.misc.empresas ? this.props.misc.empresas.filter(el => el.nome.match(/\[[a-z]\]/i)).map(el => (
															<option value={el.id}
																	key={el.id}>{el.nome}</option>)) : null}
													</FormSelect>
												</Col>
												<Col md="4" className="form-group">
												<label htmlFor="inputCodigo">Codigo</label>
												<FormInput
													id="inputCodigo"
													type="text"
													placeholder="1234"
													invalid={!this.state.form.codigo.valid && this.state.form.codigo.touched && this.state.form.codigo.shouldValidate}
													onChange={(event) => this.inputChangedHandler(event,
													'codigo')}/>
												</Col>
												<Col md="4" className="form-group">
												<label htmlFor="inputOs">OS</label>
												<FormInput
													id="inputOs"
													type="text"
													placeholder="OS 9999"
													invalid={!this.state.form.os.valid && this.state.form.os.touched && this.state.form.os.shouldValidate}
													onChange={(event) => this.inputChangedHandler(event,
													'os')}/>
												</Col>

											</Row>

											<Row form>
												<Col md="6" className="form-group">
												<label htmlFor="inputPropriedade">Propriedade</label>
												<FormInput
													id="inputPropriedade"
													placeholder="Fazenda Santa Fé"
													invalid={!this.state.form.propriedade.valid && this.state.form.propriedade.touched && this.state.form.propriedade.shouldValidate}
													onChange={(event) => this.inputChangedHandler(event,
													'propriedade')}/>
												</Col>
												<Col md="6" className="form-group">
													<label htmlFor="inputSelectData">Data</label>
													<input type="date"
														   id="inputSelectData"
														   className={(!this.state.form.data.valid && this.state.form.data.touched && this.state.form.data.shouldValidate) ? "form-control is-invalid" : "form-control"}
														   onChange={(event) => this.inputChangedHandler(event,
															   'data')}
													/>
												</Col>
											</Row>

											<Row form>
												<Col md="4" className="form-group">
													<label htmlFor="inputSelectPrestador">Prestador</label>
													<FormSelect
														id="inputSelectPrestador"
														valid={this.state.form.prestador.valid && this.state.form.prestador.touched && this.state.form.prestador.shouldValidate}
														invalid={!this.state.form.prestador.valid && this.state.form.prestador.touched && this.state.form.prestador.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event, 'prestador')}>
														<option>...</option>
														{this.props.misc.prestadores ? 
														this.props.misc.prestadores.map(element => { return <option value={element.prestadorId} key={element.prestadorId}>{element.nome}</option>})
														: null }
													</FormSelect>
												</Col>
												<Col md="3" className="form-group">
													<label htmlFor="inputSelectTipoAplicacao">Tipo Aplicação</label>
													<FormSelect
														id="inputSelectTipoAplicacao"
														invalid={!this.state.form.tipoAplicacao.valid && this.state.form.tipoAplicacao.touched && this.state.form.tipoAplicacao.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event, 'tipoAplicacao')}>
														<option>...</option>
														{this.props.misc.tipoAplicacoes ? 
														this.props.misc.tipoAplicacoes.map(element => { return <option value={element.tipoAplicacaoId} key={element.tipoAplicacaoId}>{element.descricao}</option>})
														: null }
													</FormSelect>
												</Col>
												<Col md="3" className="form-group">
													<label htmlFor="inputSelectModalidade">Modalidade</label>
													<FormSelect
														id="inputSelectModalidade"
														invalid={!this.state.form.modalidade.valid && this.state.form.modalidade.touched && this.state.form.modalidade.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event, 'modalidade')}>
														<option>...</option>
														{this.objectModalidades ? this.objectModalidades[0] ?
														this.objectModalidades[0].modalidades.map(element => { return <option value={element.modalidadeId} key={element.modalidadeId}>{element.descricao}</option>})
														: null : null }
													</FormSelect>
												</Col>
												<Col md="2" className="form-group">
													<label htmlFor="inputAreaAplicacao">Área Aplicação (ha)</label>
													<FormInput
														id="inputAreaAplicacao"
														type="text"
														placeholder="0.00"
														invalid={!this.state.form.areaAplicada.valid && this.state.form.areaAplicada.touched && this.state.form.areaAplicada.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'areaAplicada')}/>
												</Col>
											</Row>

											<Row form>
												<Col md="3" className="form-group">
													<label htmlFor="inputUmidadeRelativa">Umidade Relativa</label>
													<FormInput
														id="inputUmidadeRelativa"
														type="text"
														placeholder="0.00"
														invalid={!this.state.form.umidadeRelativa.valid && this.state.form.umidadeRelativa.touched && this.state.form.umidadeRelativa.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'umidadeRelativa')}/>
												</Col>
												<Col md="3" className="form-group">
													<label htmlFor="inputTemperatura">Temperatura</label>
													<FormInput
														id="inputTemperatura"
														type="text"
														placeholder="0.00"
														invalid={!this.state.form.temperatura.valid && this.state.form.temperatura.touched && this.state.form.temperatura.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'temperatura')}/>
												</Col>
												<Col md="3" className="form-group">
													<label htmlFor="inputVelocidadeVento">Velocidade do Vento</label>
													<FormInput
														id="inputVelocidadeVento"
														type="text"
														placeholder="0.00"
														invalid={!this.state.form.velocidadeVento.valid && this.state.form.velocidadeVento.touched && this.state.form.velocidadeVento.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'velocidadeVento')}/>
												</Col>
												<Col md="3" className="form-group">
												<label htmlFor="inputSelectDpv">DPV</label>
												<FormSelect
													id="inputSelectDpv"
													invalid={!this.state.form.dpv.valid && this.state.form.dpv.touched && this.state.form.dpv.shouldValidate}
													onChange={(event) => this.inputChangedHandler(event,
													'dpv')}>
													<option value="Escolha...">Escolha...</option>
													{contentArrays.dpv.map((element, index) => (
													<option key={index} value={element}>{element}</option>))}
												</FormSelect>
												</Col>
											</Row>

											<hr/>

											<Row form>
												<Col md="4" className="form-group">
													<label htmlFor="">Aplicação (.kml)</label>
													<div className="custom-file mb-3">
														<input type="file" id="arquivoAplicacao"
															   className={(!this.state.form.arquivoAplicacao.valid && this.state.form.arquivoAplicacao.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
															   onChange={(event) => this.inputChangedHandler(event,
																   'arquivoAplicacao')}/>
														<label className="custom-file-label" htmlFor="arquivoAplicacao">
															{this.state.form.arquivoAplicacao.value}
														</label>
													</div>
												</Col>
												<Col md="4" className="form-group">
													<label htmlFor="">Trajeto (.kml)</label>
													<div className="custom-file mb-3">
														<input type="file" id="arquivoTrajeto"
															   className={(!this.state.form.arquivoTrajeto.valid && this.state.form.arquivoTrajeto.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
															   onChange={(event) => this.inputChangedHandler(event,
																   'arquivoTrajeto')}/>
														<label className="custom-file-label" htmlFor="arquivoTrajeto">
															{this.state.form.arquivoTrajeto.value}
														</label>
													</div>
												</Col>
												<Col md="4" className="form-group">
												<label htmlFor="">Ordem de Serviço (.pdf)</label>
												<div className="custom-file mb-3">
													<input type="file"
														className={(!this.state.form.arquivoAnexo1.valid && this.state.form.arquivoAnexo1.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
														onChange={(event) => this.inputChangedHandler(event,
															'arquivoAnexo1')}/>
													<label className="custom-file-label" htmlFor="arquivoAnexo1">
													{this.state.form.arquivoAnexo1.value}
													</label>
												</div>
												</Col>
											</Row>

						                    <Row form>
												<Col md="4" className="form-group">
													<label htmlFor="">Relatório Operacional (.pdf)</label>
													<div className="custom-file mb-3">
														<input type="file" id="arquivoAnexo2"
															   className={(!this.state.form.arquivoAnexo2.valid && this.state.form.arquivoAnexo2.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
															   onChange={(event) => this.inputChangedHandler(event,
																   'arquivoAnexo2')}/>
														<label className="custom-file-label" htmlFor="arquivoAnexo2">
															{this.state.form.arquivoAnexo2.value}
														</label>
													</div>
												</Col>
												<Col md="4" className="form-group">
													<label htmlFor="">Receituário Agronômico (.pdf)</label>
													<div className="custom-file mb-3">
														<input type="file" id="arquivoAnexo3"
															   className={(!this.state.form.arquivoAnexo3.valid && this.state.form.arquivoAnexo3.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
															   onChange={(event) => this.inputChangedHandler(event,
																   'arquivoAnexo3')}/>
														<label className="custom-file-label" htmlFor="arquivoAnexo3">
															{this.state.form.arquivoAnexo3.value}
														</label>
													</div>
												</Col>
												<Col md="4" className="form-group">
												<label htmlFor="">Checklist (.pdf)</label>
												<div className="custom-file mb-3">
													<input type="file"
														className={(!this.state.form.arquivoAnexo4.valid && this.state.form.arquivoAnexo4.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
														onChange={(event) => this.inputChangedHandler(event,
															'arquivoAnexo4')}/>
													<label className="custom-file-label" htmlFor="arquivoAnexo4">
													{this.state.form.arquivoAnexo4.value}
													</label>
												</div>
												</Col>
											</Row>

											<Button type="submit" theme="success" disabled={!this.props.sentRequest}
													onClick={event => this.onFormSendHandler(event)}>Cadastrar</Button>

										</Form>
									</Col>
								</Row>
							</ListGroupItem>
						</ListGroup>


					</Card>
				</Col>
			</Row>
		</Container>)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onLoadCompaniesSimpleList: () => dispatch(actions.requestCompaniesSimpleList()),
		onLoadPrestadorSimpleList: () => dispatch(actions.requestPrestadorSimpleList()),
		onLoadTipoAplicacaoList: () => dispatch(actions.requestTipoAplicacaoList()),
		onSendNewAmbientalData: (formData) => dispatch(actions.sendNewAmbientalData(formData)),
  }
};
const mapStateToProps = state => {
	return {
		misc: state.misc,
    	sentRequest: state.progressBar.percentage === 0
	}
};

export default connect(mapStateToProps,
	mapDispatchToProps)(
	NewAmbientalData);
