import React from "react";

import classes from './ProgressBar.module.css'

import {connect} from 'react-redux'

const ProgressBar = ({ percentage }) => (
  <div className={classes.ProgressBar} style={{width: percentage + '%'}}/>
);

const mapStateToProps = state => {
  return {
    percentage: state.progressBar.percentage
  }
};

export default connect(mapStateToProps)(ProgressBar);
