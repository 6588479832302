import React from "react";
import ReactTable from "react-table";
import FuzzySearch from "fuzzy-search";
import dateFormat from "dateformat";
import {toastr} from 'react-redux-toastr'

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  CardFooter
} from "shards-react";
import {connect} from 'react-redux'
import * as actions from '../store/actions'


import PageTitle from "../components/common/PageTitle";
import 'react-table/react-table.css'
import '../styles/templates/_react-table.scss'
import ExcelSelectColumns from "../components/excel-select-columns/ExcelSelectColumns";

class NewReactTable extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      // Data Tabale
      tableData: [], //Columns Table
      fullColumns: [{
        Header: "Código",
      }, {
        Header: "Nome",
      }, {
        Header: "Tratamento",
      }, {
        Header: "Cultura",
      }, {
        Header: "Empresa",
      }, {
        Header: "Prestadora",
      }, {
        Header: "Piloto",
      }, {
        Header: "Data da Aplicação",
      }, {
        Header: "Data de Registro",
      }, {
        Header: "Distância da Pista",
      }, {
        Header: "Faixa Contratada (ha)",
      }, {
        Header: "Faixa Executada (ha)",
      }, {
        Header: "Largura da Faixa (ha)",
      }, {
        Header: "Largura da Faixa (%)",
      }, {
        Header: "Vazao Contratada (l)",
      }, {
        Header: "Área de Plantio (ha)",
      }, {
        Header: "Insumos (ha)",
      }, {
        Header: "Insumos (%)",
      }, {
        Header: "Área Aplicada (ha)",
      }, {
        Header: "Área Aplicada (%)",
      }, {
        Header: "Área não Aplicada (ha)",
      }, {
        Header: "Área não Aplicada (%)",
      }, {
        Header: "Aplicação Efetiva (ha)",
      }, {
        Header: "Aplicação Efetiva (%)",
      }, {
        Header: "Aplicação Externa (ha)",
      }, {
        Header: "Aplicação Externa (%)",
      }, {
        Header: "Aplicação Sobreposta (ha)",
      }, {
        Header: "Aplicação Sobreposta (%)",
      }, {
        Header: "Taxa de Aplicação (l)",
      }, {
        Header: "Taxa de Aplicação (%)",
      },],
      mainColumns: [{
        Header: "",
        expander: true,
        sortable: false,
        width: 60,
        Expander: ({isExpanded}) => <div>
          {isExpanded ? <Button theme="white"><i className="material-icons">search</i></Button> :
            <Button theme="white"><i className="material-icons">search</i></Button>}
        </div>
      }, {
        Header: "Código",
        accessor: "codigo",
        width: 120,
        className: "text-center"
      }, {
        Header: "Nome",
        accessor: "nome",
        minWidth: 150,
        maxWidth: 200,
        className: "text-center"
      }, {
        Header: "Tratamento",
        accessor: "tratamento",
        width: 150,
        className: "text-center"
      }, {
        Header: "Cultura",
        accessor: "cultura",
        width: 150,
        className: "text-center"
      }, {
        Header: "Empresa",
        accessor: "empresa.nome",
        width: 200,
        className: "text-center",
        show: this.props.authData.nivel !== 'funcionario'
      }, {
        Header: "Prestadora",
        accessor: "piloto.prestadora.nome",
        width: 200,
        className: "text-center"
      }, {
        Header: "Piloto",
        accessor: "piloto.nome",
        width: 150,
        className: "text-center"
      }, {
        Header: "Data da Aplicação",
        accessor: "data",
        className: "text-center",
        minWidth: 170,
        Cell: row => {
          let date = new Date(row.original.data);
          date.setDate(date.getDate() + 1);
          return dateFormat(date,
            "dd/mm/yyyy")
        },
        Filter: ({filter, onChange}) => <input
          type={"date"}
          className={"text-center w-auto"}
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : "all"}>
        </input>,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          } else {
            return row[filter.id] === filter.value;
          }
        },
      }, {
        Header: "Data de Registro",
        accessor: "dataCriacao",
        className: "text-center",
        minWidth: 170,
        Cell: row => {
          let date = new Date(row.original.dataCriacao);
          date.setHours(date.getHours() + 3);
          return (dateFormat(date,
            "dd/mm/yyyy HH:MM:ss"));
        },
        Filter: ({filter, onChange}) => <input
          type={"date"}
          className={"text-center w-auto"}
          onChange={event => onChange(event.target.value)}
          value={filter ? filter.value : "all"}>
        </input>,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          } else {
            
            const filt_date = new Date(filter.value);
            filt_date.setDate(filt_date.getDate() + 1);
            const filter_value = dateFormat(filt_date,
              "yyyy-mm-dd");
            
            const row_date = new Date(row[filter.id]);
            const row_value = dateFormat(row_date,
              "yyyy-mm-dd");
            
            return row_value === filter_value;
            
          }
        },
      }, {
        Header: "Status",
        accessor: 'status',
        className: "text-center",
        minWidth: 120,
        Cell: row => {
          if (this.state.tableData.length > 0) {
            const row_key = row.row._index;
            let text_status;
            let textClass;
            
            if (this.state.tableData[row_key].hidden) {
              text_status = 'Oculto';
              textClass = 'info'
            } else if (this.state.tableData[row_key].ready) {
              text_status = 'Pronto';
              textClass = 'success'
            } else if (this.state.tableData[row_key].error) {
              text_status = 'Erro';
              textClass = 'danger'
            } else if (this.state.tableData[row_key].processing) {
              text_status = 'Processando';
              textClass = 'warning'
            } else {
              text_status = 'Na fila';
              textClass = 'primary'
            }
            
            return (<span className={'text-' + textClass}>{text_status}</span>);
          } else {
            return null;
          }
          
        },
        Filter: ({filter, onChange}) => <select
          onChange={event => onChange(event.target.value)}
          style={{width: "100%"}}
          value={filter ? filter.value : "all"}>
          <option value="all">Todos</option>
          <option value="Pronto">Pronto</option>
          <option value="Processando">Processando</option>
          <option value="Oculto">Oculto</option>
          <option value="Erro">Erro</option>
          <option value="Na Fila">Na Fila</option>
        </select>,
        filterMethod: (filter, row) => {
          if (filter.value === "all") {
            return true;
          }
          return row[filter.id] === filter.value;
        }
      }, {
        Header: "Actions",
        accessor: "actions",
        minWidth: 150,
        sortable: false,
        filterable: false,
        Cell: row => {
          if (this.state.tableData.length > 0) {
            
            const row_key = row.row._index;
            let button_lock;
            let button_delete;
            let button_view = <Button theme="white" onClick={() => this.handleItemView(row)}>
              <i className="material-icons">visibility</i>
            </Button>
            
            
            if (this.props.authData.nivel !== 'funcionario'){
              if (this.state.tableData[row_key].hidden) {
                button_lock = (<Button theme="white" onClick={() => this.handleItemHidden(row)}>
                  <i className="material-icons">lock_open</i>
                </Button>);
                
              } else {
                
                button_lock = (<Button theme="white" onClick={() => this.handleItemHidden(row)}>
                  <i className="material-icons">lock</i>
                </Button>);
              }
              button_delete = <Button theme="danger" onClick={() => this.handleItemDelete(row)}>
                <i className="material-icons">delete</i>
              </Button>
              button_view = <Button theme="white" type='submit'>
                <i className="material-icons">visibility</i>
              </Button>
            }
            
            return (<form action={"/app/map/" + this.state.tableData[row.index].uuid} target="_blank">
              <ButtonGroup size="sm" className="d-table mx-auto">
                {button_view}
                {button_lock}
                {button_delete}
              </ButtonGroup>
            </form>)
          } else {
            return null;
          }
        }
      }],
      subColumns: [{
        Header: "Distância da Pista",
        columns: [{
          Header: "(km)",
          accessor: "distanciaPista",
          width: 200,
        },],
        className: "text-center"
      }, {
        Header: "Faixa Contratada",
        columns: [{
          Header: "(km)",
          accessor: "faixaContratada",
          width: 200,
        },],
        className: "text-center"
      }, {
        Header: "Faixa Executada",
        columns: [{
          Header: "(ha)",
          accessor: "faixaExecutada",
          width: 200,
        },],
        className: "text-center"
      }, {
        Header: "Largura da Faixa",
        columns: [{
          Header: "(ha)",
          accessor: "larguraFaixa",
        }, {
          Header: "(%)",
          accessor: "percentLarguraFaixa",
        },],
        className: "text-center"
      }, {
        Header: "Vazao Contratada",
        columns: [{
          Header: "(l)",
          accessor: "vazaoContratada",
          width: 200,
          
        },],
        className: "text-center"
      }, {
        Header: "Área de Plantio",
        columns: [{
          Header: "(ha)",
          accessor: "areaPlantio",
        },],
        className: "text-center"
      }, {
        Header: "Insumos",
        columns: [{
          Header: "(ha)",
          accessor: "insumos",
        }, {
          Header: "(%)",
          accessor: "percentInsumos",
        },],
        className: "text-center"
      }, {
        Header: "Área Aplicada",
        columns: [{
          Header: "(ha)",
          accessor: "areaAplicada",
        }, {
          Header: "(%)",
          accessor: "percentAreaAplicada",
        },],
        className: "text-center"
      }, {
        Header: "Área não Aplicada",
        columns: [{
          Header: "(ha)",
          accessor: "areaNaoAplicada",
        }, {
          Header: "(%)",
          accessor: "percentAreaNaoAplicada",
        },],
        className: "text-center"
      }, {
        Header: "Aplicação Efetiva",
        columns: [{
          Header: "(ha)",
          accessor: "aplicacaoEfetiva",
        }, {
          Header: "(%)",
          accessor: "percentAplicacaoEfetiva",
        },],
        className: "text-center"
      }, {
        Header: "Aplicação Externa",
        columns: [{
          Header: "(ha)",
          accessor: "aplicacaoExterna",
        }, {
          Header: "(%)",
          accessor: "percentAplicacaoExterna",
        },],
        className: "text-center"
      }, {
        Header: "Aplicação Sobreposta",
        columns: [{
          Header: "(ha)",
          accessor: "areaSobreposicao",
        }, {
          Header: "(%)",
          accessor: "percentAreaSobreposicao",
        },],
        className: "text-center"
      }, {
        Header: "Taxa de Aplicação",
        columns: [{
          Header: "(l)",
          accessor: "taxaAplicacao",
        }, {
          Header: "(%)",
          accessor: "percentTaxaAplicacao",
        },],
        className: "text-center"
      },], // Control Table
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      sorted: [],
      expanded: {},
      resized: [],
      filtered: [], //Tooltip
      export_excel: false,
      dataLenght: 0,
    };
    
    this.searcher = null;
    this.find = false;
    
    this.getStatusClass = this.getStatusClass.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleItemHidden = this.handleItemHidden.bind(this);
    this.handleItemDelete = this.handleItemDelete.bind(this);
    this.handleItemView = this.handleItemView.bind(this);
    this.exportTableToPDF = this.exportTableToPDF(this);
    this.toggleTip = this.toggleTip.bind(this);
  }
  
  componentWillMount() {
  }
  
  componentDidMount() {
    this.props.onFetchReportsList();
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.tableData !== prevState.tableData) {
      // Initialize the fuzzy searcher.
      if (!(this.searcher)) {
        this.searcher = new FuzzySearch(this.state.tableData,
          ["nome", "tratamento", "cultura", "empresa.nome", "piloto.nome",],
          {
            caseSensitive: false
          });
      }
    }
  }
  
  /**
   * Returns the appropriate status class for the `Status` column.
   */
  getStatusClass(status) {
    const statusMap = {
      hidden: "info",
      ready: "success",
      processing: "warning",
      error: "danger"
    };
    
    return `text-${statusMap[status]}`;
  }
  
  /**
   * Handles the page size change event.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }
  
  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {
    this.find = true;
    this.setState({
      ...this.state,
      tableData: this.searcher.search(e.target.value)
    });
  }
  
  /**
   * Mock method for hide log.
   */
  handleItemHidden(row) {
    const row_key = row.row._index;
    const newData = this.state.tableData;
    
    newData[row_key].hidden ? newData[row_key].status = "Pronto" : newData[row_key].status = "Oculto";
    newData[row_key].hidden = !this.state.tableData[row_key].hidden;
    
    this.props.onToggleHiddenReport(newData[row_key].uuid,
      newData[row_key].hidden);
    
    this.setState({
      ...this.state,
      tableData: newData
    });
    
    if (this.state.tableData[row_key].hidden) {
      toastr.info('OCULTADO!',
        'O Laudo ' + newData[row_key].nome + ' foi ocultado com sucesso!');
    } else {
      toastr.info('REVELADO!',
        'O Laudo ' + newData[row_key].nome + ' foi revelado com sucesso!');
    }
    
    
  }
  
  /**
   * Mock method for deleting log.
   */
  handleItemDelete(row) {
    
    const row_key = row.row._index;
    const newData = this.state.tableData;
    
    this.props.onDeleteReport(newData[row_key].uuid,
      newData[row_key].hidden);
    
    const log_deleted = newData.splice(row_key,
      1);
    
    
    this.setState({
      ...this.state,
      tableData: newData
    });
    
    toastr.warning('EXCLUÍDO!',
      'O Laudo ' + log_deleted[0].nome + ' foi excluído com sucesso!');
  }
  
  /**
   * Mock method for details log.
   */
  handleItemView(row) {
    this.props.history.push('/map/' + this.state.tableData[row.index].uuid);
  }
  
  /**
   * Mock method for export table to pdf.
   */
  exportTableToPDF() {
    
    // const fs = require('fs');
    // const pdf = require('html-pdf');
    // const html = fs.readFileSync('./test/businesscard.html', 'utf8');
    // const options = {format: 'Letter'};
    //
    // pdf.create('<!doctype html><html><head><meta charset="utf-8"><title>PDF</title></head><body><div><p>PDF</p></div></body></html></doctype>', options).toFile('./teste.pdf', function (err, res) {
    // });
  }
  
  /**
   * Mock method for open tooltip.
   */
  toggleTip(e) {
    const id = e.target.id;
    
    this.setState({
      [id]: !this.state[id]
    });
  }
  
  modalClickHandler = () => {
    this.setState({
      exportModalOpen: !this.state.exportModalOpen
    });
  };
  
  render() {
    let {tableData, fullColumns, mainColumns, subColumns, pageSize, pageSizeOptions, sorted, expanded, resized, filtered} = this.state;
    
    if (this.props.misc.reports && this.state.tableData !== this.props.misc.reports && this.find === false) {
      
      let dataLenght = this.props.misc.reports.length;
      let newPageSizeOptions = {...this.state.pageSizeOptions};
      
      
      // newPageSizeOptions.push(1);
      
      this.setState({
        ...this.state,
        tableData: this.props.misc.reports
      });
      
    }
    
    return (<Container fluid className="main-content-container px-4 pb-4">
      
      <Row noGutters className="page-header py-4">
        <PageTitle title={this.props.authData.nomeEmpresa ? this.props.authData.nomeEmpresa : "DOMINUS SOLI"}  subtitle="Laudos" className="text-sm-left mb-3"/>
      </Row>
      <Card className="p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            <Row>
              <Col className="file-manager__filters__rows d-flex" md="4">
                <span>Exibir</span>
                <FormSelect
                  size="sm"
                  value={this.state.pageSize}
                  onChange={this.handlePageSizeChange}
                  disabled
                >
                  {pageSizeOptions.map((size, idx) => (<option key={idx} value={size}>
                    {size} linhas
                  </option>))}
                </FormSelect>
              </Col>
              <Col className="d-flex" md="4" style={{textAlign: "center"}}>
                <em>
                  <small>Obs: Mantenha "Shift" pressionado para ordenar mais de uma coluna.</small>
                </em>
              </Col>
              <Col className="file-manager__filters__search d-flex" md="4">
                <InputGroup seamless size="sm" className="ml-auto">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>
                      <i className="material-icons">search</i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormInput onChange={this.handleFilterSearch}/>
                </InputGroup>
              </Col>
            </Row>
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div className="">
            <ReactTable
              id={"sp-reports-history-table"}
              // Data
              columns={mainColumns}
              data={tableData}
              // Style
              style={{
                height: "605px"
              }}
              // Text
              previousText={'Anterior'}
              nextText={'Próximo'}
              loadingText={'Carregando dados...'}
              noDataText={'Nenhum informação encontrada! :('}
              pageText={'Página'}
              ofText={'de'}
              rowsText={'linhas'}
              // Accessibility Labels
              pageJumpText={'jump to page'}
              rowsSelectorText={'rows per page'}
              // Controlled table
              pivotBy={[]}
              filterable={true}
              resizable={true}
              showPageSizeOptions={false}
              defaultPageSize={10}
              pageSize={pageSize}
              showPageJump={true}
              loading={false}
              collapseOnSortingChange={true}
              collapseOnPageChange={true}
              collapseOnDataChange={true}
              multiSort={true}
              // Controlled props
              expanded={expanded}
              resized={resized}
              filtered={filtered}
              sorted={sorted}
              className={"-shadow"}
              // Callbacks
              onSortedChange={sorted => this.setState({sorted})}
              onPageChange={page => this.setState({page})}
              onPageSizeChange={(pageSize, page) => this.setState({
                page,
                pageSize
              })}
              onExpandedChange={expanded => this.setState({expanded})}
              onResizedChange={resized => this.setState({resized})}
              onFilteredChange={filtered => this.setState({filtered})}
              // SubComponent
              SubComponent={(row) => {
                const row_key = row.row._index;
                return (<div id={"subcomponente-" + row_key}
                             className={"border-bottom p-4 subcomponent"}>
                  <ReactTable
                    // Data
                    columns={subColumns}
                    data={[tableData[row_key]]}
                    // Controlled table
                    pivotBy={[]}
                    sortable={false}
                    filterable={false}
                    resizable={false}
                    showPageSizeOptions={false}
                    showPagination={false}
                    defaultPageSize={1}
                    pageSize={1}
                    // Controlled props
                    className={"border"}/>
                </div>)
              }}>
              
              {(state, makeTable) => {
                
                
                this.reactTable = state.sortedData.map((post) => {
                  return post._original
                });
                
                return (<div>
                  {makeTable()}
                </div>)
              }}
            
            </ReactTable>
          </div>
        </CardBody>
        <CardFooter className="d-flex justify-content-center">
          <Button theme="white" id="modal-handler" style={{fontSize: "1em"}}
                  onClick={this.modalClickHandler}>
            <i className="fas fa-download"/>
          </Button>
        </CardFooter>
      </Card>
      <ExcelSelectColumns open={this.state.exportModalOpen} empresa={this.props.authData.nomeEmpresa ? this.props.authData.nomeEmpresa : "DOMINUS SOLI"} toggle={this.modalClickHandler} data={this.reactTable} authData={this.props.authData}/>
    </Container>);
  }
}

const mapStateToProps = state => {
  return {
    misc: state.misc,
    authData: state.auth.userInfo
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchReportsList: () => dispatch(actions.fetchReportsList()),
    onToggleHiddenReport: (uuid, hidden) => dispatch(actions.hideReport(uuid,
      hidden)),
    onDeleteReport: (uuid) => dispatch(actions.deleteReport(uuid))
  }
};

export default connect(mapStateToProps,
  mapDispatchToProps)(
  NewReactTable);


