import React, {Component} from "react";

import classes from './EfficiencyMap.module.css'

import {LayersControl, Map, Popup, TileLayer} from 'react-leaflet'

import {connect} from 'react-redux'
import * as actions from '../../../../store/actions'

import GeoJSON from "react-leaflet/es/GeoJSON";

import {
  Button,
  Col,
  Row,
  FormCheckbox,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormTextarea,
  Collapse
} from "shards-react";

import FloatingData from '../../FloatingData/FloatingData'
import FloatingLegend from '../../FloatingLegend/FloatingLegend'

import Control from 'react-leaflet-control'
import ZoomControl from "react-leaflet/es/ZoomControl";
import Export from '../../../export-formats/ExportMapToPDF'
import {toastr} from 'react-redux-toastr'

class EfficiencyMap extends Component<> {
  state = {
    lat: 51.505,
    lng: -0.09,
    zoom: 13,
    bounds: null,
    modalOpen: false,
    mapTileLayers: [{
      key: 'mapbox.light',
      name: 'Black and White - Mapbox',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'https://api.tiles.mapbox.com/v4/mapbox.light/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiZG9taW51c3NvbGkiLCJhIjoiY2p2Zmg2cHhmMG95ZzQza3d1cjNxYjVxdiJ9.-Mx9La2cCP4tEXmDJf139g',
    }, {
      key: 'mapbox.satellite',
      name: 'Satélite - Mapbox',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'https://api.tiles.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiZG9taW51c3NvbGkiLCJhIjoiY2p2Zmg2cHhmMG95ZzQza3d1cjNxYjVxdiJ9.-Mx9La2cCP4tEXmDJf139g',
    }, {
      key: 'mapbox.outdoors',
      name: 'Outdoor - Mapbox',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'https://api.tiles.mapbox.com/v4/mapbox.outdoors/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiZG9taW51c3NvbGkiLCJhIjoiY2p2Zmg2cHhmMG95ZzQza3d1cjNxYjVxdiJ9.-Mx9La2cCP4tEXmDJf139g',
    }, {
      key: 'mapbox.dark',
      name: 'Dark - Mapbox',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'https://api.tiles.mapbox.com/v4/mapbox.dark/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiZG9taW51c3NvbGkiLCJhIjoiY2p2Zmg2cHhmMG95ZzQza3d1cjNxYjVxdiJ9.-Mx9La2cCP4tEXmDJf139g',
    }, {
      key: 'google.satellite',
      name: 'Satélite - Google Earth',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    }, {
      key: 'google.hybrid',
      name: 'Híbrido - Google Earth',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    }],
    layersControl: {
      'area': {
        color: '#086caa',
        weight: 1.5,
        opacity: 1,
        name: 'Área',
        visible: true
      },
      'aplicacao': {
        color: '#ff0215',
        weight: 0.4,
        opacity: 0.8,
        fillOpacity: 0.4,
        name: 'Aplicação',
        visible: true
      },
      'trajeto': {
        color: '#fff',
        weight: 1.0,
        opacity: 0.8,
        name: 'Trajeto',
        visible: false
      },
      'aplicacao-externa': {
        color: '#eaab11',
        weight: 1,
        opacity: 0.8,
        fillOpacity: 0.4,
        name: 'Aplicação Externa',
        visible: false
      },
      'area-nao-aplicada': {
        color: '#0d7d2d',
        weight: 1,
        opacity: 0.8,
        fillOpacity: 0.4,
        name: 'Área não Aplicada',
        visible: false
      }
    },
    layersTempGuide: {
      'Área não Aplicada': 'area-nao-aplicada',
      'Aplicação Externa': 'aplicacao-externa',
      'Aplicação': 'aplicacao',
      'Área': 'area'
    },
    observacoesModalOpen: false,
    observacoesModels: [{
      name: 'Presença de obstáculos informados pelo piloto.',
      key: 'OBSTACULOS_PRESENTES',
      checked: false
    }, {
      name: 'Aplicação dentro do padrão desejado.',
      key: 'DENTRO_DO_PADRAO',
      checked: false
    }, {
      name: 'Índice de efetividade abaixo do desejado.',
      key: 'BAIXA_EFETIVIDADE',
      checked: false
    }, {
      name: 'Área não aplicada concentrada.',
      key: 'NAO_APLICADA_CONCENTRADA',
      checked: false
    }, {
      name: 'Presença de sobreposição proposital.',
      key: 'SOBREPOSICAO_PROPOSITAL',
      checked: false
    }, {
      name: 'Presença de sobreposição errática.',
      key: 'SOBREPOSICAO_ERRATICA',
      checked: false
    }, {
      name: 'Presença de sobreposição proposital / errática.',
      key: 'SOB_PROPOSITAL_ERRATICA',
      checked: false
    }, {
      name: 'Alto índice de aplicação externa.',
      key: 'EXTERNO_ALTO',
      checked: false
    }, {
      name: 'Verificar.',
      key: 'VERIFICAR',
      checked: false
    }, {
      name: 'Verificar o fornecimento de insumos x área aplicada.',
      key: 'VERIFICAR_INSUMOS',
      checked: false
    },],
    observacoesText: '',
    report: {
      visible: true,
    },
  };

  leafletMap = null;
  leafletGeoJSON = null;
  leafletLayersGroup = null;
  laudoHasLayer;

  componentDidMount() {
    if (!this.props.report.loadingReport) {
      if (Object.keys(this.props.report).length > 1 && Object.keys(this.props.report).includes(this.props.uuid)) {
      } else {
        this.props.onLoadReport(this.props.uuid);
      }
    }

  }

  modalToggleHandler = () => {
    this.setState({modalOpen: !this.state.modalOpen})
  };

  setLeafletMapRef = (map) => {
    this.leafletMap = map && map.leafletElement;
  };

  setGeoJSONRef = (json) => {
    if (!json) {
      return false;
    }
    if (!this.leafletGeoJSON) {
      this.leafletGeoJSON = json.leafletElement;
      this.leafletMap.fitBounds(this.leafletGeoJSON.getBounds())
    }


  };

  onOverlayAddHandler = (e) => {
    if (Object.keys(this.state.layersTempGuide).includes(e.name)) {
      const layersControl = {...this.state.layersControl};
      layersControl[this.state.layersTempGuide[e.name]].visible = true;
      this.setState({layersControl: layersControl})
    }
  };

  onOverlayRemoveHandler = (e) => {
    if (Object.keys(this.state.layersTempGuide).includes(e.name)) {
      const layersControl = {...this.state.layersControl};
      layersControl[this.state.layersTempGuide[e.name]].visible = false;
      this.setState({layersControl: layersControl})
    }
  };

  onObservacoesModalToggle = () => {
    this.setState({
      observacoesModalOpen: !this.state.observacoesModalOpen
    })
  };

  setLayersGroupRef = (json) => {
    this.leafletLayersGroup = json && json.leafletElement;
  };

  onCheckboxChangeHangled = (index) => {
    const value = [...this.state.observacoesModels];
    value[index].checked = !value[index].checked;

    this.setState({
      ...this.state,
      observacoesModels: value
    })
  };

  onObservacoesUpdateHandler = () => {
    let obs = [];
    Object.keys(this.state.observacoesModels).forEach(key => {
      if (this.state.observacoesModels[key].checked) {
        obs.push(this.state.observacoesModels[key].key)
      }
    });

    this.props.onUpdateReportNotes(this.props.uuid,
      obs,
      this.state.observacoesText);
  };

  setTrajetoVisible = () => {
    const newState = {...this.state};
    newState.layersControl.trajeto.visible = true;
    this.setState(newState)
  };

  onReportToggle = () => {
    this.setState({
      ...this.state,
      report: {
        visible: !this.state.report.visible,
      },
    })
  };

  render() {
    const zoomControlStyle = {
      marginLeft: '300px'
    };

    const comments = <Modal open={this.state.observacoesModalOpen}
                            toggle={this.onObservacoesModalToggle} size="lg">
      <ModalHeader>ADICIONAR OBSERVAÇÕES</ModalHeader>
      <ModalBody>
        <h5>Selecione os itens relacionados e, caso necessário, complete:</h5>
        <Row>
          <fieldset className="w-100" style={{fontSize: '15px'}}>
            <Row>
              <Col>
                {this.state.observacoesModels.map((item, index) => {
                  const len = this.state.observacoesModels.length;
                  if (index < len / 2) {
                    return <FormCheckbox key={index}
                                         onChange={() => this.onCheckboxChangeHangled(index)}>{item.name}</FormCheckbox>
                  }
                })}
              </Col>
              <Col>
                {this.state.observacoesModels.map((item, index) => {
                  const len = this.state.observacoesModels.length;
                  if (index >= len / 2) {
                    return <FormCheckbox key={index}
                                         onChange={() => this.onCheckboxChangeHangled(index)}>{item.name}</FormCheckbox>
                  }
                })}
              </Col>
            </Row>
          </fieldset>
        </Row>
        <Row>
          <FormTextarea onChange={e => {
            this.setState({
              observacoesText: e.target.value
            })
          }}/>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="mr-auto btn-warning"
                onClick={this.onObservacoesModalToggle}>Cancelar</Button>
        <Button className="float-left btn-success"
                onClick={this.onObservacoesUpdateHandler}>Salvar</Button>
      </ModalFooter>
    </Modal>

    const commentsToggle = <div style={{marginBottom: "10px"}}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={classes.ModalToogleButton} onClick={this.onObservacoesModalToggle}>
        <i className="material-icons">toc</i>
      </a>
    </div>;

    return (
      <div>
        <Map zoom={this.state.zoom} className={classes.Map} ref={this.setLeafletMapRef}
             boundsOptions={{padding: [0, 0]}}
             zoomControl={false} onOverlayAdd={this.onOverlayAddHandler} onOverlayRemove={this.onOverlayRemoveHandler}>

          {this.props.report[this.props.uuid] ? <Collapse open={this.state.report.visible} className={'mt-5'}>
            <FloatingData laudoData={this.props.report[this.props.uuid].laudoInfo}
                          observacoesModel={this.state.observacoesModels}/> </Collapse> : null}

          {this.props.authData.nivel !== 'funcionario' ? comments : null}

          <Control position={"topleft"}>
            <div style={{marginBottom: "10px"}}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className={classes.ModalToogleButton} onClick={this.onReportToggle}>
                <i className="material-icons"> assignment </i>
              </a>
            </div>
          </Control>

          <Control position={"topright"}>

            {this.props.authData.nivel !== 'funcionario' ? commentsToggle : null}

            <div style={{marginBottom: "10px"}}>
              <Export id={"map"} label={"Export"}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className={classes.ModalToogleButton} onClick={(e) => {
                  e.preventDefault();
                  toastr.info("Gerando documento em PDF...", {position: 'top-right'})
                }}>
                  <i className="material-icons"> picture_as_pdf</i>
                </a>
              </Export>
            </div>

            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className={classes.ModalToogleButton}>
                <i className="material-icons"> timeline </i>
              </a>
            </div>

          </Control>

          <LayersControl position="topright" ref={this.setLayersGroupRef}>

            {this.state.mapTileLayers.map((value, index) => (
              <LayersControl.BaseLayer key={value.key} name={value.name} checked={value.key === 'mapbox.light'}>
                <TileLayer
                  attribution={value.attr}
                  url={value.url}
                />
              </LayersControl.BaseLayer>))}
            {this.props.report[this.props.uuid] ? this.props.report[this.props.uuid].collections.map(layer => (
              <LayersControl.Overlay
                name={this.state.layersControl[layer.properties.laudoHasLayer.layer].name}
                checked={this.state.layersControl[layer.properties.laudoHasLayer.layer].visible}
                key={layer.properties.laudoHasLayer.layer}
                randomProp={layer.properties.laudoHasLayer.layer === 'trajeto' && !this.state.layersControl.trajeto.visible ? this.setTrajetoVisible() : null}>
                <GeoJSON data={layer}
                         ref={layer.properties.laudoHasLayer.layer === 'area' ? this.setGeoJSONRef : null}
                         style={this.state.layersControl[layer.properties.laudoHasLayer.layer]}>

                </GeoJSON>
              </LayersControl.Overlay>

            )) : null}
            {/*<LayersControl.Overlay name="Area" checked>*/}
            {/*<GeoJSON data={data} style={this.state.layersControl.application} ref={this.setGeoJSONRef}>*/}
            {/*<Popup>*/}
            {/*A pretty CSS3 popup. <br/> Easily customizable. <br/>*/}
            {/*<Button onClick={this.modalToggleHandler}>Clique aqui!</Button>*/}
            {/*</Popup>*/}
            {/*</GeoJSON>*/}
            {/*</LayersControl.Overlay>*/}
          </LayersControl>

          <ZoomControl position={"topright"}/>

          <Control position={"bottomright"}>
            <FloatingLegend items={this.state.layersControl}/>
          </Control>

        </Map>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadReport: (uuid) => dispatch(actions.getReportFromUUID(uuid)),
    onUpdateReportNotes: (uuid, obsArray, obsText) => dispatch(actions.updateReportNotes(uuid,
      obsArray,
      obsText))
  }
};

const mapStateToProps = state => {
  return {
    report: state.report,
    authData: state.auth.userInfo
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EfficiencyMap);
