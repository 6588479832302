import * as actionTypes from './actionTypes';
import getInstance from '../../instances/axios/withAuth'

import showToastr from './toastr'

export const sendNewUser = (formData) => {
  return dispatch => {
    getInstance().post('/entidades/cadastro',
      formData,
      {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(res => {
      dispatch(showToastr('Sucesso',
        res.data.message,
        'success'));
      dispatch(requestProvidersSimpleList());
      dispatch(requestCompaniesSimpleList());
    }).catch(err => {
      dispatch(showToastr('Falha ao cadastrar',
        err.response.data.message,
        'warning'));
    })
  }
};


export const fetchReportsList = () => {
  return dispatch => {
    getInstance().get('/laudos/list').then(laudos => {
      dispatch({
        type: actionTypes.MISC_FETCH_REPORTS,
        payload: {reports: laudos.data}
      });
    }).catch(err => {
      console.log(err)
    })
  }
};

export const hideReport = (uuid, hidden) => {
  return dispatch => {
    getInstance().patch('/laudos/hide/' + uuid,
      {hide: hidden}).catch(err => console.log(err));
  }
};

export const deleteReport = (uuid) => {
  return dispatch => {
    getInstance().patch('/laudos/delete/' + uuid).catch(err => console.log(err));
  }
};

const fetchCompaniesSimpleList = companies => {
  console.log('SUCESSO [fetchCompaniesSimpleList]',
    companies);
  return {
    type: actionTypes.MISC_FETCH_COMPANIES,
    payload: companies
  }
};

export const requestCompaniesSimpleList = () => {
  return dispatch => {
    getInstance().get('/entidades/list/empresas').then(companies => {
      dispatch(fetchCompaniesSimpleList(companies));
    }).catch(err => {
      console.log('ERROR [requestCompaniesSimpleList]')
    })
  }
};

const fetchProvidersSimpleList = providers => {
  console.log('SUCESSO [fetchProvidersSimpleList]',
    providers);
  return {
    type: actionTypes.MISC_FETCH_PROVIDERS,
    payload: providers
  }
};

export const requestProvidersSimpleList = () => {
  return dispatch => {
    getInstance().get('/entidades/list/prestadoras').then(providers => {
      dispatch(fetchProvidersSimpleList(providers));
    }).catch(err => {
      console.log('ERROR [requestCompaniesSimpleList]')
    })
  }
};


const fetchDefaultDashboard = infos => {
  return {
    type: actionTypes.MISC_FETCH_PROVIDERS,
    payload: infos
  }
};

export const requestPieAndSeriesFromUUID = (uuid) => {
  return dispatch => {
    console.log(uuid);
    getInstance().get('/dashboard/single/'+uuid).then(laudo => {
      dispatch({type: actionTypes.DASHBOARD_SELECT_REPORT, payload: laudo.data})
    }).catch(e => console.log(e))
  }
};

export const toggleIsSelected = () => {
  return dispatch => {
      dispatch({type: actionTypes.DASHBOARD_TOGGLE_SELECTED})
  }
};

export const requestDefaultDashboard = (isLoading, isLoadingAuth) => {
  if (isLoading && isLoadingAuth) {
    return {type: 'NONE'}
  }
  return dispatch => {
    dispatch({type: actionTypes.DASHBOARD_FETCH_DEFAULT_START});
    getInstance().get('dashboard/').then(infos => {
      dispatch({type: actionTypes.DASHBOARD_FETCH_DEFAULT, payload: infos.data})
    }).catch(err => console.log(err) && dispatch({type: actionTypes.DASHBOARD_FETCH_DEFAULT_ERROR}))
  }
}

export const requestFilteredDashboard = (options, loading) => {
  if (loading) {
    return { type: 'NONE' }
  }
  return dispatch => {
    dispatch({type: actionTypes.DASHBOARD_FILTER_START});
    dispatch(showToastr('CARREGANDO...', 'Filtrando resultados...', 'info'));
    getInstance().post('dashboard/filter', options).then(dashboard => {
      console.log(dashboard.data);
      if (dashboard.data.message) {
        dispatch({type: actionTypes.DASHBOARD_FILTER_ERROR});
        return dispatch(showToastr('OPS...', dashboard.data.message, 'info'));
      }
      dispatch({type: actionTypes.DASHBOARD_FILTER_SUCCESS, payload: dashboard.data});
      dispatch(showToastr('SUCESSO!', 'Informações atualizadas com sucesso!'));
    }).catch(e => {
      dispatch({type: actionTypes.DASHBOARD_FILTER_ERROR});
      dispatch(showToastr('OPS...', 'Verifique ou limpe os campos do filtro e tente novamente.', 'warning'))
    });
  }
};
