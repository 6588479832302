import axios from 'axios';
import baseUrl from "./baseUrl";


const getInstance = () => axios.create({
  baseURL: baseUrl,
  // baseURL: 'http://localhost:8080/',
	headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
	withCredentials: false
});

export default getInstance;
