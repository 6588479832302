/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, {Component} from "react";
import {Link, Redirect} from "react-router-dom";
import {
  Button, Card, CardBody, Col, Container, Form, FormCheckbox, FormFeedback, FormGroup, FormInput, Row
} from "shards-react";
import validator from 'validator'

import {connect} from 'react-redux'

import * as actions from '../store/actions'
import {checkValidity, updateObject} from '../shared/utility';


class Login extends Component {
  
  state = {
    controls: {
      email: {
        element: {
          label: 'Email',
          type: 'email',
          id: 'loginEmailInput',
          placeholder: 'Insira um email válido',
          autoComplete: 'email',
          formFeedback: 'O email é inválido. Ex: pedro@email.com'
        },
        value: '',
        validation: [(value) => validator.isEmail(value)],
        required: true,
        valid: false,
        touched: false,
        showValid: false
      },
      password: {
        element: {
          label: 'Senha',
          type: 'password',
          id: 'loginPasswordInput',
          placeholder: '*******',
          autoComplete: 'current-password',
          formFeedback: 'A senha deve conter pelo menos 6 dígitos',
        },
        value: '',
        validation: [(value) => validator.isLength(value,
          {min: 6})],
        required: true,
        valid: false,
        touched: false,
        showValid: false
      }
    },
  };
  
  loginHandler = (e) => {
    e.preventDefault();
    this.props.onAuth(this.state.controls.email.value,
      this.state.controls.password.value);
  };
  
  
  
  inputChangedHandler = (event, controlName) => {
    const updatedControls = updateObject(this.state.controls,
      {
        [controlName]: updateObject(this.state.controls[controlName],
          {
            value: event.target.value,
            valid: checkValidity(event.target.value,
              this.state.controls[controlName].validation),
            touched: true
          })
      });
    this.setState({controls: updatedControls});
  };
  
  render() {
    let formElementsArray = [];
    for (let key in this.state.controls) {
      formElementsArray.push({
        id: key,
        config: this.state.controls[key]
      });
    }
    
    let form = formElementsArray.map(formElement => (<FormGroup key={formElement.id}>
        <label htmlFor={formElement.config.element.id}>{formElement.config.element.label}</label>
        <FormInput
          type={formElement.config.element.type}
          id={formElement.config.element.id}
          placeholder={formElement.config.element.placeholder}
          autoComplete={formElement.config.element.autoComplete}
          onChange={(event) => this.inputChangedHandler(event,
            formElement.id)}
          valid={formElement.config.valid && formElement.config.touched && formElement.config.showValid}
          invalid={!formElement.config.valid && formElement.config.touched}
        />
        <FormFeedback valid={false}>{formElement.config.element.formFeedback}</FormFeedback>
      </FormGroup>));

    let authRedirect = null;
    if (this.props.isAuthenticated) {
      authRedirect = <Redirect to={this.props.authRedirectPath}/>
    }
    
    return (<Container fluid className="main-content-container h-100 px-4">
      {authRedirect}
      <Row noGutters className="h-100">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Logo */}
              <img
                className="auth-form__logo d-table mx-auto mb-3"
                src={require("../images/SprayPlan.svg.png")}
                alt="SprayPlan"
                style={{
                  height: "60px",
                  width: "auto",
                  maxWidth: "none"
                }}
              />
              
              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                Acesse sua conta
              </h5>
              
              {/* Form Fields */}
              
              <Form>
                {form}
                {/*<FormGroup>
                  <FormCheckbox>Continuar conectado por 7 dias.</FormCheckbox>
                </FormGroup>*/}
                <Button
                  pill
                  onClick={this.loginHandler}
                  theme="accent"
                  className="d-table mx-auto"
                  type="submit"
                  disabled={!(this.state.controls.email.valid && this.state.controls.password.valid)}
                >
                  Acessar
                </Button>
              </Form>
            </CardBody>
            
            {/* Social Icons */}
            {/*<CardFooter>*/}
            {/*<ul className="auth-form__social-icons d-table mx-auto">*/}
            {/*<li>*/}
            {/*<a href="#">*/}
            {/*<i className="fab fa-facebook-f" />*/}
            {/*</a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*<a href="#">*/}
            {/*<i className="fab fa-twitter" />*/}
            {/*</a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*<a href="#">*/}
            {/*<i className="fab fa-github" />*/}
            {/*</a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*<a href="#">*/}
            {/*<i className="fab fa-google-plus-g" />*/}
            {/*</a>*/}
            {/*</li>*/}
            {/*</ul>*/}
            {/*</CardFooter>*/}
          </Card>
          
          {/* Meta Details */}
          <div className="auth-form__meta d-flex mt-4">
            <Link to="/forgot-password">Esqueceu sua senha?</Link>
            <Link to="/register" className="ml-auto">
              Problemas para conectar?
            </Link>
          </div>
        </Col>
      </Row>
    </Container>);
  }


}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token != null,
    authRedirectPath: state.auth.authRedirectPath
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email,
      password))
  }
};
export default connect(mapStateToProps,
  mapDispatchToProps)(
  Login);
