import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  ButtonGroup,
  Button
} from "shards-react";

import Chart from "../../utils/chart";
import colors from "../../utils/colors";

class LogHistoryBar extends React.Component {
  constructor(props) {
    super(props);

    this.legendRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  buildChart = () => {
    
    this.chartData = {
      labels: [""],
      datasets: [
        {
          label: "A. Plantio",
          fill: "start",
          data: [this.props.chartValues.areaPlantio],
          icons: '<i class="fas fa-minus"/>',
          backgroundColor: colors.teal.toRGBA(0.7),
          borderColor: colors.teal.toRGBA(0.7),
          pointBackgroundColor: colors.white.toHex(),
          pointHoverBackgroundColor: colors.teal.toRGBA(0.7),
          borderWidth: 1.5,
          hidden: false,
        },
        {
          label: "Insumos",
          fill: "start",
          data: [this.props.chartValues.insumos],
          icons: '<i class="fas fa-minus"/>',
          backgroundColor: colors.purple.toRGBA(0.7),
          borderColor: colors.purple.toRGBA(0.7),
          pointBackgroundColor: colors.white.toHex(),
          pointHoverBackgroundColor: colors.purple.toRGBA(0.7),
          borderWidth: 1.5,
          hidden: false,
        },
        {
          label: "A. Aplicada",
          fill: "start",
          data: [this.props.chartValues.areaAplicada],
          icons: '<i class="fas fa-minus"/>',
          backgroundColor: colors.cyan.toRGBA(0.7),
          borderColor: colors.cyan.toRGBA(0.7),
          pointBackgroundColor: colors.white.toHex(),
          pointHoverBackgroundColor: colors.cyan.toRGBA(0.7),
          borderWidth: 1.5,
          hidden: false,
        }, {
          label: "A. não Aplicada",
          fill: "start",
          data: [this.props.chartValues.areaNaoAplicada],
          icons: '<i class="fas fa-minus"/>',
          backgroundColor: colors.warning.toRGBA(0.7),
          borderColor: colors.warning.toRGBA(0.7),
          pointBackgroundColor: colors.white.toHex(),
          pointHoverBackgroundColor: colors.warning.toRGBA(0.7),
          borderWidth: 1.5,
          hidden: false,
        }
      ]
    };
    
    
    const chartOptions = {
      ...{
        shouldNotDisplayCustomLegend: true,
        responsive: true,
        legend: {
          display: true
        },
        // Uncomment the next line in order to disable the animations.
        // animation: false,
        tooltips: {
          enabled: false,
          mode: "index",
          position: "nearest",
          /*callbacks: {
           title: (tooltipItems, fieldData) => {
           //const {codigo, data} = this.props.entireChartData.info[tooltipItems[0].index];
           const dataObj = new Date(data);
           return ['CÓDIGO: <strong>'+codigo+'</strong>', '<p class="mb-0 pb-0 mt-0 pt-0 font-weight-normal">'+dataObj.toLocaleDateString('pt-BR')+'</p>' ]
           },
           label: (tooltipItem, data) => {
           var label = data.datasets[tooltipItem.datasetIndex].label || '';
           
           if (label) {
           label += ': ';
           }
           label += tooltipItem.yLabel + '%';
           return label;
           },
           footer: () => {
           return 'Clique para visualizar laudo.'
           }
           } */
        },
        scales: {
          xAxes: [
            {
              gridLines: false
            }
          ],
          yAxes: [
            {
              ticks: {
                callback: (value, index, values) => {
                  return value + ' ha';
                }
              }
            }
          ]
        },
      },
      maintainAspectRatio: false,
      ...this.props.chartOptions
    };
    
    
    console.log(this.chartData);
    const SalesReportChart = new Chart(this.canvasRef.current, {
      type: "bar",
      data: this.chartData,
      options: chartOptions
    });
    
    // Generate the chart labels.
    this.legendRef.current.innerHTML = SalesReportChart.generateLegend();
    
    // Hide initially the first and last chart points.
    // They can still be triggered on hover.
    const meta = SalesReportChart.getDatasetMeta(0);
    meta.data[0]._model.radius = 0;
    meta.data[
    this.chartData.datasets[0].data.length - 1
      ]._model.radius = 0;
    
    
    
    
    
    // Render the chart.
    SalesReportChart.render();
    
    this.setState({chartRef: SalesReportChart});
  }
  
  componentDidMount() {
    this.buildChart()
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.chartValues !== prevProps.chartValues) {
      this.state.chartRef.destroy()
      this.buildChart();
    }
  }
  
  render() {
    const {title} = this.props;
    console.log("render")
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">Área / Índices</h6>
          <div className="block-handle"/>
        </CardHeader>

        <CardBody className="pt-0" style={{maxHeight: (this.props.maxHeight - 20) + 'px'}}>
          <div ref={this.legendRef}/>
          <canvas
            height="120"
            ref={this.canvasRef}
            style={{maxWidth: "100% !important"}}
            className=""
          />
        </CardBody>
      </Card>
    );
  }
}

LogHistoryBar.propTypes = {
  /**
   * The title of the component.
   */
  title: PropTypes.string,
  /**
   * The chart data.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

LogHistoryBar.defaultProps = {
  title: "Área / Indice",
}
export default LogHistoryBar;
