import * as actionTypes from '../actions/actionTypes'
import {updateObject} from "../../shared/utility";


const initialState = {
	token: null,
	error: null,
	loading: false,
	authRedirectPath: '/',
	userInfo: {
		privileges: {},
		name: 'DESCONHECIDO',
		profilePicture: null
	},
	loggedOut: false
};

const authStart = (state, action) => {
	return updateObject(state,
		{
			error: null,
			loading: true,
			loggedOut: false
		})
};

const getRedirectPath = () => {
	const location = window.location.href.split('/');
	
	let redirectPath = '/';
	if (location[4] !== 'login') {
		redirectPath = '';
		location.slice(3,
			location.length).forEach((pathSlice, index) => {
			  if (index !== 0) {
          redirectPath += '/' + pathSlice
        }
		})
	}
	console.log("REDIRECT PATH", redirectPath)
	return redirectPath
};

const authSuccess = (state, action) => {
	return updateObject(state,
		{
			...action.payload,
			error: null,
			loading: false,
			authRedirectPath: getRedirectPath()
		})
};

const authUpdateUserInfo = (state, action) => {
	console.log(action.payload);
	return updateObject(state,
		action.payload);
};

const authLogout = (state, action) => {
	return updateObject(state,
		action.payload)
};

const authFail = (state, action) => {
	return updateObject(state,
		{
			error: action.payload.error,
			loading: false
		});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_SUCCESS:
			return authSuccess(state,
				action);
		case actionTypes.AUTH_START:
			return authStart(state,
				action);
		case actionTypes.AUTH_LOGOUT:
			return authLogout(state,
				action);
		case actionTypes.AUTH_UPDATE_USER_INFO:
			return authUpdateUserInfo(state,
				action);
		case actionTypes.AUTH_FAIL:
			return authFail(state,
				action);
		default:
			return state;
	}
};

export default reducer;
