import React from "react";
import {Link, Redirect} from "react-router-dom";
import {Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink} from "shards-react";

import {connect} from 'react-redux'

import * as actions from '../../../../store/actions'

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  onLogoutHandler = () => {
    this.props.onLogout()
  };

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }
  
  componentDidMount() {
    //this.redirect = this.props.isAuthenticated ? null : <Redirect to="/login"/>
  }
  
  
  render() {
    let userName = null;
    let profilePicture = null;
    if (this.props.userInfo) {
      userName = this.props.userInfo.name;
      profilePicture = this.props.userInfo.profilePicture
    }
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        {this.redirect}
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src={require("./../../../../images/avatars/profile_pic.png")}
            alt="User Avatar"
          />{" "}
          <span className="d-none d-md-inline-block">{userName}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          {/*<DropdownItem tag={Link} to="user-profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="edit-user-profile">
            <i className="material-icons">&#xE8B8;</i> Edit Profile
          </DropdownItem>
          <DropdownItem tag={Link} to="file-manager-list">
            <i className="material-icons">&#xE2C7;</i> Files
          </DropdownItem>
          <DropdownItem tag={Link} to="transaction-history">
            <i className="material-icons">&#xE896;</i> Transactions
          </DropdownItem>
          <DropdownItem divider/>*/}
          <DropdownItem onClick={this.onLogoutHandler}  className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.userInfo,
    isAuthenticated: state.auth.token !== null
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout())
  }
};

export default connect(mapStateToProps,
  mapDispatchToProps)(
  UserActions)
