import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux'
import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import thunk from 'redux-thunk'
import {reducer as toastrRedux} from 'react-redux-toastr'
import ReduxToastr from 'react-redux-toastr'

import CustomProgressBar from './components/layout/custom-progress-bar/ProgressBar/ProgressBar'

import authReducer from './store/reducers/auth'
import reportReducer from './store/reducers/report'
import miscReducer from './store/reducers/misc'
import progressBarReducer from './store/reducers/progressBar'
import dashboardReducer from './store/reducers/dashboard'
import ambientalReducer from './store/reducers/ambiental'

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
 // const composeEnhancers = compose;

const rootReducer = combineReducers({
  auth: authReducer,
  toastr: toastrRedux,
  report: reportReducer,
  misc: miscReducer,
  progressBar: progressBarReducer,
  dashboard: dashboardReducer,
  ambiental: ambientalReducer
});

const store = createStore(rootReducer,
  composeEnhancers(applyMiddleware(thunk)));

const app = (
  <Provider store={store}>
    <CustomProgressBar/>
    <App/>
    <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position="top-left"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick/>
  </Provider>
);

ReactDOM.render(app,
  document.getElementById('root'));

serviceWorker.unregister();
