import React, {Component} from 'react';
import {
  Button,
  ButtonGroup,
  Card, CardBody,
  CardHeader,
  Col,
  Container, FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import RangeDatePicker from "../components/common/RangeDatePicker";
import ReactTable from "react-table";
import dateFormat from "dateformat";
import FuzzySearch from "fuzzy-search";
import {connect} from 'react-redux'
import * as actions from '../store/actions'


class OrdemServicoLista extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // Data Tabale
      tableData: [
      ],
      //Columns Table
      columns: [
        {
          Header: "OS",
          accessor: "nome",
          minWidth: 150,
          className: "text-center",
          Filter: ({filter, onChange}) =>
            <input
              type={"text"}
              className={"text-center w-auto"}
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : ""}>
            </input>,

        },
        {
          Header: "Data",
          accessor: "dataPrevista",
          className: "text-center",
          minWidth: 150,
          Cell: row => {
            let date = new Date(row.original.dataPrevista);
            date.setDate(date.getDate() + 1);
            return date.toLocaleDateString('pt-BR');
          },
          Filter: ({filter, onChange}) =>
            <input
              type={"date"}
              className={"text-center w-auto"}
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : "all"}>
            </input>,
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            } else {
              return row[filter.id] === filter.value;
            }
          },
        },
        {
          Header: "PDF",
          accessor: "os.url",
          minWidth: 150,
          className: "text-center",
          sortable: false,
          filterable: false,
          Cell: row => {
            if (this.state.tableData.length > 0) {
              return (<ButtonGroup size="sm" className="d-table mx-auto">
                <Button theme="white" onClick={() => this.handleItemView(row)}>
                  <i className="material-icons">visibility</i>
                </Button>
              </ButtonGroup>)
            } else {
              return null;
            }
          }
        },
        {
          Header: "Ações",
          accessor: "actions",
          className: "text-center",
          minWidth: 100,
          sortable: false,
          filterable: false,
          Cell: row => {
            if (this.state.tableData.length > 0) {
              return (
                <ButtonGroup size="sm" className="d-table mx-auto">
                  <Button theme="danger" onClick={() => {
                    const row_key = row.row._index;
                    this.props.onDeleteFromUUID(this.state.tableData[row_key].uuid);
                  }}>
                    <i className="material-icons" >delete</i>
                  </Button>
                </ButtonGroup>)
            } else {
              return null;
            }
          },
        },
      ],
      // Control Table
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      sorted: [],
      expanded: {},
      resized: [],
      filtered: [],
    };

    this.searcher = null;

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleItemView = this.handleItemView.bind(this);

  };

  componentWillMount() {
  };

  componentDidMount() {
    this.searcher = new FuzzySearch(this.state.tableData, [
      "codigo",
      "os.url",
    ], {
      caseSensitive: false
    });

    this.props.onFetchServiceOrderList();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.osList !== prevProps.osList) {
      this.setState(
        {tableData: this.props.osList}
      )
    }
  }

  /**
   * Handles the page size change event.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {
    this.setState({
      ...this.state,
      tableData: this.searcher.search(e.target.value)
    });
  }

  /**
   * Mock method for details log.
   */
  handleItemView(row) {
    const row_key = row.row._index;
    this.props.onGetFileFromUUID(this.state.tableData[row_key].uuid);
  }

  render() {

    let {tableData, columns, pageSize, pageSizeOptions, sorted, expanded, resized, filtered} = this.state;

    return (

      <Container fluid className="main-content-container px-4 pb-4">

        <Row noGutters className="page-header py-4">
          <PageTitle title="ORDENS DE SERVIÇO" subtitle="Tabelas" className="text-sm-left mb-3"/>
          {/*<Col sm="4" className="d-flex ml-auto my-auto">*/}
            {/*<RangeDatePicker className="justify-content-end"/>*/}
          {/*</Col>*/}
        </Row>

        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>

                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="4">
                  <span>Exibir</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}>
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} linhas
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col className="d-flex" md="4" style={{textAlign: "center"}}>
                  <em>
                    <small>Obs: Mantenha "Shift" pressionado para ordenar mais de uma coluna.</small>
                  </em>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="4">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch}/>
                  </InputGroup>
                </Col>

              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                id={"sp-reports-history-table"}
                // Data
                columns={columns}
                data={tableData}
                // Style
                style={{height: "605px"}}
                // Text
                previousText={'Anterior'}
                nextText={'Próximo'}
                loadingText={'Carregando dados...'}
                noDataText={'Nenhum informação encontrada! :('}
                pageText={'Página'}
                ofText={'de'}
                rowsText={'linhas'}
                // Accessibility Labels
                pageJumpText={'jump to page'}
                rowsSelectorText={'rows per page'}
                // Controlled table
                pivotBy={[]}
                filterable={true}
                resizable={true}
                showPageSizeOptions={false}
                defaultPageSize={this.state.pageSize}
                pageSize={pageSize}
                showPageJump={true}
                loading={false}
                collapseOnSortingChange={true}
                collapseOnPageChange={true}
                collapseOnDataChange={true}
                multiSort={true}
                // Controlled props
                expanded={expanded}
                resized={resized}
                filtered={filtered}
                sorted={sorted}
                className={"-shadow"}
                // Callbacks
                onSortedChange={sorted => this.setState({sorted})}
                onPageChange={page => this.setState({page})}
                onPageSizeChange={(pageSize, page) => this.setState({page, pageSize})}
                onExpandedChange={expanded => this.setState({expanded})}
                onResizedChange={resized => this.setState({resized})}
                onFilteredChange={filtered => this.setState({filtered})}
              />
            </div>
          </CardBody>
        </Card>

      </Container>

    );

  }

}


const mapStateToProps = state => {
  return {
    osList: state.misc.osList,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchServiceOrderList: () => dispatch(actions.fetchServiceOrderList()),
    onGetFileFromUUID: (uuid) => dispatch(actions.getOsFileFromUUID(uuid)),
    onDeleteFromUUID: (uuid) => dispatch(actions.removeOsFromUUID(uuid))
}
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdemServicoLista);

