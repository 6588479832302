import * as actionTypes from './actionTypes';
import axios from '../../instances/axios'

import showToastr from './toastr'

export const authStart = () => {
	return {
		type: actionTypes.AUTH_START
	}
};

export const authSuccess = (authToken, userPrivileges, authUserName) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		payload: {
			token: authToken
		}
	}
};

export const authUpdateUserInfo = (data) => {
	return {
		type: actionTypes.AUTH_UPDATE_USER_INFO,
		payload: {userInfo: data}
	}
};

export const authFail = (authError) => {
	return {
		type: actionTypes.AUTH_FAIL,
		payload: {error: authError}
	}
};

export const logout = (showMessage = true) => {
	return dispatch => {
		localStorage.removeItem('token');
		localStorage.removeItem('tokenExpirationDate');
		if (showMessage) {
			dispatch(showToastr('Até logo! :)',
				'Logout realizado com sucesso',
				'info'));
		}
		dispatch(sendLogout())
	}
};

const sendLogout = () => {
	return {
		type: actionTypes.AUTH_LOGOUT,
		payload: {
			token: null,
			error: null,
			loading: false,
			authRedirectPath: '/',
			userInfo: {
        privileges: {},
        name: 'DESCONHECIDO',
        profilePicture: null
      },
			loggedOut: true
		}
	}
};

export const setRedirectPath = (authPath) => {
	return {
		type: actionTypes.AUTH_SET_REDIRECT_PATH,
		payload: {path: authPath}
	}
};

export const authCheckState = () => {
	return dispatch => {
		dispatch(authStart());
		const token = localStorage.getItem('token');
		if (!token) {
			return dispatch(logout(false));
		}
		const expirationDate = localStorage.getItem('tokenExpirationDate');;
		if (new Date(expirationDate) <= new Date()) {
			dispatch(showToastr('Erro :(', 'Falha ao restaurar sessão anterior. Faça login novamente', 'info'))
			return dispatch(logout(false));
		}
		
		axios.get('/entidades/user-info',
			{
				headers: {Authorization: `Bearer ${token}`},
				withCredentials: false
			})
			.then(response => dispatch(authSuccess(token,
				{},
				'REFRESH')) && dispatch(authUpdateUserInfo(response.data.userInfo)) && dispatch(showToastr('Bem-Vindo!',
				'Sessão restaurada com sucesso!')))
			.catch(err => dispatch(showToastr('Erro :(',
				'Falha ao restaurar sessão anterior. Faça login novamente.', 'info')) && dispatch(authFail("ERR")));
		
		
	}
};

export const auth = (authEmail, authPassword, authRemember = false) => {
	return dispatch => {
		dispatch(authStart());
		const authData = {
			email: authEmail,
			senha: authPassword
		};
		
		axios.post('login',
			authData)
			.then(response => {
				const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
				
				localStorage.setItem('token',
					response.data.token);
				localStorage.setItem('tokenExpirationDate',
					expirationDate);
				localStorage.setItem('remember',
					authRemember.toString());
				dispatch(showToastr('Bem-vindo!',
					'Login efetuado com sucesso!'));
				dispatch(authUpdateUserInfo(response.data.userInfo));
				dispatch(authSuccess(response.data.token))
			})
			.catch(err => {
				dispatch(showToastr('Falha no Login',
					err.response.data.message,
					'warning'));
				dispatch(authFail(err.response.data.message));
			});
	}
};
