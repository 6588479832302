import React, {Component} from "react";
import {BrowserRouter as Router, Route, Redirect} from "react-router-dom";
import {connect} from "react-redux";

import routes from "./routes";

import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";
import "leaflet/dist/leaflet.css"
import "izimodal/css/iziModal.min.css"
import "izimodal/js/iziModal.min"
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import "react-leaflet-sidebarv2/"

import * as actions from './store/actions'

class App extends Component {
	
	componentWillMount() {
		this.props.onTryLogin()
		console.log('ComponentWillMount')
	}
	
	render() {
		console.log('Render')
		let authRedirect = null;
		
		if (!this.props.auth.loading) {
			if (this.props.auth.error || this.props.auth.loggedOut) {
				authRedirect = <Redirect to={'/login'}/>;
			}
		}
		
		return (<Router basename={process.env.REACT_APP_BASENAME || "app/"}>
			<div>
				{routes.map((route, index) => {
					return (<Route
						key={index}
						path={route.path}
						exact={route.exact}
						component={withTracker(props => {
							return (<route.layout {...props}>
								{route.path !== '/login' ? authRedirect : null}
								<route.component {...props} />
							</route.layout>);
						})}
					/>);
				})}
			</div>
		</Router>)
	}
	;
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.token !== null,
		auth: state.auth,
    authData: state.auth.userInfo
	}
};

const mapDispatchToProps = dispatch => {
	return {
		onTryLogin: (location) => dispatch(actions.authCheckState(location))
	}
};

export default connect(mapStateToProps,
	mapDispatchToProps)(
	App)
