import * as actionTypes from './actionTypes';
import getInstance from '../../instances/axios/withAuth'

import showToastr from './toastr'

export const sendNewUser = (formData) => {
  return dispatch => {
    getInstance().post('/entidades/cadastro',
      formData,
      {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(res => {
      dispatch(showToastr('Sucesso',
        res.data.message,
        'success'));
      dispatch(requestProvidersSimpleList());
      dispatch(requestCompaniesSimpleList());
    }).catch(err => {
      dispatch(showToastr('Falha ao cadastrar',
        err.response.data.message,
        'warning'));
    })
  }
};
export const sendNewAmbientalUser = (formData) => {
  return dispatch => {
    getInstance().put('/entidades/cadastro/ambiental',
      formData,
      {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(res => {
      dispatch(showToastr('Sucesso',
        res.data.message,
        'success'));
    }).catch(err => {
      dispatch(showToastr('Falha ao cadastrar',
        err.response.data.message,
        'warning'));
    })
  }
};
export const sendNewPrestador = (formData) => {
  return dispatch => {
    getInstance().put('/entidades/cadastro/prestador',
      formData,
      {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(res => {
      dispatch(showToastr('Sucesso',
        res.data.message,
        'success'));
    }).catch(err => {
      dispatch(showToastr('Falha ao cadastrar',
        err.response.data.message,
        'warning'));
    })
  }
};

export const fetchReportsList = () => {
  return dispatch => {
    getInstance().get('/laudos/list').then(laudos => {
      dispatch({
        type: actionTypes.MISC_FETCH_REPORTS,
        payload: {reports: laudos.data}
      });
    }).catch(err => {
      console.log(err)
    })
  }
};

export const fetchFilteredReportsList = (filter) => {

  return (dispatch) => {

      dispatch({type: actionTypes.MISC_START_LOADING_FILTERED_FETCH__REPORTS});
      dispatch(showToastr('Relatório de Efetividade:', 'Resgatando as Aplicações...', 'info'));

      getInstance().get('/laudos/list', filter).then((result) => {

          if (result.data.message) {
              dispatch({type: actionTypes.DASHBOARD_FILTER_ERROR});
              return dispatch(showToastr('Relatório de Efetividade:', result.data.message, 'warning'));
          }

          dispatch({
              type: actionTypes.MISC_SUCCESS_LOADING_FILTERED_FETCH__REPORTS,
              payload: {filteredReports: result.data}
          });
          dispatch(showToastr('Relatório de Efetividade:', 'As Aplicações foram resgatadas!'));

      }).catch((err) => {

          dispatch({type: actionTypes.MISC_ERROR_LOADING_FILTERED_FETCH__REPORTS});
          dispatch(showToastr('Relatório de Aplicações:', 'Ocorreu um erro ao resgatar as Aplicações!', 'danger'))

      })
  }
};

export const fetchAmbientalList = () => {
  return dispatch => {
    getInstance().get('/ambiental/list').then(laudos => {
      dispatch({
        type: actionTypes.MISC_FETCH_AMBIENTAL,
        payload: {ambiental: laudos.data}
      });
    }).catch(err => {
      console.log(err)
    })
  }
};

export const fetchServiceOrderList = () => {
  return dispatch => {
    getInstance().get('/ambiental/os-list').then(laudos => {
      dispatch({
        type: actionTypes.MISC_FETCH_OS_LIST,
        payload: {osList: laudos.data}
      });
    }).catch(err => {
      console.log(err)
    })
  }
};

export const hideReport = (uuid, hidden) => {
  return dispatch => {
    getInstance().patch('/laudos/hide/' + uuid,
      {hide: hidden}).catch(err => console.log(err));
  }
};

export const deleteReport = (uuid) => {
  return dispatch => {
    getInstance().patch('/laudos/delete/' + uuid).catch(err => console.log(err));
  }
};

export const denyOrApproveAmbientalData = (uuid, state) => {
  return dispatch => {
    getInstance().patch('/ambiental/'+uuid+'/'+state).then(() => {
      dispatch({
        type: actionTypes.AMBIENTAL_DENY_OR_APPROVE,
        payload: {
          hidden: state !== 'approve',
          uuid
        }
      })
    }).catch(err => {
      console.log(err);
    })
  }
}

const fetchCompaniesSimpleList = companies => {
  console.log('SUCESSO [fetchCompaniesSimpleList]',
    companies);
  return {
    type: actionTypes.MISC_FETCH_COMPANIES,
    payload: companies
  }
};

export const requestCompaniesSimpleList = () => {
  return dispatch => {
    getInstance().get('/entidades/list/empresas').then(companies => {
      dispatch(fetchCompaniesSimpleList(companies));
    }).catch(err => {
      console.log('ERROR [requestCompaniesSimpleList]')
    })
  }
};

const fetchProvidersSimpleList = providers => {
  console.log('SUCESSO [fetchProvidersSimpleList]',
    providers);
  return {
    type: actionTypes.MISC_FETCH_PROVIDERS,
    payload: providers
  }
};

export const requestProvidersSimpleList = () => {
  return dispatch => {
    getInstance().get('/entidades/list/prestadoras').then(providers => {
      dispatch(fetchProvidersSimpleList(providers));
    }).catch(err => {
      console.log('ERROR [requestCompaniesSimpleList]')
    })
  }
};

const fetchPrestadorSimpleList = prestador => {
  console.log('SUCESSO [fetchPrestadorSimpleList]', prestador);
  return {
    type: actionTypes.MISC_FETCH_PRESTADOR,
    payload: prestador
  }
};

export const requestPrestadorSimpleList = () => {
  return dispatch => {
    getInstance().get('/entidades/list/prestador').then(prestador => {
      dispatch(fetchPrestadorSimpleList(prestador));
    }).catch(err => {
      console.log('ERROR [requestPrestadorSimpleList]')
    })
  }
};

const fetchTipoAplicacaoList = tipoAplicacao => {
  console.log('SUCESSO [fetchTipoAplicacaoList]', tipoAplicacao);
  return {
    type: actionTypes.MISC_FETCH_TIPOAPLICACAO,
    payload: tipoAplicacao
  }
};

export const requestTipoAplicacaoList = () => {
  return dispatch => {
    getInstance().get('/entidades/list/tipoAplicacao').then(tipoAplicacao => {
      dispatch(fetchTipoAplicacaoList(tipoAplicacao));
    }).catch(err => {
      console.log('ERROR [requestTipoAplicacaoList]')
    })
  }
};

export const sendNewLocation = (formData) => {
  return dispatch => {
    getInstance().put('/entidades/cadastro/location',
      formData,
      {
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(res => {
      dispatch(showToastr('Sucesso',
        "Região cadastrada com sucesso.",
        'success'));
      dispatch({
        type: actionTypes.LOCATION_ADD_NEW,
        payload: res.data
      });
    }).catch(err => {
      dispatch(showToastr('Falha ao cadastrar',
        err.message,
        'warning'));
    })
  }
};
