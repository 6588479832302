import React, {Component} from "react";
import {
	Button, Card, CardHeader, Col, Container, Form, FormCheckbox, FormInput, FormSelect, ListGroup, ListGroupItem, Row
} from "shards-react";
import {connect} from 'react-redux'

import {toastr} from 'react-redux-toastr'

import ProgressBar from '../components/layout/custom-progress-bar/ProgressBar/ProgressBar'
import PageTitle from "../components/common/PageTitle";
import * as actions from '../store/actions'
import validator from "validator";
import contentArrays from "../utils/arrays";

class NewServiceOrder extends Component {

  initialState = {
    form: {
      data: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isDate: true
        }
      },
      os: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        name: 'os',
        validator: {
          isLength: 3
        },
      },
      anexoOrdemServico: {
        value: 'Escolha um arquivo...',
        valid: false,
        shouldValidate: true,
        touched: false,
        store: false,
        name: 'file_anexo_1',
        validator: {
          isFile: true
        }
      },
      empresa: {
        value: null,
        name: 'empresaId',
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isIn: this.props.arrayEmpresas
        }
      },

    }
  };

	state = {...this.initialState};
	objectPrestadoras = {};

	componentDidMount() {
	  this.props.onLoadCompaniesSimpleList();
	}

	onFormSendHandler = e => {
		e.preventDefault();
		let counter = 0, validCounter = 0;
		let invalid = '';
		for (let key in this.state.form) {
			if (this.state.form.hasOwnProperty(key)) {
				if (this.state.form[key].valid) {
					validCounter++;
				} else {
				  invalid = key;
        }
				counter++;
			}
		}
		if (validCounter !== counter) {
			return toastr.warning('Não Enviado', 'Verifique as informações e tente novamente - [' + invalid + ']', {position: 'top-right'});
    }
		let formData = new FormData();
		for (let key in this.state.form) {
			if (this.state.form.hasOwnProperty(key)) {
				let itemName = key;
				if (this.state.form[key].name) {
					itemName = this.state.form[key].name
				}
				if (this.state.form[key].store) {
					formData.append(itemName,
						this.state.form[key].store)
				} else {
					formData.append(itemName,
						this.state.form[key].value);
				}
				console.log(itemName,
					this.state.form[key]);
			}
		}

		this.props.onSendNewServiceOrder(formData, () => {
		  this.setState({...this.state, ...this.initialState});
		  const inputsList = document.getElementsByTagName('input');
		  const selectsList = document.getElementsByTagName('select');

		  console.log('INPUTS: ', inputsList)

      for (let input of inputsList) {
        input.value = ''
      }

      for (let select of selectsList) {
        select.selectedIndex = 0;
      }

		  // inputsArray.forEach(input => {
		  //   input.value = ''
      // })
    });
    toastr.info('Cadastrando...', 'Enviando OS para o sistema...', {position: 'top-right'});

	};

	inputChangedHandler(e, key) {
		console.log(key);
		console.log(this.props.misc);
		let obj = {...this.state.form[key]};
		let incomingValue = e.target.value;

		let valid = true;

		if (obj.validator) {
			if (obj.validator.isInObjArray) {
				this.objectPrestadoras = this.props.misc.prestadoras.reduce((arr, cur, i) => {
						const {id, nome, pilotos} = cur;
						console.log(id,
							nome,
							pilotos,
							cur);
						arr[id] = {
							nome: nome,
							pilotos: pilotos
						};
						return arr
					},
					{});
				valid = this.objectPrestadoras[incomingValue] && valid;
			}
			if (obj.validator.isEmail) {
				valid = validator.isEmail(incomingValue) && valid;
			}
			if (obj.validator.isLength) {
				valid = validator.isLength(incomingValue,
					{min: obj.validator.isLength}) && valid;
			}
			if (obj.validator.equals) {
				valid = validator.equals(incomingValue,
					obj.validator.equals) && valid;
			}
			if (obj.validator.isIn) {
				valid = validator.isIn(incomingValue,
					obj.validator.isIn) && valid;
			}
			if (obj.validator.isFloat) {
				valid = validator.isFloat(incomingValue) && valid;
			}
			if (obj.validator.isDate) {
				valid = new Date(incomingValue) < new Date() && valid;
			}
			if (obj.validator.isFile) {
				//incomingValue = incomingValue.files[0].name
				valid = e.target.files.length === 1 && valid;
				if (valid) {
					incomingValue = e.target.files[0].name;
					const splitValue = incomingValue.split('.');
					if (splitValue[splitValue.length - 1].toLowerCase() !== 'pdf') {
						valid = false;
						incomingValue = 'Escolha um arquivo...'
					} else {
						obj.store = e.target.files[0];
            console.log(obj.store.size);
					}
				} else {
					incomingValue = 'Escolha um arquivo...'
				}
			}
		}

		obj.valid = valid;
		obj.value = incomingValue;
		obj.touched = true;

		console.log(obj);

		this.setState(oldState => ({
			form: {
				...oldState.form,
				[key]: obj
			}
		}))
	}

	render() {
	  const seq = [];
	  if (this.props.info.includes('[A]')){
	    seq.push("[A]");
    }
	  if (this.props.info.includes('[B]')){
	    seq.push("[B]");
    }
	  if (this.props.info.includes('[C]')){
	    seq.push("[C]");
    }
	  if (this.props.info.includes('[D]')){
	    seq.push("[D]");
    }
	  if (this.props.info.includes('[E]')){
	    seq.push("[E]");
    }
	  if (this.props.info.includes('[F]')){
	    seq.push("[F]");
    }

		return (<Container fluid className="main-content-container px-4">
			<Row noGutters className="page-header py-4">
				<PageTitle sm="4" title="Ordem de Serviço" subtitle="Cadastro" className="text-sm-left"/>
			</Row>
			<Row>
				<Col>
					<Card small>
						<CardHeader className="border-bottom">
							<h6 className="m-0">Dados Cadastrais</h6>
						</CardHeader>


						<ListGroup flush>
							<ListGroupItem className="p-3">
								<Row>
									<Col>
										<Form>
                      <Row form>
                        <Col md="3" className="form-group">
                          <label htmlFor="inputSelectEmpresa">Região</label>
                          <FormSelect
                            id="inputSelectEmpresa"
                            invalid={!this.state.form.empresa.valid && this.state.form.empresa.touched && this.state.form.empresa.shouldValidate}
                            onChange={(event) => this.inputChangedHandler(event,
                              'empresa')}>
                            <option>Escolha...</option>
                            {this.props.misc.empresas ? this.props.misc.empresas.map(el => {
                              let shouldShow = false;
                              seq.forEach(item => {
                                if (el.nome.includes(item)){
                                  shouldShow = true;
                                }
                              });
                              if (!shouldShow) {
                                return null;
                              }
                              return (
                                <option value={el.id}
                                        key={el.id}>{el.nome}</option>)
                            }) : null}
                          </FormSelect>
                        </Col>
                        <Col md="3" className="form-group">
                          <label htmlFor="inputCodigo">Código (OS)</label>
                          <FormInput
                            id="inputCodigo"
                            type="text"
                            placeholder="OS 1234"
                            invalid={!this.state.form.os.valid && this.state.form.os.touched && this.state.form.os.shouldValidate}
                            onChange={(event) => this.inputChangedHandler(event,
                              'os')}/>
                        </Col>
                        <Col md="3" className="form-group">
                          <label htmlFor="inputSelectData">Data</label>
                          <input type="date"
                                 id="inputSelectData"
                                 className={(!this.state.form.data.valid && this.state.form.data.touched && this.state.form.data.shouldValidate) ? "form-control is-invalid" : "form-control"}
                                 onChange={(event) => this.inputChangedHandler(event,
                                   'data')}
                          />
                        </Col>
                        <Col md="3" className="form-group">
                          <label htmlFor="">Ordem de Serviço (.pdf)</label>
                          <div className="custom-file mb-3">
                            <input type="file"
                                   className={(!this.state.form.anexoOrdemServico.valid && this.state.form.anexoOrdemServico.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
                                   onChange={(event) => this.inputChangedHandler(event,
                                     'anexoOrdemServico')}/>
                            <label className="custom-file-label" htmlFor="anexoOrdemServico">
                              {this.state.form.anexoOrdemServico.value}
                            </label>
                          </div>
                        </Col>

                      </Row>

											<Button type="submit" theme="success" disabled={!this.props.sentRequest}
													onClick={event => this.onFormSendHandler(event)}>Cadastrar</Button>

										</Form>
									</Col>
								</Row>
							</ListGroupItem>
						</ListGroup>


					</Card>
				</Col>
			</Row>
		</Container>)
	}
}

const mapDispatchToProps = dispatch => {
	return {
	  onSendNewServiceOrder: (formData, clearForm) => dispatch(actions.sendNewServiceOrder(formData, clearForm)),
		onLoadCompaniesSimpleList: () => dispatch(actions.requestCompaniesSimpleList()),
	}
};
const mapStateToProps = state => {
	return {
		misc: state.misc,
    info: String(state.auth.userInfo.funcao),
    sentRequest: state.progressBar.percentage === 0
	}
};

export default connect(mapStateToProps,
	mapDispatchToProps)(
	NewServiceOrder);
