import * as actionTypes from './actionTypes';

export const updateProgressBar = (percentage) => {
  return {
    type: actionTypes.PROGRESS_CHANGE,
    payload: {
      percentage: percentage
    }
  }
};
