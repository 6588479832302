import React from "react";
import PropTypes from "prop-types";
import {Container, Row} from "shards-react";
import colors from "../../utils/colors.js"

class EffectivenessReportParameterHeader extends React.Component {

    componentDidMount() {
        console.log("EffectivenessReportParameterHeader [componentDidMount]");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            console.log("EffectivenessReportParameterHeader [componentDidUpdate]");
        }
    }

    convertDate(date) {

        let newDate = new Date(date + " 00:00:01");
        return newDate.toLocaleDateString("pt-BR");

    }

    render() {

        console.log("EffectivenessReportParameterHeader [render]");

        const {empresa, prestadora, tratamento, dataInicial, dataFinal} = this.props.data;

        return (
            <Container id={"re_header_cont"} fluid>
                <Row>
                    <div className="table-responsive">
                        <table className="table table-borderless text-center border-bottom">
                            <thead style={{
                                color: colors.white.toRGBA(),
                                backgroundColor: colors.gray_default_1.toRGBA(),
                                verticalAlign: "middle"
                            }}>
                            <tr>
                                <th scope="col" className={"border border-white"}>
                                    EMPRESA
                                </th>
                                <th scope="col" className={"border border-white"}>
                                    PRESTADORA
                                </th>
                                <th scope="col" className={"border border-white"}>
                                    TRATAMENTO
                                </th>
                                <th scope="col" className={"border border-white"} colSpan={"2"}>
                                    PERÍODO
                                </th>
                            </tr>
                            </thead>
                            <tbody style={{
                                color: colors.reagentGray.toRGBA(),
                                backgroundColor: colors.white.toRGBA()
                            }}>
                            <tr>
                                <td>{(empresa).toUpperCase()}</td>
                                <td>{(prestadora).toUpperCase()}</td>
                                <td>{(tratamento).toUpperCase()}</td>
                                <td>{this.convertDate(dataInicial)}</td>
                                <td>{this.convertDate(dataFinal)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </Row>
            </Container>
        );
    }
}

EffectivenessReportParameterHeader.propTypes = {
    /**
     * The latest orders data.
     */
    data: PropTypes.object
};

EffectivenessReportParameterHeader.defaultProps = {
    data: {
        empresa: "TEXT",
        prestadora: "TEXT",
        tratamento: "TEXT",
        dataInicial: new Date().toLocaleString("pt-BR"),
        dataFinal: new Date().toLocaleString("pt-BR"),
    }
};

export default EffectivenessReportParameterHeader;
