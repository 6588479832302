import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class tableToExcel extends React.Component {
  
  render() {
    
    const cellStyle = {
      alignment: {horizontal: "left", vertical: "center"},
      border: {
        top: {style: 'thin', color: { rgb: "#000" }},
        bottom: {style: 'thin', color: { rgb: "#000" }},
        left: {style: 'thin', color: { rgb: "#000" }},
        right: {style: 'thin', color: { rgb: "#000" }},
      }
    };
    
    const dataSet = [
      {
        columns: this.props.columns.map(post => {
          return ({
            title: "",
            width: {wpx: 186},
          });
        }),
        data: this.props.data.map((post, mapIndex) => {
          const row = [];
          if (post.status === "Pronto"){
            this.props.columns.forEach((el, index)=> {
              let obj = {};
              if (true){
                if (el.key !== 'prestadora' && !post[el.key]){
                  console.error("[NÃO ENCONTRADO] " + mapIndex + ":", el.key, post, post[el.key]);
                }
              }
              if (el.key === 'piloto') {
                obj.value = post[el.key].nome
              }else if (el.key === 'empresa'){
                obj.value = post[el.key].nome
              }else if (el.key === 'prestadora'){
                obj.value = post['piloto'].prestadora.nome
              }else if (el.key === 'data' || el.key === 'createdAt') {
                obj.value = new Date(post[el.key]).toLocaleDateString('pt-BR')
              }else {
                obj.value = post[el.key]
              }
              obj.style = cellStyle;
              row.push(obj);
            });
          }
          return (row);
        }).filter(el => el.length > 0),
      }
    ];
    
    dataSet[0].data.unshift(this.props.columns.map(post => {
      return ({
        value: post.name,
        style: {
          ...cellStyle,
          font: {sz: "12", bold: true}
        }
        
      });
    }));
    
    const datas = {}
    if (this.props.data.length > 0) {
      datas.inicial = new Date(this.props.data[0].data).toLocaleDateString('pt-BR');
      datas.final = new Date(this.props.data[this.props.data.length - 1].data).toLocaleDateString('pt-BR')
    }
    
    dataSet.unshift(
      {
        columns: ["[SPRAY PLAN] " + this.props.empresa + " - CONTROLE DE LAUDOS - " + datas.inicial + " - " + datas.final],
        data: []
      });
    
    dataSet.push(
      {
        ySteps: 1,
        columns: ["DATA DE CONSULTA: " + new Date().toLocaleString('pt-BR')],
        data: []
      }
    )
    
    return (
      <div className={"p-3"}>
        <ExcelFile element={
          <button id={this.props.idButton} className={this.props.classButton}
                  style={{fontSize: "1rem"}}>
            {this.props.buttonText}
          </button>}
                   filename={this.props.nameFile}>
          <ExcelSheet dataSet={dataSet} name={this.props.nameSheet}/>
        </ExcelFile>
      </div>
    );
  }
}

export default tableToExcel;
