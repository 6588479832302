import React from 'react'

import {Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Collapse} from "shards-react";

import classes from './FloatingLegend.module.css'

const floatingLegend = props => (<Col md="3" id="floatingLegendCard">
  <Card style={props.width ? {width: '300px'} : {width: '200px'}} className={[classes.Legend, classes.LegendTransparent, "p-0"].join(" ")}>
    <CardHeader className={["pb-0", classes.LegendTransparent].join(" ")} onClick={() => props.toggler()}>
      <h6><small className="pr-3 text-black-50">{props.open ? "▼" : "▲"}</small>Legenda</h6>
    </CardHeader>
    <Collapse open={props.open}>
      <CardBody className={[classes.LegendTransparent, "p-1 pl-4 pr-4"].join(" ")}>
    
        {Object.keys(props.items).map((item, index) => (<Row key={index}><p className={classes.LegendItem}
                                                                            style={{display: props.items[item].visible ? "inline-flex" : "none"}}>
          <span style={{backgroundColor: props.items[item].color, margin: 'auto', height: '3px', width: '3px', border: '1px solid rgba(210, 210, 210, 1)'}} className={classes.LegendItemColor}/>
          <span style={{whiteSpace: 'normal'}}>{props.items[item].name}</span></p></Row>))}
      </CardBody>
    </Collapse>
    
    <CardFooter className={[classes.LegendTransparent, "p-1"].join(" ")}/>
  </Card>
</Col>);

export default floatingLegend;
