export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  
  rules.forEach(validation => {
    isValid = validation(value) && isValid
  });

  return isValid;
};
