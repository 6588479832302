import * as actionTypes from './actionTypes';
import getInstance from '../../instances/axios/withAuth'

import showToastr from './toastr'
import {updateProgressBar} from './index';

const loadReportFromUUID = (laudo, uuid) => {
  return {
    type: actionTypes.REPORT_LOAD_NEW,
    payload: {
      [uuid]: laudo
    }
  }
};

export const getReportFromUUID = (uuid) => {
  return dispatch => {
    dispatch({type: actionTypes.REPORT_START_LOADING_NEW});
    getInstance().get('/laudos/'+uuid).then(laudo => {
      dispatch(loadReportFromUUID(laudo.data, uuid));
      dispatch({type: actionTypes.REPORT_SUCCESS_LOADING_NEW});
	}).catch(err => {
		dispatch({type: actionTypes.REPORT_ERROR_LOADING_NEW});
		console.log(err);
    })
  }
  //fetch app
  //validate reponse
  //dispatch action with full information
};


export const updateReportNotes = (uuid, obsArray, obsText) => {
  return dispatch => {
    getInstance().patch('/laudos/obs/'+uuid, {obsArray: obsArray, obsText: obsText}).then(laudo => {
      dispatch({type: actionTypes.REPORT_UPDATE_NOTES, payload: {laudoInfo: laudo.data, uuid: uuid}});
      dispatch(showToastr('Observações salvas', 'As modificações nas observações foram salvas...'));
  
    }).catch(err => {
      dispatch(showToastr('Erro ao salvar', 'Problemas aconteceram ao modificar as observações...', 'warning'));
  
      console.log(err);
    })
  }
  //fetch app
  //validate reponse
  //dispatch action with full information
};

export const sendNewApplicationReport = (formData, clearForm) => {
  console.log('[CLEAR FORM]',clearForm)
  return dispatch => {
    getInstance().put('/laudos/add', formData, {
      headers: {'Content-Type': 'multipart/form-data' },
      onUploadProgress: function(progressEvent) {
        dispatch(updateProgressBar(Math.round( (progressEvent.loaded * 100) / progressEvent.total )))
      }
    }).then(response => {
      clearForm();
      dispatch(showToastr('Laudo Adicionado', 'O laudo estará disponível após o processamento...'));
      dispatch(updateProgressBar(0))
    }).catch(err => {
      console.log(err);
      dispatch(updateProgressBar(0));
      dispatch(showToastr('Falha ao cadastrar', 'Verifique os campos e tente novamente.'));
    })
  }
};
