import React from 'react';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import domToImage from 'dom-to-image';

const onExport = (id) => {
  
  const input = document.getElementById(id);
  const control_tr = document.getElementsByClassName("leaflet-top leaflet-right")[0];
  const control_tl = document.getElementsByClassName("leaflet-top leaflet-left")[0];
  
  control_tr.style.visibility = "hidden";
  control_tl.style.visibility = "hidden";
  
  domToImage.toPng(input, {})
    .then((dataUrl) => {
      control_tr.style.visibility = "visible";
      control_tl.style.visibility = "visible";
      const imgData = dataUrl;
      let w = input.offsetWidth;
      let h = input.offsetHeight;
      let pdf = new JsPDF(
        {
          orientation: w > h ? 'landscape' : 'portrait',
          unit: 'pt',
          format: [w, h]
        }
      );
      pdf.addImage(imgData, 'PNG', 0, 0, input.offsetWidth, input.offsetHeight);
      pdf.save(`Laudo-SprayPlan-${new Date().toLocaleDateString('pt-br')}.pdf`);
    });
};

const PrintButton = ({id, children}) => (
  <div className="tc mb4 mt2">
    <div onClick={() => onExport(id)}>
      {children}
    </div>
  </div>);

export default PrintButton;
