import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
  charts: {
    stack: {},
    series: {},
    pie: {},
    selectedReport: {codigo: null, uuid: null, isSelected: false}
  },
  smallStats: {},
  loading: false,
  ready: false
};

const toggleSelected = (state) => {
  const newState = {...state};
  newState.charts.selectedReport.isSelected = !newState.charts.selectedReport.isSelected;
  return updateObject(state, newState)
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_FETCH_DEFAULT: return updateObject(state, {...action.payload, loading: false, ready: true});
    case actionTypes.DASHBOARD_FETCH_DEFAULT_ERROR: return updateObject(state, {loading: false, ready: true});
    case actionTypes.DASHBOARD_FETCH_DEFAULT_START: return updateObject(state, {loading: true});
    case actionTypes.DASHBOARD_SELECT_REPORT: return updateObject(state, action.payload);
    case actionTypes.DASHBOARD_TOGGLE_SELECTED: return toggleSelected(state);
    case actionTypes.DASHBOARD_FILTER_START: return updateObject(state, {loading: true});
    case actionTypes.DASHBOARD_FILTER_SUCCESS: return updateObject(state, {...action.payload, loading: false});
    case actionTypes.DASHBOARD_FILTER_ERROR: return updateObject(state, {loading: false});
    default: return state;
  }
};

export default reducer;
