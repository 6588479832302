import React from "react";
import {connect} from 'react-redux';
import * as actions from '../store/actions';
import colors from "../utils/colors";
import classes from '../components/effectiveness-report/EffectivenessReport.module.css';
import contentArrays from "../utils/arrays";
/* Biblioteca de Validação de Campos de Formulários */
import validator from "validator";

import PageTitle from "../components/common/PageTitle";
import EffectivenessReportPieChart from "../components/effectiveness-report/EffectivenessReportPieChart";
import EffectivenessReportSegregationTable from "../components/effectiveness-report/EffectivenessReportSegregationTable";
import EffectivenessReportParameterHeader from "../components/effectiveness-report/EffectivenessReportParameterHeader";
import EffectivenessReportExportingPDF from "../components/effectiveness-report/EffectivenessReportExportingPDF"
import EffectivenessReportExportingSpreadsheet from "../components/effectiveness-report/EffectivenessReportExportingSpreadsheet";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Collapse,
    Container,
    Fade,
    Form, FormCheckbox,
    FormGroup,
    FormInput,
    FormSelect,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Tooltip,
} from "shards-react";

class EffectivenessReport extends React.Component {

    objectModalidades = {};

    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            charged: false,
            tooltip: {
                charge: false,
                keep: false,
                exporting: false,
                explain: false,
            },
            filter: {
                fade: false,
                valid: false,
                keep: false,
                form: {
                    empresa: {
                        name: "empresa",
                        value: -1,
                        options: [],
                        validator: {
                            isIn: [],
                        },
                        valid: false,
                        shouldValidate: true,
                        changed: false,
                    },
                    prestador: {
                        name: "prestador",
                        value: -1,
                        options: [],
                        validator: {
                            isInt: true
                        },
                        valid: false,
                        shouldValidate: true,
                        changed: false,
                    },
                    tipoAplicacao: {
                        name: "tipoAplicacao",
                        value: -1,
                        options: [],
                        validator: {
                            isArrayTipoAplicacao: []
                        },
                        valid: false,
                        shouldValidate: true,
                        changed: false,
                    },
                    modalidade: {
                        name: "modalidade",
                        value: -1,
                        options: [],
                        validator: {
                            isInt: true
                        },
                        valid: false,
                        shouldValidate: true,
                        changed: false,
                    },
                    dataInicial: {
                        name: "dataInicial",
                        value: "",
                        validator: {
                            isDate: false
                        },
                        valid: false,
                        shouldValidate: true,
                        changed: false,
                    },
                    dataFinal: {
                        name: "dataFinal",
                        value: "",
                        validator: {
                            isDate: false
                        },
                        valid: false,
                        shouldValidate: true,
                        changed: false,
                    },
                }
            },
            header: {
                data: {
                    empresa: "-",
                    prestador: "-",
                    tipoAplicacao: "-",
                    dataInicial: new Date().toLocaleString('pt-BR'),
                    dataFinal: new Date().toLocaleString('pt-BR'),
                }
            },
            graph: {
                chartData: {
                    labels: [],
                    datasets: [
                        {
                            hoverBorderColor: colors.white.toRGBA(1),
                            hoverBackgroundColor: [
                                colors.gray_default_1.toRGBA(1),
                                colors.gray_default_1.toRGBA(1),
                                colors.gray_default_1.toRGBA(1)
                            ],
                            data: [33, 33, 33],
                            icons: [
                                '<i class="fas fa-circle"/>',
                                '<i class="fas fa-circle"/>',
                                '<i class="fas fa-circle"/>'
                            ],
                            backgroundColor: [
                                colors.gray_default_1.toRGBA(0.9),
                                colors.gray_default_1.toRGBA(0.8),
                                colors.gray_default_1.toRGBA(0.7)
                            ]
                        }
                    ],
                },
            },
            tables: {
                tableTop: {
                    toggle: false,
                    total: 0,
                    data: [],
                    plus: [],
                    edit: []
                },
                tableMiddle: {
                    toggle: false,
                    total: 0,
                    data: [],
                    plus: [],
                    edit: []
                },
                tableBottom: {
                    toggle: false,
                    total: 0,
                    data: [],
                    plus: [],
                    edit: []
                },
                pilots: [],
                checkeds: [],
                totalLines: 0,
            },
        };

        this.handleWillLoadData = this.handleWillLoadData.bind(this);
        this.handleToggleTooltip = this.handleToggleTooltip.bind(this);
        this.handleToggleExportOptions = this.handleToggleExportOptions.bind(this);
        this.handleToggleTables = this.handleToggleTables.bind(this);
        this.handleLoadData = this.handleLoadData.bind(this);
        this.handleEditOldLine = this.handleEditOldLine.bind(this);
        this.onChangeOldLine = this.onChangeOldLine.bind(this);
        this.handleSaveOldLine = this.handleSaveOldLine.bind(this);
        this.handleAbortOldLine = this.handleAbortOldLine.bind(this);
        this.handleAddNewLine = this.handleAddNewLine.bind(this);
        this.onChangeNewLine = this.onChangeNewLine.bind(this);
        this.handleSaveNewLine = this.handleSaveNewLine.bind(this);
        this.handleAbortNewLine = this.handleAbortNewLine.bind(this);
        this.handleDeleteLine = this.handleDeleteLine.bind(this);
        this.handleCheckLine = this.handleCheckLine.bind(this);
        this.handleMergeLines = this.handleMergeLines.bind(this);
        this.handleToggleKeepCharge = this.handleToggleKeepCharge.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.isCharged = this.isCharged.bind(this);
        this.mountParameterHeader = this.mountParameterHeader.bind(this);
        this.mountPieChart = this.mountPieChart.bind(this);
        this.mountSegregationTables = this.mountSegregationTables.bind(this);
    };

    componentDidMount() {
        this.props.onLoadCompaniesSimpleList();
		this.props.onLoadPrestadorSimpleList();
		this.props.onLoadTipoAplicacaoList();		
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps !== this.props) {

            let newState = {...this.state};

            console.log("this.props.misc:");
            console.log(this.props.misc);

            if (prevProps.misc !== this.props.misc && this.props.misc.empresas && this.props.misc.prestadores && this.props.misc.tipoAplicacoes) {

                if (this.props.misc.empresas.length > 0 && this.props.misc.prestadores.length > 0 && this.props.misc.tipoAplicacoes.length > 0) {

                    newState.filter.form.empresa.options = this.props.misc.empresas.map(function (obj) {
                        newState.filter.form.empresa.validator.isIn.push(obj.id);
                        return {name: obj.nome.toUpperCase(), value: obj.id};
                    });
                    newState.filter.form.empresa.options.unshift({name: "...", value: -1});

                    this.objectModalidades = this.props.misc.tipoAplicacoes.filter(el => Number(el.tipoAplicacaoId) == newState.filter.form.tipoAplicacao.value);

                    // newState.filter.form.prestador.options = this.props.misc.prestadores.map(function (obj) {
                    //     newState.filter.form.prestador.validator.isIn.push(obj.id);
                    //     return {
                    //         name: obj.nome, value: obj.id, pilotos: obj.pilotos.map((obj) => {
                    //             return {name: obj.nome.toUpperCase(), value: obj.id}
                    //         })
                    //     };
                    // });
                    // newState.filter.form.prestador.options.unshift({
                    //     name: "...",
                    //     value: -1,
                    //     pilotos: [
                    //         {name: "...", value: -1}
                    //     ]
                    // });

                    // newState.filter.form.tratamento.options = [];
                    // contentArrays.tratamentos.forEach(item => {
                    //     newState.filter.form.tratamento.options.push(
                    //         {name: item.toUpperCase(), value: item}
                    //     )
                    // });
                    // newState.filter.form.tratamento.validator.isIn = newState.filter.form.tratamento.options.map(function (obj) {
                    //     return obj.value
                    // });
                    // newState.filter.form.tratamento.options.unshift({name: "...", value: -1});

                    newState.ready = true;

                }

            }

            // if (prevProps.misc !== this.props.misc && !this.props.misc.loading && this.props.misc.filteredReports && this.state.ready) {

            //     if (this.props.misc.filteredReports.length > 0) {

            //         let pilotsUnkeepList = [];

            //         this.props.misc.prestadoras.forEach(function (obj) {
            //             pilotsUnkeepList = pilotsUnkeepList.concat(obj.pilotos.map(function (piloto) {
            //                 return {name: piloto.nome, value: piloto.id, prestadora: {name: obj.nome, value: obj.id}};
            //             }));
            //         });
            //         pilotsUnkeepList.unshift({name: "...", value: -1, prestadora: {name: "...", value: -1}});

            //         let pilotsList = [];
            //         let exist = false;

            //         if (newState.tables.pilots.length > 0 && newState.filter.keep
            //             && prevState.filter.form.prestadora.value !== newState.filter.form.prestadora.value) {

            //             exist = false;

            //             pilotsUnkeepList.forEach(function (newPilots) {

            //                 newState.tables.pilots.forEach(function (oldPilots) {

            //                     if (newPilots.value !== oldPilots.value) {
            //                         exist = true;
            //                     }

            //                 });

            //                 if (!exist) {
            //                     pilotsList.push(newPilots);
            //                 }

            //             });

            //             pilotsList = pilotsList.concat(pilotsList, newState.tables.pilots);

            //         } else {
            //             pilotsList = pilotsUnkeepList;
            //         }

            //         let amountUnkeep = this.props.misc.filteredReports.map((obj) => {
            //             return {
            //                 id: obj.uuid === null ? -1 : obj.uuid,
            //                 codigo: obj.codigo === null ? "null" : obj.codigo,
            //                 prestadora: obj.piloto.prestadora.nome === null ? {
            //                     name: "...",
            //                     value: -1
            //                 } : {name: obj.piloto.prestadora.nome, value: obj.piloto.prestadora.id},
            //                 piloto: obj.piloto.nome === null ? {name: "...", value: -1} : {
            //                     name: obj.piloto.nome,
            //                     value: obj.piloto.id
            //                 },
            //                 areaPlantio: obj.areaPlantio === null ? 0 : obj.areaPlantio,
            //                 efetividade: obj.percentAplicacaoEfetiva === null ? 0 : obj.percentAplicacaoEfetiva,
            //                 observacoes: obj.observacoes === null ? "" : obj.observacoes,
            //                 change: false,
            //                 add: false,
            //                 checked: false
            //             };
            //         });
            //         let amountClustered = [];
            //         let amount = [];

            //         exist = false;

            //         if (this.props.misc.filteredReports.length > 0 && newState.filter.keep) {

            //             amountClustered = amountClustered.concat(newState.tables.tableTop.data, newState.tables.tableMiddle.data, newState.tables.tableBottom.data);

            //             exist = false;

            //             amountUnkeep.forEach(function (newReport) {

            //                 newState.tables.tableTop.data.forEach(function (oldReport) {

            //                     if (newReport.id !== oldReport.id) {
            //                         exist = true;
            //                     }

            //                 });

            //                 newState.tables.tableMiddle.data.forEach(function (oldReport) {

            //                     if (newReport.id !== oldReport.id) {
            //                         exist = true;
            //                     }

            //                 });

            //                 newState.tables.tableBottom.data.forEach(function (oldReport) {

            //                     if (newReport.id !== oldReport.id) {
            //                         exist = true;
            //                     }

            //                 });

            //                 if (!exist) {
            //                     amount.push(newReport);
            //                 }

            //             });

            //             amount = amount.concat(amount, amountClustered);

            //         } else {
            //             amount = amountUnkeep;
            //         }

            //         let amountTableTop = amount.filter((obj) => {
            //             return (obj.efetividade >= 90);
            //         });
            //         amountTableTop = amountTableTop.sort((obj1, obj2) => {
            //             return obj2.efetividade - obj1.efetividade;
            //         });

            //         let totalTableTop = 0;
            //         amountTableTop.forEach((obj) => {
            //             totalTableTop += parseFloat(obj.areaPlantio);
            //         });

            //         let amountTableMiddle = amount.filter((obj) => {
            //             return (obj.efetividade < 90 && obj.efetividade >= 85);
            //         });
            //         amountTableMiddle = amountTableMiddle.sort((obj1, obj2) => {
            //             return obj2.efetividade - obj1.efetividade;
            //         });

            //         let totalTableMiddle = 0;
            //         amountTableMiddle.forEach((obj) => {
            //             totalTableMiddle += parseFloat(obj.areaPlantio);
            //         });

            //         let amountTableBottom = amount.filter((obj) => {
            //             return (obj.efetividade < 85);
            //         });
            //         amountTableBottom = amountTableBottom.sort((obj1, obj2) => {
            //             return obj2.efetividade - obj1.efetividade;
            //         });

            //         let totalTableBottom = 0;
            //         amountTableBottom.forEach((obj) => {
            //             totalTableBottom += parseFloat(obj.areaPlantio);
            //         });

            //         newState.charged = true;
            //         newState.header = {
            //             data: {
            //                 empresa: newState.filter.form.empresa.options.filter((obj) => {
            //                     return obj.value === parseInt(newState.filter.form.empresa.value)
            //                 })[0].name,
            //                 prestadora: newState.filter.form.prestadora.options.filter((obj) => {
            //                     return obj.value === parseInt(newState.filter.form.prestadora.value)
            //                 })[0].name,
            //                 tratamento: newState.filter.form.tratamento.options.filter((obj) => {
            //                     return obj.value === newState.filter.form.tratamento.value
            //                 })[0].name,
            //                 dataInicial: newState.filter.form.dataInicial.value,
            //                 dataFinal: newState.filter.form.dataFinal.value,
            //             }
            //         };
            //         newState.graph = {
            //             chartData: {
            //                 labels: ["Alta Efet.", "Média Efet.", "Baixa Efet."],
            //                 datasets: [
            //                     {
            //                         hoverBorderColor: colors.white.toRGBA(1),
            //                         hoverBackgroundColor: [
            //                             colors.green_default_2.toRGBA(0.8),
            //                             colors.yellow_default_2.toRGBA(0.8),
            //                             colors.red_default_2.toRGBA(0.8)
            //                         ],
            //                         data: [amountTableTop.length, amountTableMiddle.length, amountTableBottom.length],
            //                         icons: [
            //                             '<i class="fas fa-circle"/>',
            //                             '<i class="fas fa-circle"/>',
            //                             '<i class="fas fa-circle"/>'
            //                         ],
            //                         backgroundColor: [
            //                             colors.green_default_1.toRGBA(1),
            //                             colors.yellow_default_1.toRGBA(1),
            //                             colors.red_default_1.toRGBA(1)
            //                         ]
            //                     }
            //                 ],
            //             },
            //         };
            //         newState.tables = {
            //             tableTop: {
            //                 toggle: true,
            //                 total: parseFloat(totalTableTop).toFixed(2),
            //                 data: amountTableTop,
            //                 plus: [],
            //                 edit: []
            //             },
            //             tableMiddle: {
            //                 toggle: true,
            //                 total: parseFloat(totalTableMiddle).toFixed(2),
            //                 data: amountTableMiddle,
            //                 plus: [],
            //                 edit: []
            //             },
            //             tableBottom: {
            //                 toggle: true,
            //                 total: parseFloat(totalTableBottom).toFixed(2),
            //                 data: amountTableBottom,
            //                 plus: [],
            //                 edit: []
            //             },
            //             checkeds: [],
            //             pilots: pilotsList,
            //             totalLines: amount.length
            //         };

            //     }

            // }

            this.setState({...this.state, ...newState});
        }

    };

    handleWillLoadData(e) {
        e.preventDefault();

        const newState = {...this.state};

        let formData = new FormData();

        for (let key in newState.filter.form) {
            if (newState.filter.form.hasOwnProperty(key)) {
                formData.append(newState.filter.form[key].name, newState.filter.form[key].value);
            }
        }

        this.props.onFilterReportsList(formData);

    };

    handleToggleTooltip(target) {

        let newState = {...this.state};

        newState.tooltip[target] = !newState.tooltip[target];

        this.setState({...this.state, ...newState});
    };

    handleToggleExportOptions() {
        this.setState({...this.state, filter: {...this.state.filter, fade: true}});
    };

    handleToggleTables(target) {

        let newState = {...this.state};
        newState.tables[target].toggle = !newState.tables[target].toggle;

        this.setState({...this.state, ...newState});
    };

    handleLoadData(e) {
        e.preventDefault();
        e.persist();

        if (this.state.filter.valid) {
            this.handleWillLoadData(e);
        }
    };

    handleEditOldLine(item, reference) {

        let newState = {...this.state};

        newState.tables[reference].data = newState.tables[reference].data.map(obj => {
            if (obj.id === item.id) {
                return Object.assign({}, obj, {
                    change: !item.change
                });
            }
            return obj;
        });

        let edit = {
            valid: true,
            change: false,
            add: true,
            checked: false,
            id: item.id,
            form: {
                codigo: {
                    name: "codigo",
                    value: item.codigo,
                    validator: {
                        isLength: 3
                    },
                    valid: true,
                    shouldValidate: true,
                    changed: false,
                },
                piloto: {
                    name: "piloto",
                    value: this.state.tables.pilots.find((obj) => obj.name === item.piloto.name).value,
                    options: this.state.tables.pilots,
                    validator: {
                        isIn: this.state.tables.pilots.map(function (obj) {
                            return (obj.value);
                        }),
                    },
                    valid: true,
                    shouldValidate: true,
                    changed: true,
                },
                prestadora: {
                    name: "prestadora",
                    value: this.state.filter.form.prestadora.options.find((obj) => obj.name === item.prestadora.name).value,
                    options: this.state.filter.form.prestadora,
                    validator: {
                        isIn: this.state.filter.form.prestadora.options.map(function (obj) {
                            return (obj.value);
                        }),
                    },
                    valid: true,
                    shouldValidate: true,
                    changed: true,
                },
                areaPlantio: {
                    name: "areaPlantio",
                    value: item.areaPlantio,
                    validator: {
                        isFloat: true
                    },
                    valid: true,
                    shouldValidate: true,
                    changed: false,
                },
                efetividade: {
                    name: "efetividade",
                    value: item.efetividade,
                    validator: {
                        isFloat: true
                    },
                    valid: true,
                    shouldValidate: true,
                    changed: false,
                },
                observacoes: {
                    name: "observacoes",
                    value: item.observacoes,
                    validator: {},
                    valid: true,
                    shouldValidate: true,
                    changed: false,
                },
            },
        };

        newState.tables[reference].edit.push(edit);

        this.setState({...this.state, ...newState});
    };

    onChangeOldLine(item, reference, event, type, key) {

        let newState = {...this.state};
        let incommingValue = event.target.value;

        newState.tables[reference].edit[0].form[key].changed = true;
        newState.tables[reference].edit[0].valid = true;

        newState.tables[reference].edit[0].form[key].valid = true;

        if (type === "text") {
            if (newState.tables[reference].edit[0].form[key].validator && newState.tables[reference].edit[0].form[key].shouldValidate) {
                if (newState.tables[reference].edit[0].form[key].validator.hasOwnProperty("isLength")) {
                    newState.tables[reference].edit[0].form[key].valid = validator.isLength(event.target.value, {min: newState.tables[reference].edit[0].form[key].validator.isLength});
                }
            }
        }

        if (type === "select") {

            if (newState.tables[reference].edit[0].form[key].validator && newState.tables[reference].edit[0].form[key].shouldValidate) {
                if (newState.tables[reference].edit[0].form[key].validator.hasOwnProperty("isIn")) {
                    newState.tables[reference].edit[0].form[key].valid = validator.isIn(event.target.value, newState.tables[reference].edit[0].form[key].validator.isIn);
                }
            }

            switch (key) {

                case ("prestadora"): {
                    incommingValue = parseInt(incommingValue);
                    newState.tables[reference].edit[0].form.piloto.value = -1;
                    newState.tables[reference].edit[0].form.piloto.changed = false;
                    newState.tables[reference].edit[0].form.piloto.valid = false;
                    break;
                }

                case ("piloto"): {
                    incommingValue = parseInt(incommingValue);
                    if (incommingValue === -1) {
                        newState.tables[reference].edit[0].form[key].valid = false;
                    }
                    break;
                }

                default: {
                    incommingValue = parseInt(incommingValue);
                    break;
                }

            }

        }

        if (type === "number") {

            if (key === "areaPlantio") {

                if (validator.isFloat(incommingValue)) {

                    incommingValue = parseFloat(incommingValue);

                    if (incommingValue <= 0) {
                        newState.tables[reference].edit[0].form[key].valid = false;
                    }

                } else {
                    newState.tables[reference].edit[0].form[key].valid = false;
                }
            }

            if (key === "efetividade") {

                if (validator.isFloat(incommingValue)) {

                    incommingValue = parseFloat(incommingValue);

                    if (reference === "tableTop" && incommingValue < 90) {
                        newState.tables[reference].edit[0].form[key].valid = false;
                        newState.tables[reference].edit[0].valid = false;
                    }

                    if (reference === "tableMiddle" && (incommingValue >= 90 || incommingValue < 85)) {
                        newState.tables[reference].edit[0].form[key].valid = false;
                        newState.tables[reference].edit[0].valid = false;
                    }

                    if (reference === "tableBottom" && incommingValue >= 85) {
                        newState.tables[reference].edit[0].form[key].valid = false;
                    }

                    if (incommingValue <= 0) {
                        newState.tables[reference].edit[0].form[key].valid = false;
                    }

                } else {
                    newState.tables[reference].edit[0].form[key].valid = false;
                }
            }

        }

        newState.tables[reference].edit[0].form[key].value = incommingValue;

        for (let key in this.state.tables[reference].edit[0].form) {
            if (this.state.tables[reference].edit[0].form.hasOwnProperty(key)) {
                if (!this.state.tables[reference].edit[0].form[key].valid) {
                    newState.tables[reference].edit[0].valid = false;
                    break;
                }
            }
        }

        this.setState({...this.state, ...newState});

    };

    handleSaveOldLine(item, reference) {

        const edit = this.state.tables[reference].edit[0];
        let totalTable = 0;
        let newState = {...this.state};

        newState.tables[reference].data.forEach((obj) => {
            if (obj.id === edit.id) {
                obj.change = false;
                obj.add = false;
                obj.checked = false;
                obj.codigo = edit.form.codigo.value;
                obj.prestadora = {
                    name: this.state.filter.form.prestadora.options.find((obj) => obj.value === edit.form.prestadora.value).name,
                    value: edit.form.prestadora.value
                };
                obj.piloto = {
                    name: this.state.tables.pilots.find((obj) => obj.value === edit.form.piloto.value).name,
                    value: edit.form.piloto.value
                };
                obj.areaPlantio = edit.form.areaPlantio.value;
                obj.efetividade = edit.form.efetividade.value;
                obj.observacoes = edit.form.observacoes.value;
            }
        });
        newState.tables[reference].data = newState.tables[reference].data.sort((obj1, obj2) => {
            return obj2.efetividade - obj1.efetividade;
        });

        newState.tables[reference].data.forEach((obj) => {
            totalTable += parseFloat(obj.areaPlantio);
        });
        newState.tables[reference].total = parseFloat(totalTable).toFixed(2);
        newState.tables[reference].edit = [];
        newState.graph.chartData.datasets[0].data = [
            newState.tables.tableTop.data.length,
            newState.tables.tableMiddle.data.length,
            newState.tables.tableBottom.data.length
        ];

        this.setState({...this.state, ...newState});
    };

    handleAbortOldLine(item, reference) {

        let newState = {...this.state};
        newState.tables[reference].edit = [];
        newState.tables[reference].data = newState.tables[reference].data.map(obj => {
            if (obj.id === item.id) {
                return Object.assign({}, obj, {
                    change: !item.change
                });
            }
            return obj;
        });

        this.setState({...this.state, ...newState});

    };

    handleAddNewLine(reference) {

        let newState = {...this.state};

        let plus = {
            id: ++newState.tables.totalLines,
            valid: false,
            change: false,
            add: true,
            checked: false,
            form: {
                codigo: {
                    name: "codigo",
                    value: "",
                    validator: {
                        isLength: 3
                    },
                    valid: false,
                    shouldValidate: true,
                    changed: false,
                },
                piloto: {
                    name: "piloto",
                    value: -1,
                    options: this.state.tables.pilots,
                    validator: {
                        isIn: this.state.tables.pilots.map(function (obj) {
                            return (obj.value);
                        }),
                    },
                    valid: false,
                    shouldValidate: true,
                    changed: false,
                },
                prestadora: {
                    name: "prestadora",
                    value: -1,
                    options: this.state.filter.form.prestadora,
                    validator: {
                        isIn: this.state.filter.form.prestadora.options.map(function (obj) {
                            return (obj.value);
                        }),
                    },
                    valid: false,
                    shouldValidate: true,
                    changed: false,
                },
                areaPlantio: {
                    name: "areaPlantio",
                    value: 0,
                    validator: {
                        isFloat: true
                    },
                    valid: false,
                    shouldValidate: true,
                    changed: false,
                },
                efetividade: {
                    name: "efetividade",
                    value: 0,
                    validator: {
                        isFloat: true
                    },
                    valid: false,
                    shouldValidate: true,
                    changed: false,
                },
                observacoes: {
                    name: "observacoes",
                    value: "",
                    validator: {},
                    valid: false,
                    shouldValidate: true,
                    changed: false,
                },
            },
        };

        newState.tables[reference].plus.push(plus);

        this.setState({...this.state, ...newState});
    };

    handleSaveNewLine(reference) {

        const plus = this.state.tables[reference].plus[0];
        let totalTable = 0;
        let newState = {...this.state};
        let newData = {};

        newData.id = plus.id;
        newData.change = plus.change;
        newData.add = plus.add;
        newData.checked = plus.checked;
        newData.codigo = plus.form.codigo.value;
        newData.prestadora = {
            name: this.state.filter.form.prestadora.options.find((obj) => obj.value === plus.form.prestadora.value).name,
            value: plus.form.prestadora.value
        };
        newData.piloto = {
            name: this.state.tables.pilots.find((obj) => obj.value === plus.form.piloto.value).name,
            value: plus.form.piloto.value
        };
        newData.areaPlantio = plus.form.areaPlantio.value;
        newData.efetividade = plus.form.efetividade.value;
        newData.observacoes = plus.form.observacoes.value;

        newState.tables[reference].data.push(newData);
        newState.tables[reference].data = newState.tables[reference].data.sort((obj1, obj2) => {
            return obj2.efetividade - obj1.efetividade;
        });

        newState.tables[reference].data.forEach((obj) => {
            totalTable += parseFloat(obj.areaPlantio);
        });
        newState.tables[reference].total = parseFloat(totalTable).toFixed(2);
        newState.tables[reference].plus = [];

        newState.graph.chartData.datasets[0].data = [
            newState.tables.tableTop.data.length,
            newState.tables.tableMiddle.data.length,
            newState.tables.tableBottom.data.length
        ];

        this.setState({...this.state, ...newState});
    };

    handleAbortNewLine(reference) {

        let newState = {...this.state};
        newState.tables[reference].plus = [];
        this.setState({...this.state, ...newState});

    };

    onChangeNewLine(item, reference, event, type, key) {

        let newState = {...this.state};

        let incommingValue = event.target.value;

        newState.tables[reference].plus[0].form[key].changed = true;
        newState.tables[reference].plus[0].valid = true;

        newState.tables[reference].plus[0].form[key].valid = true;

        if (type === "text") {
            if (newState.tables[reference].plus[0].form[key].validator && newState.tables[reference].plus[0].form[key].shouldValidate) {
                if (newState.tables[reference].plus[0].form[key].validator.hasOwnProperty("isLength")) {
                    newState.tables[reference].plus[0].form[key].valid = validator.isLength(event.target.value, {min: newState.tables[reference].plus[0].form[key].validator.isLength});
                }
            }
        }

        if (type === "select") {

            if (newState.tables[reference].plus[0].form[key].validator && newState.tables[reference].plus[0].form[key].shouldValidate) {
                if (newState.tables[reference].plus[0].form[key].validator.hasOwnProperty("isIn")) {
                    newState.tables[reference].plus[0].form[key].valid = validator.isIn(event.target.value, newState.tables[reference].plus[0].form[key].validator.isIn);
                }
            }

            switch (key) {

                case ("prestadora"): {
                    incommingValue = parseInt(incommingValue);
                    if (incommingValue === -1) {
                        newState.tables[reference].plus[0].form[key].valid = false;
                    }
                    newState.tables[reference].plus[0].form.piloto.value = -1;
                    newState.tables[reference].plus[0].form.piloto.changed = false;
                    newState.tables[reference].plus[0].form.piloto.valid = false;
                    break;
                }

                case ("piloto"): {
                    incommingValue = parseInt(incommingValue);
                    if (incommingValue === -1) {
                        newState.tables[reference].plus[0].form[key].valid = false;
                    }
                    break;
                }

                default: {
                    incommingValue = parseInt(incommingValue);
                    break;
                }

            }

        }

        if (type === "number") {

            if (key === "areaPlantio") {

                if (validator.isFloat(incommingValue)) {

                    incommingValue = parseFloat(incommingValue);

                    if (incommingValue <= 0) {
                        newState.tables[reference].plus[0].form[key].valid = false;
                    }

                } else {
                    newState.tables[reference].plus[0].form[key].valid = false;
                }
            }

            if (key === "efetividade") {

                if (validator.isFloat(incommingValue)) {

                    incommingValue = parseFloat(incommingValue);

                    if (reference === "tableTop" && incommingValue < 90) {
                        newState.tables[reference].plus[0].form[key].valid = false;
                        newState.tables[reference].plus[0].valid = false;
                    }

                    if (reference === "tableMiddle" && (incommingValue >= 90 || incommingValue < 85)) {
                        newState.tables[reference].plus[0].form[key].valid = false;
                        newState.tables[reference].plus[0].valid = false;
                    }

                    if (reference === "tableBottom" && incommingValue >= 85) {
                        newState.tables[reference].plus[0].form[key].valid = false;
                    }

                    if (incommingValue <= 0) {
                        newState.tables[reference].plus[0].form[key].valid = false;
                    }

                } else {
                    newState.tables[reference].plus[0].form[key].valid = false;
                }
            }

        }

        newState.tables[reference].plus[0].form[key].value = incommingValue;

        for (let key in this.state.tables[reference].plus[0].form) {
            if (this.state.tables[reference].plus[0].form.hasOwnProperty(key)) {
                if (!this.state.tables[reference].plus[0].form[key].valid) {
                    newState.tables[reference].plus[0].valid = false;
                    break;
                }
            }
        }

        this.setState({...this.state, ...newState});

    };

    handleDeleteLine(item, reference) {

        let totalTable = 0;
        let newState = {...this.state};

        newState.tables[reference].data = newState.tables[reference].data.filter((obj) => {
            return (obj.id !== item.id);
        });
        newState.tables[reference].data = newState.tables[reference].data.sort((obj1, obj2) => {
            return obj2.efetividade - obj1.efetividade;
        });
        // eslint-disable-next-line array-callback-return
        newState.tables[reference].data.map((obj) => {
            totalTable += parseFloat(obj.areaPlantio);
        });
        newState.tables[reference].total = parseFloat(totalTable).toFixed(2);

        newState.graph.chartData.datasets[0].data = [
            newState.tables.tableTop.data.length,
            newState.tables.tableMiddle.data.length,
            newState.tables.tableBottom.data.length
        ];

        this.setState({...this.state, ...newState});

    };

    handleCheckLine(item, reference) {

        let newState = {...this.state};

        // eslint-disable-next-line array-callback-return
        newState.tables[reference].data.map((obj1) => {
            if (obj1.id === item.id) {
                obj1.checked = !obj1.checked;

                if (obj1.checked) {
                    newState.tables.checkeds.push(item);
                } else {
                    newState.tables.checkeds = newState.tables.checkeds.filter((obj2) => {
                        return (obj1.id !== obj2.id)
                    })
                }

            }
        });

        this.setState({...this.state, ...newState});
    };

    handleMergeLines(list, reference) {

        let newState = {...this.state};
        let totalTableTop = 0;
        let totalTableMiddle = 0;
        let totalTableBottom = 0;

        let newData = {
            id: ++newState.tables.totalLines,
            change: false,
            add: false,
            checked: false,
            codigo: "",
            piloto: "",
            prestadora: "",
            areaPlantio: 0,
            efetividade: 0,
            observacoes: ""
        };

        // eslint-disable-next-line array-callback-return
        list.forEach((row, index) => {

            if (index === 0) {
                newData.codigo = row.codigo;
            } else {
                newData.codigo = newData.codigo + " / " + row.codigo;
            }

            newData.piloto = row.piloto;
            newData.prestadora = row.prestadora;
            newData.areaPlantio = (parseFloat(newData.areaPlantio) + parseFloat(row.areaPlantio)).toFixed(2);
            newData.efetividade = (parseFloat(newData.efetividade) + parseFloat(row.efetividade)).toFixed(2);

            if (index === 0) {
                newData.observacoes = row.observacoes;
            } else {

                if (newData.observacoes === "" && row.observacoes === "") {
                    newData.observacoes = "";
                } else {
                    if (newData.observacoes === "" && row.observacoes !== "") {
                        newData.observacoes = row.observacoes;
                    } else {
                        if (newData.observacoes !== "" && row.observacoes !== "") {
                            newData.observacoes = newData.observacoes + "\n" + row.observacoes;
                        }
                    }
                }
            }

            newState.tables.tableTop.data = newState.tables.tableTop.data.filter((other) => {
                return (row.id !== other.id);
            });
            newState.tables.tableMiddle.data = newState.tables.tableMiddle.data.filter((other) => {
                return (row.id !== other.id);
            });
            newState.tables.tableBottom.data = newState.tables.tableBottom.data.filter((other) => {
                return (row.id !== other.id);
            });

        });

        newData.efetividade = (parseFloat(newData.efetividade) / list.length).toFixed(2);

        if (newData.efetividade >= 90) {
            reference = "tableTop";
        } else {
            if (newData.efetividade >= 85 && newData.efetividade < 90) {
                reference = "tableMiddle";
            } else {
                if (newData.efetividade < 85) {
                    reference = "tableBottom";
                }

            }
        }

        newState.tables[reference].data.push(newData);
        newState.tables[reference].data = newState.tables[reference].data.sort((row1, row2) => {
            return row1.efetividade - row2.efetividade;
        });

        newState.tables.tableTop.data.forEach((obj) => {
            totalTableTop += parseFloat(obj.areaPlantio);
        });
        newState.tables.tableTop.total = parseFloat(totalTableTop).toFixed(2);

        newState.tables.tableMiddle.data.forEach((obj) => {
            totalTableMiddle += parseFloat(obj.areaPlantio);
        });
        newState.tables.tableMiddle.total = parseFloat(totalTableMiddle).toFixed(2);

        newState.tables.tableBottom.data.forEach((obj) => {
            totalTableBottom += parseFloat(obj.areaPlantio);
        });
        newState.tables.tableBottom.total = parseFloat(totalTableBottom).toFixed(2);

        newState.graph.chartData.datasets[0].data = [
            newState.tables.tableTop.data.length,
            newState.tables.tableMiddle.data.length,
            newState.tables.tableBottom.data.length
        ];

        newState.tables.checkeds = [];

        this.setState({...this.state, ...newState});

    };

    handleToggleKeepCharge() {
        this.setState({...this.state, filter: {...this.state.filter, keep: !this.state.filter.keep}});
    };

    onChangeFilter(event, type, key) {

        console.log("EffectivenessReport [onChangeFilter]");

        let newState = {...this.state};

        newState.filter.form[key].value = event.target.value;
        newState.filter.form[key].valid = true;
        newState.filter.form[key].changed = true;

        newState.filter.valid = true;

        if (type === "date") {

            if (newState.filter.form[key].validator.hasOwnProperty("isDate")) {

                newState.filter.form.dataInicial.valid = newState.filter.form.dataInicial.value !== "";
                newState.filter.form.dataFinal.valid = newState.filter.form.dataFinal.value !== "";

                if (newState.filter.form.dataInicial.valid && newState.filter.form.dataFinal.valid) {

                    let dataInicial = new Date(newState.filter.form.dataInicial.value + " 00:00:01");
                    let dataFinal = new Date(newState.filter.form.dataFinal.value + " 00:00:01");

                    if (dataInicial.getTime() > dataFinal.getTime()) {

                        newState.filter.form.dataInicial.valid = false;
                        newState.filter.form.dataFinal.valid = false;

                    }

                }

            }
        }

        if (type === "select") {

            if (newState.filter.form[key].validator && newState.filter.form[key].shouldValidate) {

                if (newState.filter.form[key].validator.hasOwnProperty("isIn")) {

                    newState.filter.form[key].valid = validator.isIn(event.target.value, newState.filter.form[key].validator.isIn);

                }
            }
        }

        for (let key in newState.filter.form) {
            if (newState.filter.form.hasOwnProperty(key)) {
                if (newState.filter.form[key].shouldValidate && !newState.filter.form[key].valid) {
                    newState.filter.valid = false;
                    break;
                }
            }
        }

        this.setState({...this.state, ...newState});
    };

    onMouseInExportOptions() {
        if (this.isCharged()) {

            let newState = {...this.state}

            newState.filter.fade = true;
            newState.tooltip.exporting = false;

            this.setState({...this.state, ...newState});

        } else {
            this.handleToggleTooltip("exporting");
        }
    };

    onMouseOutExportOptions() {
        if (this.isCharged()) {

            let newState = {...this.state}

            newState.filter.fade = false;
            newState.tooltip.exporting = false;

            this.setState({...this.state, ...newState});

        } else {
            this.handleToggleTooltip("exporting");
        }
    };

    mountParameterHeader() {

        return (
            <EffectivenessReportParameterHeader data={this.state.header.data}/>
        );

    };

    mountPieChart() {

        return (
            <Container id={"re_graphP_cont"} fluid>
                <Row className={"d-flex justify-content-center"}>
                    <Col md={"5"} className={""}>
                        <EffectivenessReportPieChart chartData={this.state.graph.chartData}
                                                     id={"re_graphP_comp"}/>
                    </Col>
                </Row>
            </Container>
        );

    };

    mountSegregationTables() {

        return (
            <Container id={"re_table_cont"} fluid>
                {/*Start - Tabela de Alta Efetvidade do Relatório*/}
                <Row className={"mb-4"}>
                    <EffectivenessReportSegregationTable
                        toggle={this.state.tables.tableTop.toggle}
                        title={"≥ 90%: ALTA EFETIVIDADE"}
                        headerColor={colors.green_default_1.toRGBA(1)}
                        total={parseFloat(this.state.tables.tableTop.total)}
                        data={this.state.tables.tableTop.data}
                        plus={this.state.tables.tableTop.plus}
                        edit={this.state.tables.tableTop.edit}
                        pilotos={this.state.tables.pilots}
                        prestadoras={this.state.filter.form.prestadora.options}
                        checkeds={this.state.tables.checkeds}
                        reference={"tableTop"}
                        handleToggleTables={() => this.handleToggleTables("tableTop")}
                        handleEditOldLine={this.handleEditOldLine}
                        onChangeOldLine={this.onChangeOldLine}
                        handleSaveOldLine={this.handleSaveOldLine}
                        handleAbortOldLine={this.handleAbortOldLine}
                        handleAddNewLine={this.handleAddNewLine}
                        onChangeNewLine={this.onChangeNewLine}
                        handleSaveNewLine={this.handleSaveNewLine}
                        handleAbortNewLine={this.handleAbortNewLine}
                        handleDeleteLine={this.handleDeleteLine}
                        handleMergeLines={this.handleMergeLines}
                        onCheckLine={this.handleCheckLine}
                    />
                </Row>
                {/*End -  Tabela de Alta Efetvidade do Relatório*/}

                {/*Start -  Tabela de Média Efetvidade do Relatório*/}
                <Row className={"mb-4"}>
                    <EffectivenessReportSegregationTable
                        toggle={this.state.tables.tableMiddle.toggle}
                        title={"85% ≥ < 90%: MÉDIA EFETIVIDADE"}
                        headerColor={colors.yellow_default_1.toRGBA(1)}
                        total={parseFloat(this.state.tables.tableMiddle.total)}
                        data={this.state.tables.tableMiddle.data}
                        plus={this.state.tables.tableMiddle.plus}
                        edit={this.state.tables.tableMiddle.edit}
                        pilotos={this.state.tables.pilots}
                        prestadoras={this.state.filter.form.prestadora.options}
                        checkeds={this.state.tables.checkeds}
                        reference={"tableMiddle"}
                        handleToggleTables={() => this.handleToggleTables("tableMiddle")}
                        handleEditOldLine={this.handleEditOldLine}
                        onChangeOldLine={this.onChangeOldLine}
                        handleSaveOldLine={this.handleSaveOldLine}
                        handleAbortOldLine={this.handleAbortOldLine}
                        handleAddNewLine={this.handleAddNewLine}
                        onChangeNewLine={this.onChangeNewLine}
                        handleSaveNewLine={this.handleSaveNewLine}
                        handleAbortNewLine={this.handleAbortNewLine}
                        handleDeleteLine={this.handleDeleteLine}
                        onCheckLine={this.handleCheckLine}
                        handleMergeLines={this.handleMergeLines}
                    />
                </Row>
                {/*End -  Tabela de Média Efetvidade do Relatório*/}

                {/*Start -  Tabela de Baixa Efetvidade do Relatório*/}
                <Row className={"mb-4"}>
                    <EffectivenessReportSegregationTable
                        toggle={this.state.tables.tableBottom.toggle}
                        title={"< 85%: BAIXA EFETIVIDADE"}
                        headerColor={colors.red_default_1.toRGBA(1)}
                        total={parseFloat(this.state.tables.tableBottom.total)}
                        data={this.state.tables.tableBottom.data}
                        plus={this.state.tables.tableBottom.plus}
                        edit={this.state.tables.tableBottom.edit}
                        pilotos={this.state.tables.pilots}
                        prestadoras={this.state.filter.form.prestadora.options}
                        checkeds={this.state.tables.checkeds}
                        reference={"tableBottom"}
                        handleToggleTables={() => this.handleToggleTables("tableBottom")}
                        handleEditOldLine={this.handleEditOldLine}
                        onChangeOldLine={this.onChangeOldLine}
                        handleSaveOldLine={this.handleSaveOldLine}
                        handleAbortOldLine={this.handleAbortOldLine}
                        handleAddNewLine={this.handleAddNewLine}
                        onChangeNewLine={this.onChangeNewLine}
                        handleSaveNewLine={this.handleSaveNewLine}
                        handleAbortNewLine={this.handleAbortNewLine}
                        handleDeleteLine={this.handleDeleteLine}
                        onCheckLine={this.handleCheckLine}
                        handleMergeLines={this.handleMergeLines}
                    />
                </Row>
                {/*End -  Tabela de Baixa Efetvidade do Relatório*/}
            </Container>
        );

    };

    /* Função que indica se existem informações nas Tabelas de Eficiência */
    isCharged() {
        return (this.state.charged && (this.state.tables.tableTop.data.length > 0 || this.state.tables.tableMiddle.data.length > 0 || this.state.tables.tableBottom.data.length > 0));
    };

    render() {
		if (this.props.misc.tipoAplicacoes && this.state.filter.form.tipoAplicacao) {
            if (this.state.filter.form.tipoAplicacao.valid) {
              this.objectModalidades = this.props.misc.tipoAplicacoes.filter(el => Number(el.tipoAplicacaoId) == this.state.form.tipoAplicacao.value);
            }
        }

        return (
            <Container fluid className="main-content-container px-4 pb-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle
                        title={"Relatório de Efetividade"}
                        subtitle={"Aplicações"} explain={true}
                        explainTextBold={"Você precisa criar um Relatório orientado à Efetividade das Aplicações?"}
                        explainText={"Basta utilizar do filtro para carregar aplicações, direto da Base de Dados, e editar o seu relatório até exportá-lo como arquivo."}
                        explainTooltip={this.state.tooltip.explain}
                        handleToggleTooltip={() => this.handleToggleTooltip("explain")}
                        className="text-sm-left mb-3"/>
                </Row>

                {/*Start - Relatório de Efetividade*/}
                <Card>
                    <CardHeader className={"border-bottom"}>
                        {/*Start - Formulário de Filtragem*/}
                        <Container id={"er_filter_cont"} fluid>
                            <Form style={{
                                color: colors.reagentGray.toRGBA(),
                                backgroundColor: 'transparent'
                            }}>
                                <Row>
                                    <Col lg={"2"} md={"12"} sm={"12"}
                                         className={[classes.er_filter_col_input].join("")}>
                                        <FormGroup>
                                            <label htmlFor="#er_filter_form_empresa">Regiões</label>
                                            <FormSelect id={"er_filter_form_empresa"}
                                                        style={{color: colors.reagentGray.toRGBA()}}
                                                        onChange={(event) => this.onChangeFilter(event, "select", "empresa")}
                                                        invalid={!this.state.filter.form.empresa.valid && this.state.filter.form.empresa.shouldValidate && this.state.filter.form.empresa.changed}
                                                        valid={this.state.filter.form.empresa.valid && this.state.filter.form.empresa.shouldValidate && this.state.filter.form.empresa.changed}>
                                                        {this.state.filter.form.empresa.options.map((obj, index) => {
                                                            return (<option key={index} id={obj.value}
                                                                            value={obj.value}>{obj.name.toUpperCase()}</option>);
                                                        })}
                                            </FormSelect>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={"2"} md={"6"} sm={"6"} className={[classes.er_filter_col_input].join("")}>
                                        <FormGroup>
                                            <label htmlFor="#er_filter_form_prestador">Prestador</label>
                                            <FormSelect id={"er_filter_form_prestador"}
                                                        style={{color: colors.reagentGray.toRGBA()}}
                                                        onChange={(event) => this.onChangeFilter(event, "select", "prestador")}
                                                        invalid={!this.state.filter.form.prestador.valid && this.state.filter.form.prestador.shouldValidate && this.state.filter.form.prestador.changed}
                                                        valid={this.state.filter.form.prestador.valid && this.state.filter.form.prestador.shouldValidate && this.state.filter.form.prestador.changed}>
                                                        <option>...</option>
                                                        {this.props.misc.prestadores ? 
														this.props.misc.prestadores.map(element => { 
                                                            return <option value={element.prestadorId} key={element.prestadorId}>{element.nome}</option>})
														: null }
                                            </FormSelect>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={"2"} md={"6"} sm={"6"} className={[classes.er_filter_col_input].join("")}>
                                        <FormGroup>
                                            <label htmlFor="#er_filter_form_tipoAplicacao">Tipo Aplicação</label>
                                            <FormSelect id={"er_filter_form_tipoAplicacao"}
                                                        style={{color: colors.reagentGray.toRGBA()}}
                                                        onChange={(event) => this.onChangeFilter((event), "select", "tipoAplicacao")}
                                                        invalid={!this.state.filter.form.tipoAplicacao.valid && this.state.filter.form.tipoAplicacao.shouldValidate && this.state.filter.form.tipoAplicacao.changed}
                                                        valid={this.state.filter.form.tipoAplicacao.valid && this.state.filter.form.tipoAplicacao.shouldValidate && this.state.filter.form.tipoAplicacao.changed}>
                                                        <option>...</option>
														{this.props.misc.tipoAplicacoes ? 
														this.props.misc.tipoAplicacoes.map(element => { return <option value={element.tipoAplicacaoId} key={element.tipoAplicacaoId}>{element.descricao}</option>})
														: null }
                                            </FormSelect>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={"2"} md={"6"} sm={"6"} className={[classes.er_filter_col_input].join("")}>
                                        <FormGroup>
                                            <label htmlFor="#er_filter_form_modalidade">Modalidade</label>
                                            <FormSelect id={"er_filter_form_modalidade"}
                                                        style={{color: colors.reagentGray.toRGBA()}}
                                                        onChange={(event) => this.onChangeFilter((event), "select", "modalidade")}
                                                        invalid={!this.state.filter.form.modalidade.valid && this.state.filter.form.modalidade.shouldValidate && this.state.filter.form.modalidade.changed}
                                                        valid={this.state.filter.form.modalidade.valid && this.state.filter.form.modalidade.shouldValidate && this.state.filter.form.modalidade.changed}>
                                                        <option>...</option>
														{this.objectModalidades ? this.objectModalidades[0] ?
														this.objectModalidades[0].modalidades.map(element => { return <option value={element.modalidadeId} key={element.modalidadeId}>{element.descricao}</option>})
														: null : null }
                                            </FormSelect>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={"3"} md={"12"} sm={"12"}
                                         className={[classes.er_filter_col_input_period].join("")}>
                                        <FormGroup className={"w-100"}>
                                            <label htmlFor="er_filter_form_periodo">Período</label>
                                            <InputGroup id={"er_filter_form_periodo"}>
                                                <FormInput id={"er_filter_form_dataInicial"} type={"date"}
                                                           className={[classes.er_filter_form_dataInicial].join("")}
                                                           style={{
                                                               color: colors.reagentGray.toRGBA(),
                                                           }}
                                                           onChange={(event) => this.onChangeFilter((event), "date", "dataInicial")}
                                                           invalid={!this.state.filter.form.dataInicial.valid && this.state.filter.form.dataInicial.shouldValidate && this.state.filter.form.dataInicial.changed}
                                                           valid={this.state.filter.form.dataInicial.valid && this.state.filter.form.dataInicial.shouldValidate && this.state.filter.form.dataInicial.changed}/>
                                                <FormInput id={"er_filter_form_dataFinal"} type={"date"}
                                                           className={[classes.er_filter_form_dataFinal].join("")}
                                                           style={{
                                                               color: colors.reagentGray.toRGBA(),
                                                               minWidth: "144px"
                                                           }}
                                                           onChange={(event) => this.onChangeFilter((event), "date", "dataFinal")}
                                                           invalid={!this.state.filter.form.dataFinal.valid && this.state.filter.form.dataFinal.shouldValidate && this.state.filter.form.dataFinal.changed}
                                                           valid={this.state.filter.form.dataFinal.valid && this.state.filter.form.dataFinal.shouldValidate && this.state.filter.form.dataFinal.changed}/>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={"3"} md={"12"} sm={"12"}
                                         className={[classes.er_filter_col_buttons].join("")}>
                                        <div className={[classes.er_filter_col_button_charge].join("")}>
                                            <InputGroup>
                                                <Tooltip
                                                    open={this.state.tooltip.charge}
                                                    target="#er_filter_btn_charge"
                                                    placement="top"
                                                    toggle={() => {
                                                        this.handleToggleTooltip("charge")
                                                    }}
                                                    disabled={this.state.filter.valid}>
                                                    <span role="img" aria-label="Face with Hand Over Mouth">
                                                        Por favor, preencha corretamento os campos, antes de realizar a filtragem! 🤭
                                                    </span>
                                                </Tooltip>
                                                <Button id={"er_filter_btn_charge"} type="submit" theme="success"
                                                        className={[classes.er_filter_charge_button].join("")}
                                                        onClick={(e) => this.handleLoadData(e)}
                                                        disabled={!this.state.filter.valid}>
                                                    Carregar
                                                </Button>
                                                <Tooltip
                                                    open={this.state.tooltip.keep}
                                                    target="#er_filter_btn_check_keep"
                                                    placement="top"
                                                    toggle={() => {
                                                        this.handleToggleTooltip("keep")
                                                    }}>
                                                    <span role="img" aria-label="Hugging Face">
                                                        Preserve os dados atuais, e faça novas filtragens! 🤗
                                                    </span>
                                                </Tooltip>
                                                <InputGroupAddon id={"er_filter_btn_check_keep"}
                                                                 type={"append"}
                                                                 className={[classes.er_filter_charge_keep].join("")}>
                                                    <InputGroupText className={"border-success"}>
                                                        <FormCheckbox
                                                            toggle
                                                            small
                                                            checked={this.state.filter.keep}
                                                            onChange={() => this.handleToggleKeepCharge()}/>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                        <div className={"position-relative"}
                                             onMouseEnter={() => this.onMouseInExportOptions()}
                                             onMouseLeave={() => this.onMouseOutExportOptions()}>
                                            <Tooltip
                                                open={this.state.tooltip.exporting}
                                                target="#er_filter_btn_export"
                                                toggle={() => {
                                                    this.handleToggleTooltip("exporting")
                                                }}
                                                disabled={this.isCharged()}>
                                                <span role="img" aria-label="Thinking Face">
                                                    Não é possível exportar, pela ausência de dados! 🤔
                                                </span>
                                            </Tooltip>
                                            <Button id={"er_filter_btn_export"} outline theme="light"
                                                    type={"button"} style={{width: "65px"}}
                                                    disabled={!this.isCharged()}
                                                    onClick={() => this.handleToggleExportOptions()}>
                                                <i className="fas fa-download" style={{color: "rgb(129, 142, 163)"}}/>
                                            </Button>
                                            <Fade in={this.state.filter.fade}>
                                                <div
                                                    style={this.isCharged() ? {display: "flex"} : {display: "none"}}
                                                    className={"position-absolute pt-2 transparent align-items-center justify-content-center flex-column"}>

                                                    {/* <EffectivenessReportExportingSpreadsheet
                                                        name={"effectiveness_report_spreadsheet"}
                                                        title={"Efficiency Report - " + new Date().toLocaleString('pt-BR')}
                                                        header={this.state.header}
                                                        tableTop={this.state.tables.tableTop}
                                                        tableMiddle={this.state.tables.tableMiddle}
                                                        tableBottom={this.state.tables.tableBottom}
                                                        style={{
                                                            display: "none",
                                                            transition: "all 2s"
                                                        }}/>

                                                    <EffectivenessReportExportingPDF
                                                        name={"effectiveness_report_pdf"}
                                                        graph={"re_graphP_comp"}
                                                        header={this.state.header}
                                                        tableTop={this.state.tables.tableTop}
                                                        tableMiddle={this.state.tables.tableMiddle}
                                                        tableBottom={this.state.tables.tableBottom}
                                                    /> */}
                                                </div>
                                            </Fade>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                        {/*End - Formulário de Filtragem*/}
                    </CardHeader>

                    <CardBody id={"re_report_body"}>
                        <Collapse open={this.state.charged}>
                            {/*Start - Cabeçalho do Relatório*/}
                            {this.state.charged ? this.mountParameterHeader() : null}
                            {/*End - Cabeçalho do Relatório*/}

                            {/*Start - Gráfico de Pizza do Relatório*/}
                            {this.state.charged ? this.mountPieChart() : null}
                            {/*End - Gráfico de Pizza do Relatório*/}

                            {/*Start - Tabelas de Efetividade*/}
                            {this.state.charged ? this.mountSegregationTables() : null}
                            {/*End - Tabelas de Efetividade*/}
                        </Collapse>
                    </CardBody>

                    <CardFooter/>
                </Card>
                {/*End - Relatório de Efetividade*/}

            </Container>
        );
    };

}

const mapStateToProps = state => {
    return {
        misc: state.misc,
        userInfo: state.auth.userInfo
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onLoadCompaniesSimpleList: () => dispatch(actions.requestCompaniesSimpleList()),
        onLoadPrestadorSimpleList: () => dispatch(actions.requestPrestadorSimpleList()),
		onLoadTipoAplicacaoList: () => dispatch(actions.requestTipoAplicacaoList()),
        onFilterReportsList: (filter) => dispatch(actions.fetchFilteredReportsList(filter)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EffectivenessReport);