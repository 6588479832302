import React from 'react'

import {Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row} from "shards-react";

import classes from './FloatingData.module.css'

const floatingData = props => {

  let dataAplicacao = new Date(props.laudoData.data);
  dataAplicacao.setHours(dataAplicacao.getHours() + 5);
  let dia = (dataAplicacao.getDate()).toString().padStart(2, '0'),
    mes = (dataAplicacao.getMonth() + 1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
    ano = dataAplicacao.getFullYear();

  console.log(dataAplicacao)
  const stringDataAplicacao = dia + '/' + mes + '/' + ano;

  const listaObservacoesLaudo = props.laudoData.observacoesArray.map(el => (
    el.chave
  ));
  const htmlObservacoes = props.observacoesModel.filter(el => listaObservacoesLaudo.includes(el.key)).map((el, index) => (
    <li key={el.key}>{el.name.toUpperCase()}</li>
  ));

  if (props.laudoData.observacoes !== null && props.laudoData.observacoes !== '') {
    htmlObservacoes.push(<li key={'obs'}>{props.laudoData.observacoes.toUpperCase()}</li>)
  }


  return (<Col md="3" id="floatingDataCard">
    <Card className={[classes.Data, classes.DataTransparent, classes.Modal].join(" ")}>
      <CardHeader className={["pb-1", classes.DataTransparent, classes.GreenBorderTop].join(" ")}>
        <Row>
          <Col md="10">
            <h5 className={classes.DataCompany}>{props.laudoData.empresa.nome}</h5>
            <h5 className={classes.DataReport}>{props.laudoData.codigo}</h5>
          </Col>
          {/*<Col md="2"><span className={classes.CloseButton}>x</span></Col>*/}
        </Row>

      </CardHeader>
      <CardBody className={[classes.DataTransparent, "pt-3 pb-0"].join(" ")}>
        <hr className="m-1 mb-4 p-0 mt-n2"/>

        {props.laudoData.nome !== 'VAZIO' ?
          <p className="text-nowrap">Propriedade:<span id="propriedade">{props.laudoData.nome}</span></p> : null}

        <p className="text-nowrap">Prestadora:
          <span id="prestadora">{props.laudoData.piloto.prestadora.nome}</span></p>

        <p className="text-nowrap">Piloto:<span id="piloto">{props.laudoData.piloto.nome}</span></p>

        <p className="text-nowrap">Data:<span id="data">{stringDataAplicacao}</span></p>

        <p className="text-nowrap">Tratamento:<span id="modalidade">{props.laudoData.tratamento}</span></p>

        <p className="text-nowrap">Dist. da Pista (km):<span
          id="distancia">{props.laudoData.distanciaPista.toFixed(2)}</span></p>

        <p className="text-nowrap">Largura da Faixa (m):<span
          id="faixa">{props.laudoData.faixaExecutada.toFixed(2)}</span> <span
          className="font-weight-light">({props.laudoData.percentLarguraFaixa.toFixed(2)}%)</span></p>

        <p className="text-nowrap">Taxa de Aplicação (l):<span
          id="vazao">{props.laudoData.taxaAplicacao.toFixed(2)}</span> <span
          className="font-weight-light">({props.laudoData.percentTaxaAplicacao}%)</span></p>

        <p className="text-nowrap">Insumos <span className="font-weight-bold">(ha)</span>:<span
          id="insumos">{props.laudoData.insumos.toFixed(2)}</span> <span
          className="font-weight-light">({props.laudoData.percentInsumos}%)</span>
        </p>

        <p className="text-nowrap">Área de Plantio <span className="font-weight-bold">(ha)</span>:<span
          id="area-plantio">{props.laudoData.areaPlantio.toFixed(2)}</span></p>

        <p className="text-nowrap">Área Aplicada <span className="font-weight-bold">(ha)</span>:<span
          id="area-aplicada">{props.laudoData.areaAplicada.toFixed(2)}</span> <span
          className="font-weight-light">({props.laudoData.percentAreaAplicada}%)</span>
        </p>

        <p className="text-nowrap">Aplic. Efetiva <span className="font-weight-bold">(ha)</span>:<span
          id="efetiva">{props.laudoData.aplicacaoEfetiva.toFixed(2)}</span> <span
          className="font-weight-light">({props.laudoData.percentAplicacaoEfetiva}%)</span>
        </p>

        <p className="text-nowrap">Aplic. Externa <span className="font-weight-bold">(ha)</span>:<span
          id="externa">{props.laudoData.aplicacaoExterna.toFixed(2)}</span> <span
          className="font-weight-light">({props.laudoData.percentAplicacaoExterna}%)</span>
        </p>

        <p className="text-nowrap">Área Não Aplicada <span className="font-weight-bold">(ha)</span>:<span
          id="nao-aplicada">{props.laudoData.areaNaoAplicada.toFixed(2)}</span> <span
          className="font-weight-light">({props.laudoData.percentAreaNaoAplicada}%)</span>
        </p>

        <p className="text-nowrap">Sobreposição <span className="font-weight-bold">(ha)</span>:<span
          id="sobreposicao">{props.laudoData.areaSobreposicao.toFixed(2)}</span> <span
          className="font-weight-light">({props.laudoData.percentAreaSobreposicao}%)</span>
        </p>
      </CardBody>
      <CardFooter className={classes.DataTransparent + ' pt-1 ' + classes.GreenBorderBottom}>
        {htmlObservacoes.length > 0 ? <div>
          <hr/>
          <p className="font-weight-bold">Observações:</p>
          {props.laudoData ? <ul style={{fontSize: '15px'}}>{htmlObservacoes}</ul> : null}</div> : null}
      </CardFooter>
    </Card>
  </Col>)
};

export default floatingData;
