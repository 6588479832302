import React from "react";
import {Container} from "shards-react";
import validator from 'validator/lib/isUUID';
import EfficiencyMap from '../components/map/CustomMap/EfficiencyMap/EfficiencyMap';
import AmbientalMap from "../components/map/CustomMap/AmbientalMap/AmbientalMap";

const style = {
  padding: 0
};
const Map = (props) => {
  let content = null;
  const location = props.location.pathname.split('/').length > 2 ? props.location.pathname.split('/')[2] : "this is not a value";

  if (location === 'ambiental') {
    content = <AmbientalMap/>
    // content = null
  } else if (!validator(location)) {
    props.history.goBack();
  } else {
    content = <EfficiencyMap uuid={location}/>
  }

  return (
    <Container fluid className="main-content-container" style={style}>
      <div id={"map"}>
        {content}
      </div>
    </Container>)

};

export default Map;
