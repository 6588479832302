import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";

class tableToPDF extends React.Component {

  constructor(props) {
    super(props);
  }

  exportPDF = (columns, lines) => {

    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const datas = {};
    if (this.props.data.length > 0) {
      datas.inicial = new Date(this.props.data[0].data).toLocaleDateString('pt-BR');
      datas.final = new Date(this.props.data[this.props.data.length - 1].data).toLocaleDateString('pt-BR')
    }

    const title = "[SPRAY PLAN] " + this.props.empresa + " - CONTROLE DE LAUDOS - " + datas.inicial + " - " + datas.final;
    const line = this.props.data.map(post => {
      const row = [];
      this.props.columns.forEach((el, index) => {
        let obj;
        if (el.key === 'piloto') {
          obj = post[el.key].nome
        } else if (el.key === 'empresa') {
          obj = post[el.key].nome
        } else if (el.key === 'prestadora') {
          obj = post['piloto'].prestadora.nome
        } else if (el.key === 'data') {
          obj = new Date(post[el.key]).toLocaleDateString('pt-BR')
        } else if (el.key === 'nome') {
          if (post[el.key] === 'VAZIO') {
            obj = 'N/I'
          } else {
            obj = post[el.key]
          }
        } else {
          obj = post[el.key]
        }
        row.push(obj);
      });


      return (row);
    });

    let content = {
      startY: 50,
      head: [this.props.columns.map(el => el.name)],
      body: line,
      styles: {theme: 'grid'}
    };

    doc.text(title, marginLeft, 40);
    doc.text("DATA DE CONSULTA: " + new Date().toLocaleString('pt-BR'), marginLeft, 570);


    doc.autoTable(content);


    doc.save("report.pdf");
  };

  render() {
    return (
      <div className={"p-3"}>
        <button id={this.props.idButton} className={this.props.classButton} style={{fontSize: "1rem"}}
                onClick={this.exportPDF}>
          {this.props.buttonText}
        </button>
      </div>
    );
  }
}

export default tableToPDF;
