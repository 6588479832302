import React from "react";
import ReactExport from "react-data-export";
import {Button} from "shards-react";
import PropTypes from "prop-types";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class EffectivenessReportExportingSpreadsheet extends React.Component {

    componentDidMount() {
        console.log("EffectivenessReportExportingSpreadsheet [componentDidMount]");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            console.log("EffectivenessReportExportingSpreadsheet [componentDidUpdate]");
        }
    }

    convertDate(date) {

        let newDate = new Date(date + " 00:00:01");
        return newDate.toLocaleDateString("pt-BR");

    }

    gradient(value) {

        if (value > 96.666) {
            return "27AB73";
        }

        if (value <= 96.666 && value > 93.333) {
            return "00814C";
        }

        if (value < 93.333 && value >= 90) {
            return "005929";
        }

        if (value >= 88.33 && value < 90) {
            return "CC9D18";
        }

        if (value < 88.33 && value >= 86.66) {
            return "9A7200";
        }

        if (value < 86.66 && value >= 85) {
            return "6B4900";
        }

        if (value >= 81.667 && value < 85) {
            return "B33D5A";
        }

        if (value < 81.667 && value >= 78.334) {
            return "931D41";
        }

        if (value < 78.334) {
            return "740029";
        }
    }

    render() {

        console.log("EffectivenessReportExportingSpreadsheet [render]");

        const {name, title, header, tableTop, tableMiddle, tableBottom} = this.props;

        let title_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "c3c7cc"}},
                right: {style: 'thin', color: {rgb: "c3c7cc"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let titleC1_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let titleC6_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };

        let metaheader_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let metaheaderC1_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let metaheaderC6_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };

        let metadata_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", color: {rgb: "818ea3"}},
        };
        let metadataC1_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", color: {rgb: "818ea3"}},
        };
        let metadataC6_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", color: {rgb: "818ea3"}},
        };

        let data_style = {
            border: {
                bottom: {style: 'thin', color: {rgb: "dee2e6"}},
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
            },
            alignment: {horizontal: "center", vertical: "center", wrapText: true},
            font: {sz: "14", color: {rgb: "818ea3"}},
        };
        let dataC1_style = {
            border: {
                bottom: {style: 'thin', color: {rgb: "dee2e6"}},
                left: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
            },
            alignment: {horizontal: "center", vertical: "center", wrapText: true},
            font: {sz: "14", color: {rgb: "818ea3"}},
        };

        let obs_style = {
            alignment: {horizontal: "bottom", vertical: "center", wrapText: true},
            border: {
                top: {style: 'thin', color: {rgb: "dee2e6"}},
                bottom: {style: 'thin', color: {rgb: "dee2e6"}},
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
            font: {sz: "14", color: {rgb: "818ea3"}},
        };

        let TopHeader_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "dee2e6"}},
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let TopHeaderC1_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "dee2e6"}},
                left: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let TopHeaderC6_style = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "dee2e6"}},
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };

        let BottomHeader_style = {
            border: {
                top: {style: 'thin', color: {rgb: "dee2e6"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let BottomHeaderC1_style = {
            border: {
                top: {style: 'thin', color: {rgb: "dee2e6"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let BottomHeaderC2_style = {
            border: {
                top: {style: 'thin', color: {rgb: "dee2e6"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let BottomHeaderC3_style = {
            border: {
                top: {style: 'thin', color: {rgb: "dee2e6"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let BottomHeaderC5_style = {
            border: {
                top: {style: 'thin', color: {rgb: "dee2e6"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };
        let BottomHeaderC6_style = {
            border: {
                top: {style: 'thin', color: {rgb: "dee2e6"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "14", bold: true, color: {rgb: "FFFFFF"}},
            fill: {patternType: "solid", fgColor: {rgb: "c3c7cc"}, bgColor: {rgb: "c3c7cc"}},
        };

        let tableTop_title = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "3d5170"}},
            fill: {patternType: "solid", fgColor: {rgb: "5cd79b"}, bgColor: {rgb: "5cd79b"}},
        };
        let tableTopC1_title = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "3d5170"}},
            fill: {patternType: "solid", fgColor: {rgb: "5cd79b"}, bgColor: {rgb: "5cd79b"}},
        };
        let tableTopC6_title = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "3d5170"}},
            fill: {patternType: "solid", fgColor: {rgb: "5cd79b"}, bgColor: {rgb: "5cd79b"}},
        };

        let tableMiddle_title = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "3d5170"}},
            fill: {patternType: "solid", fgColor: {rgb: "ffca4c"}, bgColor: {rgb: "ffca4c"}},
        };
        let tableMiddleC1_title = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "3d5170"}},
            fill: {patternType: "solid", fgColor: {rgb: "ffca4c"}, bgColor: {rgb: "ffca4c"}},
        };
        let tableMiddleC6_title = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "3d5170"}},
            fill: {patternType: "solid", fgColor: {rgb: "ffca4c"}, bgColor: {rgb: "ffca4c"}},
        };

        let tableBottom_title = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "3d5170"}},
            fill: {patternType: "solid", fgColor: {rgb: "d45b75"}, bgColor: {rgb: "d45b75"}},
        };
        let tableBottomC1_title = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                left: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "3d5170"}},
            fill: {patternType: "solid", fgColor: {rgb: "d45b75"}, bgColor: {rgb: "d45b75"}},
        };
        let tableBottomC6_title = {
            border: {
                top: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
            alignment: {horizontal: "center", vertical: "center"},
            font: {sz: "16", bold: true, color: {rgb: "3d5170"}},
            fill: {patternType: "solid", fgColor: {rgb: "d45b75"}, bgColor: {rgb: "d45b75"}},
        };

        let separator_style = {
            border: {
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
            },
        };
        let separatorC1_style = {
            border: {
                left: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
            },
        };
        let separatorC6_style = {
            border: {
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
            },
        };
        let separatorR1_style = {
            border: {
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
                top: {style: 'thin', color: {rgb: "818ea3"}},
            },
        };
        let separatorR20_style = {
            border: {
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
            },
        };
        let separatorR1C1_style = {
            border: {
                left: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
                top: {style: 'thin', color: {rgb: "818ea3"}},
            },
        };
        let separatorR1C6_style = {
            border: {
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
                top: {style: 'thin', color: {rgb: "818ea3"}},
            },
        };
        let separatorR20C1_style = {
            border: {
                left: {style: 'thin', color: {rgb: "818ea3"}},
                right: {style: 'thin', color: {rgb: "FFFFFF"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
            },
        };
        let separatorR20C6_style = {
            border: {
                left: {style: 'thin', color: {rgb: "FFFFFF"}},
                right: {style: 'thin', color: {rgb: "818ea3"}},
                bottom: {style: 'thin', color: {rgb: "818ea3"}},
            },
        };

        let columns_sheet = [
            {title: "", width: {wpx: 95.4592}},
            {title: "", width: {wpx: 208.2982}},
            {title: "", width: {wpx: 131.7752}},
            {title: "", width: {wpx: 202.5914}},
            {title: "", width: {wpx: 197.9222}},
            {title: "", width: {wpx: 207.2606}}
        ];
        let data_sheet = [];
        let set = [];

        // Sheet's Title
        data_sheet.push([
            {value: "", style: titleC1_style},
            {value: "RELATÓRIO SEMANAL", style: title_style},
            {value: "-", style: title_style},
            {value: "EFETIVIDADE", style: title_style},
            {value: "", style: title_style},
            {value: "", style: titleC6_style},
        ]);

        // Sheet's Separator
        data_sheet.push([
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
        ]);

        // Sheet's Meta Header
        data_sheet.push([
            {value: "", style: metaheaderC1_style},
            {value: "EMPRESA", style: metaheader_style},
            {value: "PRESTADOR", style: metaheader_style},
            {value: "TIPOAPLICACAO", style: metaheader_style},
            {value: "PERÍODO", style: metaheader_style},
            {value: "", style: metaheaderC6_style},
        ]);

        // Sheet's Meta Data
        data_sheet.push([
            {value: "", style: metadataC1_style},
            {value: header.data.empresa.toUpperCase(), style: metadata_style},
            {value: header.data.prestador.toUpperCase(), style: metadata_style},
            {value: header.data.tipoaplicacao.toUpperCase(), style: metadata_style},
            {
                value: this.convertDate(header.data.dataInicial) + " - " + this.convertDate(header.data.dataFinal),
                style: metadata_style
            },
            {value: "", style: metadataC6_style},
        ]);

        // Sheet's Separator
        data_sheet.push([
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
        ]);

        // Sheet's Space for Graph
        data_sheet.push([
            {value: "", style: separatorR1C1_style},
            {value: "", style: separatorR1_style},
            {value: "", style: separatorR1_style},
            {value: "", style: separatorR1_style},
            {value: "", style: separatorR1_style},
            {value: "", style: separatorR1C6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorC1_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separatorC6_style},
        ]);
        data_sheet.push([
            {value: "", style: separatorR20C1_style},
            {value: "", style: separatorR20_style},
            {value: "", style: separatorR20_style},
            {value: "", style: separatorR20_style},
            {value: "", style: separatorR20_style},
            {value: "", style: separatorR20C6_style},
        ]);

        // Sheet's Separator
        data_sheet.push([
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
        ]);

        // TableTop's Title
        data_sheet.push([
            {value: "", style: tableTopC1_title},
            {value: "", style: tableTop_title},
            {value: "", style: tableTop_title},
            {value: "≥ 90%: ALTA EFETIVIDADE", style: tableTop_title},
            {value: "", style: tableTop_title},
            {value: "", style: tableTopC6_title},
        ]);

        // TableTop's Header
        data_sheet.push([
            {value: "CÓDIGO", style: TopHeaderC1_style},
            {value: "PILOTO", style: TopHeader_style},
            {value: "PRESTADORA", style: TopHeader_style},
            {value: "ÁREA DE PLANTIO (ha)", style: TopHeader_style},
            {value: "EFETIVIDADE (%)", style: TopHeader_style},
            {value: "OBSERVAÇÕES", style: TopHeaderC6_style},
        ]);

        // TableTop's Data
        tableTop.data.forEach((obj) => {

            let observacoesList = obj.observacoes.split("\n");
            let observacoesString = "";

            observacoesList.forEach((obs, index) => {
                if (!(/^ *$/.test(obs))) {
                    if (index === 0) {
                        observacoesString = "- " + obs;
                    } else {
                        observacoesString = observacoesString + "\n" + "- " + obs;
                    }
                }
            })

            data_sheet.push([
                {value: obj.codigo, style: dataC1_style},
                {value: obj.piloto.name.toUpperCase(), style: data_style},
                {value: obj.prestadora.name.toUpperCase(), style: data_style},
                {value: obj.areaPlantio, style: data_style},
                {
                    value: parseFloat(obj.efetividade).toFixed(2),
                    style: {
                        ...data_style,
                        fill: {fgColor: {rgb: this.gradient(obj.efetividade)}},
                        font: {...data_style.font, color: {rgb: "FFFFFF"}}
                    }
                },
                {value: observacoesString, style: obs_style},
            ]);

        });

        // TableTop's Footer
        data_sheet.push([
            {value: "", style: BottomHeaderC1_style},
            {value: "TOTAL", style: BottomHeaderC2_style},
            {value: "", style: BottomHeaderC3_style},
            {value: tableTop.total, style: BottomHeader_style},
            {value: "", style: BottomHeaderC5_style},
            {value: "", style: BottomHeaderC6_style},
        ]);

        // Sheet's Separator
        data_sheet.push([
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
        ]);

        // TableMiddle's Title
        data_sheet.push([
            {value: "", style: tableMiddleC1_title},
            {value: "", style: tableMiddle_title},
            {value: "", style: tableMiddle_title},
            {value: "85% ≥ < 90%: MÉDIA EFETIVIDADE", style: tableMiddle_title},
            {value: "", style: tableMiddle_title},
            {value: "", style: tableMiddleC6_title},
        ]);

        // TableMiddle's Header
        data_sheet.push([
            {value: "CÓDIGO", style: TopHeaderC1_style},
            {value: "PILOTO", style: TopHeader_style},
            {value: "PRESTADORA", style: TopHeader_style},
            {value: "ÁREA DE PLANTIO (ha)", style: TopHeader_style},
            {value: "EFETIVIDADE (%)", style: TopHeader_style},
            {value: "OBSERVAÇÕES", style: TopHeaderC6_style},
        ]);

        // TableMiddle's Data
        tableMiddle.data.forEach((obj) => {

            let observacoesList = obj.observacoes.split("\n");
            let observacoesString = "";

            observacoesList.forEach((obs, index) => {
                if (!(/^ *$/.test(obs))) {
                    if (index === 0) {
                        observacoesString = "- " + obs;
                    } else {
                        observacoesString = observacoesString + "\n" + "- " + obs;
                    }
                }
            })

            data_sheet.push([
                {value: obj.codigo, style: dataC1_style},
                {value: obj.piloto.name.toUpperCase(), style: data_style},
                {value: obj.prestadora.name.toUpperCase(), style: data_style},
                {value: obj.areaPlantio, style: data_style},
                {
                    value: parseFloat(obj.efetividade).toFixed(2),
                    style: {
                        ...data_style,
                        fill: {fgColor: {rgb: this.gradient(obj.efetividade)}},
                        font: {...data_style.font, color: {rgb: "FFFFFF"}}
                    }
                },
                {value: observacoesString, style: obs_style},
            ]);

        });

        // TableMiddle's Footer
        data_sheet.push([
            {value: "", style: BottomHeaderC1_style},
            {value: "TOTAL", style: BottomHeaderC2_style},
            {value: "", style: BottomHeaderC3_style},
            {value: tableMiddle.total, style: BottomHeader_style},
            {value: "", style: BottomHeaderC5_style},
            {value: "", style: BottomHeaderC6_style},
        ]);

        // Sheet's Separator
        data_sheet.push([
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
        ]);

        // TableMiddle's Title
        data_sheet.push([
            {value: "", style: tableBottomC1_title},
            {value: "", style: tableBottom_title},
            {value: "", style: tableBottom_title},
            {value: "< 85%: BAIXA EFETIVIDADE", style: tableBottom_title},
            {value: "", style: tableBottom_title},
            {value: "", style: tableBottomC6_title},
        ]);

        // TableBottom's Header
        data_sheet.push([
            {value: "CÓDIGO", style: TopHeaderC1_style},
            {value: "PILOTO", style: TopHeader_style},
            {value: "PRESTADORA", style: TopHeader_style},
            {value: "ÁREA DE PLANTIO (ha)", style: TopHeader_style},
            {value: "EFETIVIDADE (%)", style: TopHeader_style},
            {value: "OBSERVAÇÕES", style: TopHeaderC6_style},
        ]);

        // TableBottom's Data
        tableBottom.data.forEach((obj) => {

            let observacoesList = obj.observacoes.split("\n");
            let observacoesString = "";

            observacoesList.forEach((obs, index) => {
                if (!(/^ *$/.test(obs))) {
                    if (index === 0) {
                        observacoesString = "- " + obs;
                    } else {
                        observacoesString = observacoesString + "\n" + "- " + obs;
                    }
                }
            })

            data_sheet.push([
                {value: obj.codigo, style: dataC1_style},
                {value: obj.piloto.name.toUpperCase(), style: data_style},
                {value: obj.prestadora.name.toUpperCase(), style: data_style},
                {value: obj.areaPlantio, style: data_style},
                {
                    value: parseFloat(obj.efetividade).toFixed(2),
                    style: {
                        ...data_style,
                        fill: {fgColor: {rgb: this.gradient(obj.efetividade)}},
                        font: {...data_style.font, color: {rgb: "FFFFFF"}}
                    }
                },
                {value: observacoesString, style: obs_style},
            ]);

        });

        // TableBottom's Footer
        data_sheet.push([
            {value: "", style: BottomHeaderC1_style},
            {value: "TOTAL", style: BottomHeaderC2_style},
            {value: "", style: BottomHeaderC3_style},
            {value: tableBottom.total, style: BottomHeader_style},
            {value: "", style: BottomHeaderC5_style},
            {value: "", style: BottomHeaderC6_style},
        ]);

        // Sheet's Separator
        data_sheet.push([
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
            {value: "", style: separator_style},
        ]);

        // Sheet's Footer
        data_sheet.push([
            {value: "", style: titleC1_style},
            {value: "RELATÓRIO SEMANAL", style: title_style},
            {value: "-", style: title_style},
            {value: "EFETIVIDADE", style: title_style},
            {value: "", style: title_style},
            {value: "", style: titleC6_style},
        ]);

        // Sheet's Set
        set.push({
            columns: columns_sheet,
            data: data_sheet
        });

        const button = (
            <Button id={"re_filter_btn_export_xlsx"} theme="success" type={"button"} className={"mb-1 w-100 shadow"}>
                XLSX
            </Button>
        );

        return (
            <ExcelFile
                filename={name}
                element={button}>
                <ExcelSheet dataSet={set} name={title}/>
            </ExcelFile>
        );
    }

}

EffectivenessReportExportingSpreadsheet.propTypes = {
    /**
     * The file's name.
     */
    name: PropTypes.string,
    /**
     * The sheet's title.
     */
    title: PropTypes.string,
    /**
     * The sheet's meta header.
     */
    header: PropTypes.object,
    /**
     * The tableTop's data.
     */
    tableTop: PropTypes.object,
    /**
     * The tableMiddle's data.
     */
    tableMiddle: PropTypes.object,
    /**
     * The tableBottom's data.
     */
    tableBottom: PropTypes.object,
};

EffectivenessReportExportingSpreadsheet.defaultProps = {
    name: "effectiveness_report_spreadsheet",
    title: "Efficiency Report -" + new Date().toLocaleString('pt-BR'),
    header: {
        data: {
            empresa: "-",
            prestadora: "-",
            tratamento: "-",
            dataInicial: new Date().toLocaleString("pt-BR"),
            dataFinal: new Date().toLocaleString("pt-BR"),
        },
    },
    tableTop: {
        total: 50,
        data: [
            {
                id: 1,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 100,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 2,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 97,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 3,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 95,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 4,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 93,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 5,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 90,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
        ]
    },
    tableMiddle: {
        total: 30,
        data: [
            {
                id: 6,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 89,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 7,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 88,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 8,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 87,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 9,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 86,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 10,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 85,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
        ]
    },
    tableBottom: {
        total: 20,
        data: [
            {
                id: 11,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 84,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 12,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 80,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 13,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 78.5,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 14,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 75,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
            {
                id: 15,
                codigo: "NUMBER",
                piloto: "TEXT",
                prestadora: "TEXT",
                areaPlantio: "NUMBER",
                efetividade: 70,
                observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                change: false,
            },
        ]
    },
};

export default EffectivenessReportExportingSpreadsheet;
