import {actions as toastrActions} from 'react-redux-toastr'

const showToastr = (title, message, type='success', position='top-right') => {
  return toastrActions.add({
    type: type,
    title: title,
    position: position, // This will override the global props position.
    attention: true, // This will add a shadow like the confirm method.
    onAttentionClick: (id) => {
    }, //override default behavior of 'attention' background click.
    message: message,
    options: {}
  })
};

export default showToastr
