import React, {Component} from "react";
import {
  Button, Card, Col, Container, Form, FormCheckbox, FormInput, FormRadio, FormSelect, ListGroup, ListGroupItem, Row
} from "shards-react";
import {connect} from 'react-redux'

import PageTitle from "../components/common/PageTitle";
import * as actions from '../store/actions'
import contentArrays from "../utils/arrays";


import validator from 'validator'

class NewServiceCompany extends Component {

  state = {
    form: {
      nome: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 3
        }
      }
    }
  };

  componentDidMount() {
  }

  onFormSendHandler = e => {
    e.preventDefault();
    let formData = new FormData();
    for (let key in this.state.form) {
      if (this.state.form.hasOwnProperty(key)) {
        let itemName = key;
        if (this.state.form[key].name) {
          itemName = this.state.form[key].name
        }
        if (this.state.form[key].store) {
          formData.append(itemName, this.state.form[key].store)
        } else {
          formData.append(itemName, this.state.form[key].value);
        }
        console.log(itemName, this.state.form[key]);
      }
    }
    console.log(formData)
    this.props.onSendNewPrestador(formData);

  };

  inputChangedHandler(e, key) {
    console.log(key);
    const obj = {...this.state.form[key]};
    const incomingValue = e.target.value;

    let valid = true;

    if (obj.validator) {
      if (obj.validator.isEmail) {
        valid = validator.isEmail(incomingValue) && valid;
      }
      if (obj.validator.isLength) {
        valid = validator.isLength(incomingValue,
          {min: obj.validator.isLength}) && valid;
      }
      if (obj.validator.equals) {
        valid = validator.equals(incomingValue,
          this.state.form[obj.validator.equals].value) && valid;
      }
      if (obj.validator.isIn) {
        valid = validator.isIn(incomingValue,
          obj.validator.isIn) && valid;
      }
      if (obj.validator.isInt) {
        valid = validator.isInt(incomingValue) && valid;
      }
    }

    obj.valid = valid;
    obj.value = incomingValue;
    obj.touched = true;

    console.log(obj);

    this.setState(oldState => ({
      form: {
        ...oldState.form,
        [key]: obj
      }
    }))
  }

  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Novo Prestador" subtitle="Cadastro" className="text-sm-left"/>
        </Row>

        <Row>
          <Col>
            <Card small>
              <ListGroup flush>
                <ListGroupItem>
                  <Row>
                    <Col>
                      <Form>
                        <ListGroupItem className="p-3">

                          <h6 className="m-0 pb-3">Dados Gerais</h6>

                          <Row form>
                            <Col md="12" className="form-group">
                              <label htmlFor="inputNome">Nome</label>
                              <FormInput
                                id="inputNome"
                                placeholder="Nome Prestador"
                                onChange={event => this.inputChangedHandler(event,
                                  'nome')}/>
                            </Col>
                          </Row>
                        </ListGroupItem>

                        <ListGroupItem className="p-3">
                          <Row>
                          </Row>
                          <Button type="submit" theme="success"
                                  onClick={e => this.onFormSendHandler(e)}>Cadastrar</Button>
                        </ListGroupItem>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onSendNewPrestador: (formData) => dispatch(actions.sendNewPrestador(formData))
  }
};
const mapStateToProps = state => {
  return {
    misc: state.misc
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(NewServiceCompany);
