import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
  loadingReport: false,
  submitPercentage: 0
};

const reportLoadNew = (state, action) => {
  return updateObject(state, action.payload);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REPORT_LOAD_NEW:{
      return reportLoadNew(state, action)
    }
    case actionTypes.REPORT_START_LOADING_NEW: {
      return updateObject(state, {loadingReport: true, submitPercentage: 0})
    }
    case actionTypes.REPORT_CHANGE_SUBMIT_PERCENTAGE: {
      return updateObject(state, action.payload)
    }
    case actionTypes.REPORT_ERROR_LOADING_NEW: {
      return updateObject(state, {loadingReport: false, submitPercentage: 0})
    }
    case actionTypes.REPORT_SUCCESS_LOADING_NEW: {
      return updateObject(state, {loadingReport: false, submitPercentage: 0})
    }
    case actionTypes.REPORT_UPDATE_NOTES: {
      const laudo = {...state[action.payload.uuid]};
      console.log(laudo)
      laudo.laudoInfo.observacoesArray = action.payload.laudoInfo.observacoesArray;
      laudo.laudoInfo.observacoes = action.payload.laudoInfo.observacoes;
      
      return updateObject(state, {
        [action.payload.uuid]: laudo
      })
    }
    default:
      return state
  }
};

export default reducer;
