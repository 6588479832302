import React from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Collapse,
    FormTextarea,
    FormInput,
    FormCheckbox,
    FormSelect,
    Button,
    ButtonGroup,
    Fade,
} from "shards-react";
import colors from "../../utils/colors.js";
import classes from "./EffectivenessReportSegregationTable.module.css"

class EffectivenessReportSegregationTable extends React.Component {

    componentDidMount() {
        console.log("EffectivenessReportSegregationTable [componentDidMount]");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            console.log("EffectivenessReportSegregationTable [componentDidUpdate]");
        }
    }

    gradient(value) {

        if (value > 96.666) {
            return "#27AB73";
        }

        if (value <= 96.666 && value > 93.333) {
            return "#00814C";
        }

        if (value < 93.333 && value >= 90) {
            return "#005929";
        }

        if (value >= 88.33 && value < 90) {
            return "#CC9D18";
        }

        if (value < 88.33 && value >= 86.66) {
            return "#9A7200";
        }

        if (value < 86.66 && value >= 85) {
            return "#6B4900";
        }

        if (value >= 81.667 && value < 85) {
            return "#B33D5A";
        }

        if (value < 81.667 && value >= 78.334) {
            return "#931D41";
        }

        if (value < 78.334 && value > 0) {
            return "#740029";
        }

        if (value <= 0) {
            return "#5F5F5F";
        }

    }

    toggleClassLine(checked, edit, plus, item) {

        if (checked.length > 0) {
            if (item.checked) {
                return (classes.er_table_line_able);
            } else {
                return (classes.er_table_line_disabled);
            }
        }

        if (edit.length > 0) {
            if (item.change) {
                return (classes.er_table_line_able);
            } else {
                return (classes.er_table_line_disabled);
            }
        }

        if (plus.length > 0) {
            return (classes.er_table_line_disabled);
        }

        return (" ");
    }

    pullCheckedLines(data) {

        return data.filter((obj) => {
            return obj.checked;
        })
    }

    render() {

        console.log("EffectivenessReportSegregationTable [render]");

        const {toggle, title, headerColor, total, data, plus, edit, pilotos, prestadoras, reference, onChangeOldLine, handleEditOldLine, handleSaveOldLine, handleAbortOldLine, handleAddNewLine, onChangeNewLine, handleSaveNewLine, handleAbortNewLine, handleDeleteLine, handleMergeLines, handleToggleTables, onCheckLine} = this.props;
        let {checkeds} = this.props;

        if (checkeds.length === 0) {
            checkeds = this.pullCheckedLines(data);
        }

        return (
            <Card small className="lo-stats h-100 w-100">

                <CardHeader className="border-bottom text-center"
                            style={{backgroundColor: headerColor, cursor: "pointer"}}
                            onClick={handleToggleTables}>
                    <h6 className="m-0 font-weight-bold" style={{color: colors.fiordBlue.toRGBA(1)}}>
                        {title}
                    </h6>
                    <div className="block-handle"/>
                </CardHeader>

                <Collapse open={toggle}>

                    <CardBody className="p-0" style={{minHeight: "unset",}}>

                        <div className={"table-responsive"}>
                            <table className="table mb-0">

                                <thead className={classes.er_table_header + " py-2 border-bottom text-white"}
                                       style={{backgroundColor: colors.gray_default_1.toRGBA(1)}}>
                                <tr>
                                    <th className={classes.er_table_column_index}>
                                        #
                                    </th>
                                    <th className={classes.er_table_column_codigo}>
                                        CÓDIGO
                                    </th>
                                    <th className={classes.er_table_column_prestadora}>
                                        PRESTADORA
                                    </th>
                                    <th className={classes.er_table_column_piloto}>
                                        PILOTO
                                    </th>
                                    <th className={classes.er_table_column_areaPlantio}>
                                        ÁREA DE PLANTIO (ha)
                                    </th>
                                    <th className={classes.er_table_column_efetividade}>
                                        EFETIVIDADE (%)
                                    </th>
                                    <th className={classes.er_table_column_comments}>
                                        OBSERVAÇÕES
                                    </th>
                                    <th className={classes.er_table_column_actions}>
                                        AÇÕES
                                    </th>
                                </tr>
                                </thead>

                                <tbody className={classes.er_table_body}>

                                {data.length > 0 ? (

                                    data.map((item, id) => {

                                        let observacoesList = item.observacoes.split("\n")

                                        return (
                                            <tr key={id} className={this.toggleClassLine(checkeds, edit, plus, item)}>
                                                <td className={classes.er_table_column_index}>
                                                    <Fade hidden={item.change || plus.length > 0 || edit.length > 0}
                                                          in={!item.change && plus.length === 0 && edit.length === 0}>
                                                        <div
                                                            className={"d-flex align-items-center justify-content-center"}>
                                                            <FormCheckbox
                                                                checked={item.checked}
                                                                onChange={() => onCheckLine(item, reference)}
                                                                disabled={plus.length > 0 || edit.length > 0}/>
                                                        </div>
                                                    </Fade>
                                                    <Fade
                                                        hidden={!item.change && plus.length === 0 && edit.length === 0}
                                                        in={item.change || plus.length > 0 || edit.length > 0}>
                                                        <div className={"d-flex flex-column"}>
                                                            <i className="fas fa-minus"/>
                                                        </div>
                                                    </Fade>
                                                </td>
                                                <td className={classes.er_table_column_codigo}>
                                                    <Fade in={!item.change}>
                                                        <div hidden={item.change}
                                                             style={{minHeight: "15px"}}>
                                                            {item.codigo !== ""
                                                                ?
                                                                <span>{item.codigo}</span>
                                                                :
                                                                <span className={classes.er_cell_empty}>
                                                                    Nenhum registro encontrado
                                                                </span>
                                                            }
                                                        </div>
                                                    </Fade>
                                                    <Fade in={item.change}>
                                                        {edit.length > 0 ? (
                                                            <div
                                                                className={"d-flex flex-column justify-content-center align-items-center"}>
                                                                <FormTextarea
                                                                    id={"er_table_oldLine_input_codigo_" + id}
                                                                    hidden={!item.change}
                                                                    value={edit[0].form.codigo.value}
                                                                    onChange={(event) => onChangeOldLine(item, reference, event, "text", "codigo")}
                                                                    invalid={!edit[0].form.codigo.valid && edit[0].form.codigo.shouldValidate && edit[0].form.codigo.changed}
                                                                    valid={edit[0].form.codigo.valid && edit[0].form.codigo.shouldValidate && edit[0].form.codigo.changed}
                                                                />
                                                                <Button block type="submit" theme="success"
                                                                        onClick={() => handleEditOldLine(item, reference)}
                                                                        hidden={true}
                                                                        className={"align-self-end mt-2"}
                                                                        style={{fontSize: "1.125rem"}}>
                                                                    <i className="material-icons"> save </i>
                                                                </Button>
                                                            </div>
                                                        ) : false}
                                                    </Fade>
                                                </td>
                                                <td className={classes.er_table_column_prestadora}>
                                                    <Fade in={!item.change}>
                                                        <div hidden={item.change}
                                                             style={{minHeight: "15px"}}>
                                                            <span>{item.prestadora.name}</span>
                                                        </div>
                                                    </Fade>
                                                    <Fade in={item.change}>
                                                        {edit.length > 0 ? (
                                                            <div
                                                                className={"d-flex flex-column justify-content-center align-items-center"}>
                                                                <FormSelect
                                                                    id={"er_table_oldLine_input_prestadora_" + id}
                                                                    hidden={!item.change}
                                                                    value={edit[0].form.prestadora.value}
                                                                    onChange={(event) => onChangeOldLine(item, reference, event, "select", "prestadora")}
                                                                    invalid={!edit[0].form.prestadora.valid && edit[0].form.prestadora.shouldValidate && edit[0].form.prestadora.changed}
                                                                    valid={edit[0].form.prestadora.valid && edit[0].form.prestadora.shouldValidate && edit[0].form.prestadora.changed}>
                                                                    {prestadoras.map((obj, index) => {
                                                                        if (obj.value !== -1) {
                                                                            return (
                                                                                <option key={index} id={obj.value}
                                                                                        value={obj.value}>{obj.name}</option>);
                                                                        }
                                                                    })}
                                                                </FormSelect>
                                                                <Button block type="submit" theme="success"
                                                                        onClick={() => handleEditOldLine(item, reference)}
                                                                        hidden={true}
                                                                        className={"align-self-end mt-2"}
                                                                        style={{fontSize: "1.125rem"}}>
                                                                    <i className="material-icons"> save </i>
                                                                </Button>
                                                            </div>
                                                        ) : false}
                                                    </Fade>
                                                </td>
                                                <td className={classes.er_table_column_piloto}>
                                                    <Fade in={!item.change}>
                                                        <div hidden={item.change}
                                                             style={{minHeight: "15px"}}>
                                                            <span>{item.piloto.name}</span>
                                                        </div>
                                                    </Fade>
                                                    <Fade in={item.change}>
                                                        {edit.length > 0 ? (
                                                            <div
                                                                className={"d-flex flex-column justify-content-center align-items-center"}>
                                                                <FormSelect
                                                                    id={"er_table_oldLine_input_piloto_" + id}
                                                                    hidden={!item.change}
                                                                    value={edit[0].form.piloto.value}
                                                                    onChange={(event) => onChangeOldLine(item, reference, event, "select", "piloto")}
                                                                    invalid={!edit[0].form.piloto.valid && edit[0].form.piloto.shouldValidate && edit[0].form.piloto.changed}
                                                                    valid={edit[0].form.piloto.valid && edit[0].form.piloto.shouldValidate && edit[0].form.piloto.changed}>
                                                                    {pilotos.filter(function (obj) {
                                                                        return obj.prestadora.value === edit[0].form.prestadora.value || obj.value === -1;
                                                                    }).map(function (obj, index) {
                                                                        return (
                                                                            <option key={index}
                                                                                    value={obj.value}>
                                                                                {obj.name}
                                                                            </option>);
                                                                    })}
                                                                </FormSelect>
                                                                <Button block type="submit" theme="success"
                                                                        onClick={() => handleEditOldLine(item, reference)}
                                                                        hidden={true}
                                                                        className={"align-self-end mt-2"}
                                                                        style={{fontSize: "1.125rem"}}>
                                                                    <i className="material-icons"> save </i>
                                                                </Button>
                                                            </div>

                                                        ) : false}

                                                    </Fade>
                                                </td>
                                                <td className={classes.er_table_column_areaPlantio}>
                                                    <Fade in={!item.change}>
                                                        <div hidden={item.change}
                                                             style={{minHeight: "15px"}}>
                                                            <span>{item.areaPlantio}</span>
                                                        </div>
                                                    </Fade>
                                                    <Fade in={item.change}>
                                                        {edit.length > 0 ? (
                                                            <div
                                                                className={"d-flex flex-column justify-content-center align-items-center"}>
                                                                <FormInput
                                                                    id={"er_table_oldLine_input_areaPlantio_" + id}
                                                                    type={"number"}
                                                                    hidden={!item.change}
                                                                    value={edit[0].form.areaPlantio.value}
                                                                    onChange={(event) => onChangeOldLine(item, reference, event, "number", "areaPlantio")}
                                                                    invalid={!edit[0].form.areaPlantio.valid && edit[0].form.areaPlantio.shouldValidate && edit[0].form.areaPlantio.changed}
                                                                    valid={edit[0].form.areaPlantio.valid && edit[0].form.areaPlantio.shouldValidate && edit[0].form.areaPlantio.changed}/>
                                                                <Button block type="submit" theme="success"
                                                                        onClick={() => handleEditOldLine(item, reference)}
                                                                        hidden={true}
                                                                        className={"align-self-end mt-2"}
                                                                        style={{fontSize: "1.125rem"}}>
                                                                    <i className="material-icons"> save </i>
                                                                </Button>
                                                            </div>
                                                        ) : false}
                                                    </Fade>
                                                </td>
                                                <td className={classes.er_table_column_efetividade} style={{
                                                    color: colors.white.toRGBA(1),
                                                    backgroundColor: this.gradient(item.efetividade)
                                                }}>
                                                    <Fade in={!item.change}>
                                                        <div hidden={item.change}
                                                             style={{minHeight: "15px"}}>
                                                            <span>{parseFloat(item.efetividade).toFixed(2)}</span>
                                                        </div>
                                                    </Fade>
                                                    <Fade in={item.change}>
                                                        {edit.length > 0 ? (
                                                            <div
                                                                className={"d-flex flex-column justify-content-center align-items-center"}>
                                                                <FormInput
                                                                    id={"er_table_oldLine_input_efetividade_" + id}
                                                                    type={"number"}
                                                                    hidden={!item.change}
                                                                    value={edit[0].form.efetividade.value}
                                                                    onChange={(event) => onChangeOldLine(item, reference, event, "number", "efetividade")}
                                                                    invalid={!edit[0].form.efetividade.valid && edit[0].form.efetividade.shouldValidate && edit[0].form.efetividade.changed}
                                                                    valid={edit[0].form.efetividade.valid && edit[0].form.efetividade.shouldValidate && edit[0].form.efetividade.changed}/>
                                                                <Button block type="submit" theme="success"
                                                                        onClick={() => handleEditOldLine(item, reference)}
                                                                        hidden={true}
                                                                        className={"align-self-end mt-2"}
                                                                        style={{fontSize: "1.125rem"}}>
                                                                    <i className="material-icons"> save </i>
                                                                </Button>
                                                            </div>
                                                        ) : false}
                                                    </Fade>
                                                </td>
                                                <td className={"text-left " + classes.er_table_column_comments} style={{
                                                    color: colors.reagentGray.toRGBA(1),
                                                }}>
                                                    <Fade in={!item.change}>
                                                        <div hidden={item.change} style={{minHeight: "15px"}}>
                                                            {item.observacoes !== ""
                                                                ?
                                                                <ul>
                                                                    {observacoesList.map((obs, index) => {
                                                                        return (<li key={index}>{obs}</li>)
                                                                    })}
                                                                </ul>
                                                                :
                                                                <span className={classes.er_cell_empty}>
                                                                    Nenhum registro encontrado
                                                                </span>
                                                            }
                                                        </div>
                                                    </Fade>
                                                    <Fade in={item.change}>
                                                        {edit.length > 0 ? (
                                                            <div className={"d-flex flex-column"}>
                                                                <FormTextarea
                                                                    id={"er_table_oldLine_input_observacoes_" + id}
                                                                    hidden={!item.change}
                                                                    value={edit[0].form.observacoes.value}
                                                                    onChange={(event) => onChangeOldLine(item, reference, event, "text", "observacoes")}
                                                                    invalid={!edit[0].form.observacoes.valid && edit[0].form.observacoes.shouldValidate && edit[0].form.observacoes.changed}
                                                                    valid={edit[0].form.observacoes.valid && edit[0].form.observacoes.shouldValidate && edit[0].form.observacoes.changed}/>
                                                                <Button block type="submit" theme="success"
                                                                        onClick={() => handleEditOldLine(item, reference)}
                                                                        hidden={true}
                                                                        className={"align-self-end mt-2"}
                                                                        style={{fontSize: "1.125rem"}}>
                                                                    <i className="material-icons"> save </i>
                                                                </Button>
                                                            </div>
                                                        ) : false}
                                                    </Fade>
                                                </td>
                                                <td className={classes.er_table_column_actions}>
                                                    <Fade in={!item.change}>
                                                        <ButtonGroup hidden={item.change}>
                                                            <Button type="submit" theme="white"
                                                                    disabled={checkeds.length > 0 || plus.length > 0 || edit.length > 0}
                                                                    onClick={() => handleEditOldLine(item, reference)}>
                                                                <i className="material-icons"> edit </i>
                                                            </Button>
                                                            <Button type="submit" theme="danger"
                                                                    disabled={checkeds.length > 0 || plus.length > 0 || edit.length > 0}
                                                                    onClick={() => handleDeleteLine(item, reference)}>
                                                                <i className="material-icons"> delete </i>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Fade>
                                                    <Fade in={item.change}>
                                                        {edit.length > 0 ? (
                                                            <ButtonGroup hidden={!item.change}>
                                                                <Button type="submit" theme="success"
                                                                        disabled={!edit[0].valid}
                                                                        onClick={() => handleSaveOldLine(item, reference)}>
                                                                    <i className="fas fa-check"/>
                                                                </Button>
                                                                <Button type="submit" theme="danger"
                                                                        onClick={() => handleAbortOldLine(item, reference)}>
                                                                    <i className="fas fa-times"/>
                                                                </Button>
                                                            </ButtonGroup>
                                                        ) : false}
                                                    </Fade>
                                                </td>
                                            </tr>
                                        )
                                    })

                                ) : (
                                    <tr>
                                        <td className={"text-center"} style={{color: colors.reagentGray.toRGBA(1)}}
                                            colSpan={"8"}>
                                            Nenhuma informação encontrada! :(
                                        </td>
                                    </tr>
                                )}

                                {plus.length > 0 ? (

                                    plus.map((item, id) => {
                                        return (
                                            <tr key={id}>
                                                <td className={"text-center"}
                                                    style={{
                                                        color: colors.reagentGray.toRGBA(1)
                                                    }}>
                                                    <i className="fas fa-minus"/>
                                                </td>
                                                <td className={"text-center"}
                                                    style={{
                                                        color: colors.reagentGray.toRGBA(1),
                                                    }}>
                                                    <div
                                                        className={"d-flex justify-content-center align-items-center"}>
                                                        <FormTextarea
                                                            id={"er_table_newLine_input_codigo_" + id}
                                                            value={item.form.codigo.value}
                                                            onChange={(event) => onChangeNewLine(item, reference, event, "text", "codigo")}
                                                            invalid={!item.form.codigo.valid && item.form.codigo.shouldValidate && item.form.codigo.changed}
                                                            valid={item.form.codigo.valid && item.form.codigo.shouldValidate && item.form.codigo.changed}
                                                        />
                                                    </div>
                                                </td>
                                                <td className={"text-center"}
                                                    style={{color: colors.reagentGray.toRGBA(1)}}>
                                                    <div
                                                        className={"d-flex justify-content-center align-items-center"}>
                                                        <FormSelect
                                                            id={"er_table_newLine_input_prestadora_" + id}
                                                            value={item.form.prestadora.value}
                                                            onChange={(event) => onChangeNewLine(item, reference, event, "select", "prestadora")}
                                                            invalid={!item.form.prestadora.valid && item.form.prestadora.shouldValidate && item.form.prestadora.changed}
                                                            valid={item.form.prestadora.valid && item.form.prestadora.shouldValidate && item.form.prestadora.changed}>
                                                            {prestadoras.map((obj, index) => {
                                                                return (
                                                                    <option key={index} id={obj.value}
                                                                            value={obj.value}>{obj.name}</option>);
                                                            })}
                                                        </FormSelect>
                                                    </div>
                                                </td>
                                                <td className={"text-center"}
                                                    style={{color: colors.reagentGray.toRGBA(1)}}>
                                                    <div
                                                        className={"d-flex justify-content-center align-items-center"}>
                                                        <FormSelect
                                                            id={"er_table_newLine_input_piloto_" + id}
                                                            value={item.form.piloto.value}
                                                            onChange={(event) => onChangeNewLine(item, reference, event, "select", "piloto")}
                                                            invalid={!item.form.piloto.valid && item.form.piloto.shouldValidate && item.form.piloto.changed}
                                                            valid={item.form.piloto.valid && item.form.piloto.shouldValidate && item.form.piloto.changed}>
                                                            {pilotos.filter(function (obj) {
                                                                return obj.prestadora.value === item.form.prestadora.value || obj.value === -1;
                                                            }).map(function (obj, index) {
                                                                return (
                                                                    <option key={index}
                                                                            value={obj.value}>{obj.name}</option>);
                                                            })}
                                                        </FormSelect>
                                                    </div>
                                                </td>
                                                <td className={"text-center"}
                                                    style={{color: colors.reagentGray.toRGBA(1)}}>
                                                    <div
                                                        className={"d-flex justify-content-center align-items-center"}>
                                                        <FormInput id={"er_table_newLine_input_areaPlantio_" + id}
                                                                   type={"number"}
                                                                   value={item.form.areaPlantio.value}
                                                                   onChange={(event) => onChangeNewLine(item, reference, event, "number", "areaPlantio")}
                                                                   invalid={!item.form.areaPlantio.valid && item.form.areaPlantio.shouldValidate && item.form.areaPlantio.changed}
                                                                   valid={item.form.areaPlantio.valid && item.form.areaPlantio.shouldValidate && item.form.areaPlantio.changed}/>
                                                    </div>
                                                </td>
                                                <td className={"text-center"}
                                                    style={{
                                                        color: colors.white.toRGBA(1),
                                                        backgroundColor: this.gradient(item.form.efetividade.value)
                                                    }}>
                                                    <div
                                                        className={"d-flex justify-content-center align-items-center"}>
                                                        <FormInput id={"er_table_newLine_input_efetividade_" + id}
                                                                   type={"number"}
                                                                   value={item.form.efetividade.value}
                                                                   onChange={(event) => onChangeNewLine(item, reference, event, "number", "efetividade")}
                                                                   invalid={!item.form.efetividade.valid && item.form.efetividade.shouldValidate && item.form.efetividade.changed}
                                                                   valid={item.form.efetividade.valid && item.form.efetividade.shouldValidate && item.form.efetividade.changed}/>
                                                    </div>
                                                </td>
                                                <td className={"text-left"} style={{
                                                    color: colors.reagentGray.toRGBA(1),
                                                }}>
                                                    <div
                                                        className={"d-flex justify-content-center align-items-center"}>
                                                        <FormTextarea
                                                            id={"er_table_newLine_input_observacoes_" + id}
                                                            value={item.form.observacoes.value}
                                                            onChange={(event) => onChangeNewLine(item, reference, event, "text", "observacoes")}
                                                            invalid={!item.form.observacoes.valid && item.form.observacoes.shouldValidate && item.form.observacoes.changed}
                                                            valid={item.form.observacoes.valid && item.form.observacoes.shouldValidate && item.form.observacoes.changed}/>
                                                    </div>
                                                </td>
                                                <td className={"text-center"}
                                                    style={{color: colors.reagentGray.toRGBA(1)}}>
                                                    <div
                                                        className={"d-flex flex-column align-items-center"}>
                                                        <ButtonGroup>
                                                            <Button type="submit" theme="success"
                                                                    onClick={() => handleSaveNewLine(reference)}
                                                                    disabled={!item.valid}>
                                                                <i className="fas fa-check"/>
                                                            </Button>
                                                            <Button type="submit" theme="danger"
                                                                    onClick={() => handleAbortNewLine(reference)}>
                                                                <i className="fas fa-times"/>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })

                                ) : false}

                                <tr>
                                    <td className={"text-center"} style={{borderRight: "1px solid #dee2e6"}}>
                                        <Button block theme={"light"}
                                                onClick={() => handleMergeLines(checkeds, reference)}
                                                style={{color: colors.fiordBlue.toRGBA(1), border: "1px solid #cdd0d5"}}
                                                disabled={checkeds.length <= 1 || plus.length > 0 || edit.length > 0}>
                                            <i className="material-icons"> call_merge </i>
                                        </Button>
                                    </td>
                                    <td className={"text-center"} colSpan={"7"}>
                                        <Button block theme={"light"}
                                                onClick={() => handleAddNewLine(reference)}
                                                style={{color: colors.fiordBlue.toRGBA(1), border: "1px solid #cdd0d5"}}
                                                disabled={checkeds.length > 0 || plus.length > 0 || edit.length > 0}>
                                            <i className="fas fa-plus"/>
                                        </Button>
                                    </td>
                                </tr>

                                </tbody>

                                <tfoot className="py-2 border-bottom text-white "
                                       style={{backgroundColor: colors.gray_default_1.toRGBA(1)}}>
                                <tr>
                                    <td className="text-center font-weight-bold" colSpan={"4"}>
                                        TOTAL
                                    </td>
                                    <td className={"text-center border"}>
                                        <span>{total}</span>
                                    </td>
                                    <td colSpan={"3"}/>
                                </tr>
                                </tfoot>

                            </table>
                        </div>

                    </CardBody>

                </Collapse>

                <CardFooter className="border-top"/>

            </Card>
        );
    }

}

EffectivenessReportSegregationTable.propTypes = {
    /**
     * The component's toggle.
     */
    toggle: PropTypes.bool,

    /**
     * The component's title.
     */
    title: PropTypes.string,

    /**
     * The component's headerColor.
     */
    headerColor: PropTypes.string,

    /**
     * The component's total.
     */
    total: PropTypes.number,

    /**
     * The latest orders data.
     */
    data: PropTypes.array,

    /**
     * The latest orders plus lines.
     */
    plus: PropTypes.array,

    /**
     * The latest orders prestadora.
     */
    prestadora: PropTypes.array,

    /**
     * The latest orders pilotos.
     */
    pilotos: PropTypes.array,

    /**
     * The component's reference name.
     */
    reference: PropTypes.string,

    /** The component's plus line function **/
    handleAddNewLine: PropTypes.func,

    /** The component's save line function **/
    handleSaveNewLine: PropTypes.func,

    /** The component's delete line function **/
    handleAbortNewLine: PropTypes.func,

    /** The component's delete line function **/
    handleDeleteLine: PropTypes.func,

    /** The component's merge line's function **/
    handlerMergerLines: PropTypes.func,

    /**
     * The component's handleToggleTables.
     */
    handleToggleTables: PropTypes.func,

    /**
     * The component's handleEditOldLine.
     */
    handleEditOldLine: PropTypes.func,

    /**
     * The component's onChangeOldLine.
     */
    onChangeOldLine: PropTypes.func,

    /** The component's change line's inputs function **/
    onChangeNewLine: PropTypes.func,

    /** The component's check line's function **/
    onCheckLine: PropTypes.func,
};

EffectivenessReportSegregationTable.defaultProps = {
    toggle: false,
    title: "TTÍULO",
    headerColor: "#00b8d8",
    total: 0,
    data: [
        {
            id: 1,
            codigo: "NUMBER",
            piloto: "TEXT",
            prestadora: "TEXT",
            areaPlantio: "NUMBER",
            efetividade: "NUMBER",
            observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            change: false,
        },
        {
            id: 2,
            codigo: "NUMBER",
            piloto: "TEXT",
            prestadora: "TEXT",
            areaPlantio: "NUMBER",
            efetividade: "NUMBER",
            observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            change: false,
        },
        {
            id: 3,
            codigo: "NUMBER",
            piloto: "TEXT",
            prestadora: "TEXT",
            areaPlantio: "NUMBER",
            efetividade: "NUMBER",
            observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            change: false,
        },
        {
            id: 4,
            codigo: "NUMBER",
            piloto: "TEXT",
            prestadora: "TEXT",
            areaPlantio: "NUMBER",
            efetividade: "NUMBER",
            observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            change: false,
        },
        {
            id: 5,
            codigo: "NUMBER",
            piloto: "TEXT",
            prestadora: "TEXT",
            areaPlantio: "NUMBER",
            efetividade: "NUMBER",
            observacoes: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            change: false,
        },
    ],
    plus: [],
    edit: [],
    prestadora: [],
    pilotos: [],
    checkeds: [],
    reference: "0",
    handleToggleTables: function () {
        console.log("Header clicked!")
    },
    handleEditOldLine: function () {
        console.log("Input Observações!")
    },
    handleAddNewLine: function () {
        console.log("Add new Line!")
    },
    handleSaveNewLine: function () {
        console.log("Save new Line!")
    },
    handleAbortNewLine: function () {
        console.log("Abort new Line!")
    },
    handleDeleteLine: function () {
        console.log("Delete new Line!")
    },
    handleMergeLines: function () {
        console.log("Merge Lines!")
    },
    onChangeOldLine: function () {
        console.log("Old Line changed!")
    },
    handleSaveOldLine: function () {
        console.log("Old Line saved!");
    },
    handleAbortOldLine: function () {
        console.log("Old Line edit aborted!");
    },
    onChangeNewLine: function () {
        console.log("Change new Line!")

    },
    onCheckLien: function () {
        console.log("Check Line!")

    }
};

export default EffectivenessReportSegregationTable;