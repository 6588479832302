import React, {Component} from 'react';
import PageTitle from "../components/common/PageTitle";
import {Card, CardBody, CardHeader, Col, Container, Row} from "shards-react";
import colors from "../utils/colors";
import SmallStats from "../components/common/SmallStats";
import LogInstanceDonut from "../components/analytics/LogInstanceDonut";
import LogHistoryBar from "../components/analytics/LogHistoryBar";
import LogSeriesBar from "../components/analytics/LogSeriesBar";
import FloatingFilter from "../components/filter/FloatingFilter/FloatingFilter";
import {connect} from 'react-redux';
import * as actions from '../store/actions';


class NewReactChart extends Component {

  constructor(props) {
    super(props);

    this.state = {
      /* Small Stats Blocks */
      smallStats: [],
      chartsHeight: 500
    };
  }

  setChartsHeight = (height) => {
    this.setState({
      ...this.state,
      chartsHeight: height
    })
  };

  componentDidMount() {
    this.props.onFetchDashboardData({...this.props.dashboard}.loading, this.props.isLoadingAuth);
  }
  componentWillMount() {
    if (!this.props.userInfo.nivel || this.props.userInfo.modalidade === 'spa' || this.props.userInfo.nivel === 'ministerio') {
      this.props.history.push('/map/ambiental')
    }
  }
  
  render() {

    const filt_fields = [
      {
        id: "select_company",
        name: "EMPRESAS",
        type: "comboBox",
        value: ["first"],
        label: "EMPRESAS",
        class: null,
        enabled: true,
        elements: [{},],
        onclick: null,
        toggle: false,
        icon: {
          closed: <i className="fas fa-sort-down"/>,
          opened: <i className="fas fa-sort-up"/>,
        },
      },
      {
        id: "select_provider",
        name: "PRESTADORAS",
        type: "comboBox",
        value: ["first"],
        label: "PRESTADORAS",
        class: null,
        enabled: true,
        elements: [],
        onclick: null,
        toggle: false,
        icon: {
          closed: <i className="fas fa-sort-down"/>,
          opened: <i className="fas fa-sort-up"/>,
        },
      },
      {
        id: "select_pilot",
        name: "PILOTO",
        type: "comboBox",
        value: ["first"],
        label: "PILOTO",
        class: null,
        enabled: true,
        elements: [
          {
            id: "option_pilot_1",
            name: "Escolha uma PRESTADORA...",
            value: "first",
          }
        ],
        onclick: null,
        toggle: false,
        icon: {
          closed: <i className="fas fa-sort-down"/>,
          opened: <i className="fas fa-sort-up"/>,
        },
      },
      {
        id: "input_period",
        name: "PERÍODO",
        type: "period",
        value: {},
        label: "PERÍODO",
        class: null,
        enabled: true,
        elements: [
          {
            id: "input_period_start",
            name: "input_period_start",
            value: "null",
          },
          {
            id: "input_period_end",
            name: "input_period_end",
            value: "null",
          },
        ],
        onclick: null,
        toggle: false,
        icon: {
          closed: <i className="fas fa-sort-down"/>,
          opened: <i className="fas fa-sort-up"/>,
        },
      },
      {
        id: "input_tratments",
        name: "TRATAMENTOS",
        type: "checkBox",
        value: 'tratamentos',
        label: "TRATAMENTOS",
        class: null,
        enabled: true,
        elements: [
          {
            id: "fungicida",
            name: "Fungicida",
            value: "FUNGICIDA",
            class: null,
            checked: true,
            onchange: null,
          },
          {
            id: "inibidor",
            name: "Inibidor",
            value: "INIBIDOR",
            class: null,
            checked: true,
            onchange: null,
          },
          {
            id: "inseticida",
            name: "Inseticida",
            value: "INSETICIDA",
            class: null,
            checked: true,
            onchange: null,
          },
          {
            id: "maturador",
            name: "Maturador",
            value: "MATURADOR",
            class: null,
            checked: true,
            onchange: null,
          },
          {
            id: "preMaturador",
            name: "Pré-Maturador",
            value: "PRÉ MATURADOR",
            class: null,
            checked: true,
            onchange: null,
          },
          {
            id: "herbicida",
            name: "Herbicida",
            value: "HERBICIDA",
            class: null,
            checked: true,
            onchange: null,
          },
          {
            id: "adubacao",
            name: "Aducabação",
            value: "ADUBAÇÃO",
            class: null,
            checked: true,
            onchange: null,
          },
          {
            id: "adubacaoFoliar",
            name: "Adubacação Foliar",
            value: "ADUBAÇÃO FOLIAR",
            class: null,
            checked: true,
            onchange: null,
          },
          {
            id: "naoInformado",
            name: "Não Informado",
            value: "NÃO INFORMADO",
            class: null,
            checked: true,
            onchange: null,
          },],
        onclick: null,
        toggle: false,
        icon: {
          closed: <i className="fas fa-sort-down"/>,
          opened: <i className="fas fa-sort-up"/>,
        },
      },
      {
        id: "input_amount",
        name: "QUANTIDADE",
        type: "amount",
        value: ["50"],
        label: "QUANTIDADE",
        class: null,
        enabled: false,
        elements: [],
        minValue: 1,
        maxValue: 100,
        onclick: null,
        toggle: false,
        icon: {
          closed: <i className="fas fa-sort-down"/>,
          opened: <i className="fas fa-sort-up"/>,
        },
      },
    ];

    const smallStats = [{
      label: "Área de Plantio",
      value: this.props.dashboard.smallStats.somaAreaPlantio,
      percentage: "",
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: true,
      datasets: [{
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: colors.teal.toRGBA(0.1),
        borderColor: colors.teal.toRGBA(),
        data: [5, 15, 10, 25, 20]
      }]
    }, {
      label: "Insumos",
      value: this.props.dashboard.smallStats.somaInsumos,
      percentage: this.props.dashboard.smallStats.percentInsumos + '%',
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [{
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: colors.purple.toRGBA(0.1),
        borderColor: colors.purple.toRGBA(),
        data: [5, 25, 10, 25, 30]
      }]
    }, {
      label: "Área Aplicada",
      value: this.props.dashboard.smallStats.somaAreaAplicada,
      percentage: this.props.dashboard.smallStats.percentAreaAplicada + '%',
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [{
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: colors.cyan.toRGBA(0.1),
        borderColor: colors.cyan.toRGBA(),
        data: [7, 22, 12, 30, 24]
      }]
    }, {
      label: "Área não Aplicada",
      value: this.props.dashboard.smallStats.somaAreaNaoAplicada,
      percentage: this.props.dashboard.smallStats.percentAreaNaoAplicada + '%',
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [{
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: colors.orange.toRGBA(0.1),
        borderColor: colors.orange.toRGBA(),
        data: [7, 21, 14, 35, 28]
      }]
    }, {
      label: "Aplicação Efetiva",
      value: this.props.dashboard.smallStats.somaAplicacaoEfetiva,
      percentage: this.props.dashboard.smallStats.percentAplicacaoEfetiva + '%',
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [{
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: colors.success.toRGBA(0.1),
        borderColor: colors.success.toRGBA(),
        data: [8, 24, 16, 40, 32]
      }]
    }, {
      label: "Aplicação Externa",
      value: this.props.dashboard.smallStats.somaAplicacaoExterna,
      percentage: this.props.dashboard.smallStats.percentAplicacaoExterna + '%',
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [{
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: colors.danger.toRGBA(0.1),
        borderColor: colors.danger.toRGBA(),
        data: [9, 27, 18, 45, 36]
      }]
    }, {
      label: "Aplicação Sobreposta",
      value: this.props.dashboard.smallStats.somaAreaSobreposicao,
      percentage: this.props.dashboard.smallStats.percentAreaSobreposicao + '%',
      increase: true,
      chartLabels: [null, null, null, null, null],
      decrease: false,
      datasets: [{
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: colors.warning.toRGBA(0.1),
        borderColor: colors.warning.toRGBA(),
        data: [10, 30, 20, 50, 40]

      }]
    }];

    const multiReportChart = <Row>
      {/* Histórico de Laudos em Barra */}
      <Col lg="12" md="12" sm="12" className="mb-4">
        {this.props.dashboard.stackChart ? <LogHistoryBar title={"Laudos / Índices"}
                                                          onItemClick={this.props.onFetchPieAndSeriesFromUUID}
                                                          entireChartData={this.props.dashboard.stackChart}/> : null}
      </Col>
    </Row>;

    const toggleChartView = <Row onClick={() => this.props.onToggleSelected()}><Col lg="12" md="12" sm="12"
                                                                                    className="mb-4">
      <Card small className="m-0">
        <CardHeader style={{borderRadius: '0.625rem', cursor: 'pointer'}}>
          <h6 className="m-0">Laudos / Índices </h6>
          <div className="block-handle"/>
        </CardHeader>
      </Card>
    </Col></Row>

    return (
      <Container fluid className="main-content-container px-4 pb-4">
        {this.props.dashboard.ready ? <FloatingFilter userInfo={this.props.userInfo} fields={filt_fields}/> : null}
        <Row noGutters className="page-header py-4">
          <PageTitle title={this.props.userInfo.nomeEmpresa ? this.props.userInfo.nomeEmpresa : "DOMINUS SOLI"}
                     subtitle="Visão Geral" className="text-sm-left mb-3"/>
          {/*<Col sm="4" className="d-flex ml-auto my-auto">*/}
          {/*<RangeDatePicker className="justify-content-end"/>*/}
          {/*</Col>*/}
        </Row>

        {/* Small Stats Blocks */}
        <Row className={"small-stats responsive"}>
          {smallStats.map((stats, idx) => {
            if (idx <= 2) {
              return (<Col key={idx} sm="12" md="12" lg="4" className="mb-4">
                <SmallStats
                  id={`small-stats-${idx}`}
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </Col>)
            } else {
              return (<Col key={idx} sm="12" md="6" lg="3" className="mb-4">
                <SmallStats
                  id={`small-stats-${idx}`}
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </Col>)
            }
          })}
        </Row>

        {!this.props.dashboard.charts.selectedReport.isSelected ? multiReportChart : toggleChartView}

        {/* Histórico de Laudos em Linhas */}
        {/* <Col lg="6" md="12" sm="12" className="mb-4">
         <LogHistoryLine title={"Indices / Laudos"}/>
         </Col>*/}
        {this.props.dashboard.charts.selectedReport.isSelected ? <Row><Col lg="6" md="12" sm="12" className="mb-4">
          <LogInstanceDonut title={"Aplicação / Índices - " + this.props.dashboard.charts.selectedReport.codigo}
                            uuid={this.props.dashboard.charts.selectedReport.uuid}
                            chartValues={this.props.dashboard.charts.pie} onSetHeight={this.setChartsHeight}/>
        </Col>

          {/* Laudo em Lista */}
          <Col lg="6" md="12" sm="12" className="mb-4">
            <LogSeriesBar title={"Indices - OS 4121"} chartValues={this.props.dashboard.charts.series}
                          maxHeight={this.state.chartsHeight}/>
          </Col></Row> : null}
        {/* Laudo em Pizza */}


      </Container>)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onFetchDashboardData: (loading) => dispatch(actions.requestDefaultDashboard(loading)),
    onFetchPieAndSeriesFromUUID: uuid => dispatch(actions.requestPieAndSeriesFromUUID(uuid)),
    onToggleSelected: () => dispatch(actions.toggleIsSelected())
  }
};

const mapStateToProps = state => {
  return {
    dashboard: state.dashboard,
    isLoadingAuth: state.auth.loading,
    userInfo: state.auth.userInfo
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewReactChart);
