import React, {Component} from "react";
import {
	Button, Card, CardHeader, Col, Container, Form, FormCheckbox, FormInput, FormSelect, ListGroup, ListGroupItem, Row
} from "shards-react";
import {connect} from 'react-redux'

import {toastr} from 'react-redux-toastr'

import ProgressBar from '../components/layout/custom-progress-bar/ProgressBar/ProgressBar'
import PageTitle from "../components/common/PageTitle";
import * as actions from '../store/actions'
import validator from "validator";
import contentArrays from "../utils/arrays";

class NewApplicationReport extends Component {
	
  initialState = {
    form: {
      empresa: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isIn: this.props.arrayEmpresas
        }
      },
      tratamento: {
        value: 'NÃO INFORMADO',
        valid: true,
        shouldValidate: true,
        touched: false,
        validator: {
          isIn: contentArrays.tratamentos
        }
      },
      cultura: {
        value: 'NÃO INFORMADA',
        valid: true,
        shouldValidate: true,
        touched: false,
        validator: {
          isIn: contentArrays.culturas
        }
      },
      prestadora: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isInObjArray: 'prestadoras'
        }
      },
      piloto: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          flag: 'pilotos'
        }
      },
      data: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isDate: true
        }
      },
      nomeExibicao: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        name: 'nome',
        validator: {
          isLength: 3
        },
      },
      codigo: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 3
        }
      },
      insumos: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isFloat: true
        }
      },
      distanciaPista: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isFloat: true
        }
      },
      faixaContratada: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isFloat: true
        }
      },
      faixaExecutada: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isFloat: true
        }
      },
      vazaoContratada: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isFloat: true
        }
      },
      arquivoArea: {
        value: 'Escolha um arquivo...',
        valid: false,
        shouldValidate: true,
        touched: false,
        store: false,
        name: 'file_area',
        validator: {
          isFile: true
        }
      },
      arquivoAplicacao: {
        value: 'Escolha um arquivo...',
        valid: false,
        shouldValidate: true,
        touched: false,
        store: false,
        name: 'file_aplicacao',
        validator: {
          isFile: true
        }
      },
      arquivoTrajeto: {
        value: 'Escolha um arquivo...',
        valid: false,
        shouldValidate: true,
        touched: false,
        store: false,
        name: 'file_trajeto',
        validator: {
          isFile: true
        }
      }
    }
  };
  
	state = {...this.initialState};
	objectPrestadoras = {};
	
	componentDidMount() {
		this.props.onLoadCompaniesSimpleList();
		this.props.onLoadProvidersSimpleList();
	}
	
	onFormSendHandler = e => {
		e.preventDefault();
		let counter = 0, validCounter = 0;
		let invalid = '';
		for (let key in this.state.form) {
			if (this.state.form.hasOwnProperty(key)) {
				if (this.state.form[key].valid) {
					validCounter++;
				} else {
				  invalid = key;
        }
				counter++;
			}
		}
		if (validCounter !== counter) {
			return toastr.warning('Não Enviado', 'Verifique as informações e tente novamente - [' + invalid + ']', {position: 'top-right'});
    }
		let formData = new FormData();
		for (let key in this.state.form) {
			if (this.state.form.hasOwnProperty(key)) {
				let itemName = key;
				if (this.state.form[key].name) {
					itemName = this.state.form[key].name
				}
				if (this.state.form[key].store) {
					formData.append(itemName,
						this.state.form[key].store)
				} else {
					formData.append(itemName,
						this.state.form[key].value);
				}
				console.log(itemName,
					this.state.form[key]);
			}
		}
		
		this.props.onSendNewApplicationReport(formData, () => {
		  this.setState({...this.state, ...this.initialState});
		  const inputsList = document.getElementsByTagName('input');
		  const selectsList = document.getElementsByTagName('select');
		  
		  console.log('INPUTS: ', inputsList)
		  
      for (let input of inputsList) {
        input.value = ''
      }
		
      for (let select of selectsList) {
        select.selectedIndex = 0;
      }
      
		  // inputsArray.forEach(input => {
		  //   input.value = ''
      // })
    });
    toastr.info('Cadastrando...', 'Enviando laudo para o sistema...', {position: 'top-right'});
		
	};
	
	inputChangedHandler(e, key) {
		console.log(key);
		console.log(this.props.misc);
		let obj = {...this.state.form[key]};
		let incomingValue = e.target.value;
		
		let valid = true;
		
		if (obj.validator) {
			if (obj.validator.isInObjArray) {
				this.objectPrestadoras = this.props.misc.prestadoras.reduce((arr, cur, i) => {
						const {id, nome, pilotos} = cur;
						console.log(id,
							nome,
							pilotos,
							cur);
						arr[id] = {
							nome: nome,
							pilotos: pilotos
						};
						return arr
					},
					{});
				valid = this.objectPrestadoras[incomingValue] && valid;
			}
			if (obj.validator.isEmail) {
				valid = validator.isEmail(incomingValue) && valid;
			}
			if (obj.validator.isLength) {
				valid = validator.isLength(incomingValue,
					{min: obj.validator.isLength}) && valid;
			}
			if (obj.validator.equals) {
				valid = validator.equals(incomingValue,
					obj.validator.equals) && valid;
			}
			if (obj.validator.isIn) {
				valid = validator.isIn(incomingValue,
					obj.validator.isIn) && valid;
			}
			if (obj.validator.isFloat) {
				valid = validator.isFloat(incomingValue) && valid;
			}
			if (obj.validator.isDate) {
				valid = new Date(incomingValue) < new Date() && valid;
			}
			if (obj.validator.isFile) {
				//incomingValue = incomingValue.files[0].name
				valid = e.target.files.length === 1 && valid;
				if (valid) {
					incomingValue = e.target.files[0].name;
					const splitValue = incomingValue.split('.');
					if (splitValue[splitValue.length - 1].toLowerCase() !== 'kml') {
						valid = false;
						incomingValue = 'Escolha um arquivo...'
					} else {
						obj.store = e.target.files[0]
            console.log(obj.store.size);
            if (key === 'arquivoArea' && this.state.form.arquivoAplicacao.store) {
              if (obj.store.size >= this.state.form.arquivoAplicacao.store.size)  {
                obj = {...this.initialState.form.arquivoArea};
                valid = false;
                incomingValue = 'Escolha um arquivo...'
                toastr.warning('Atenção', 'Certifique-se de colocar os arquivos em seus devidos campos', {position: 'top-right', timeout: '3000'})
              }
            }else if (key === 'arquivoAplicacao' && this.state.form.arquivoArea.store) {
              if (obj.store.size <= this.state.form.arquivoArea.store.size)  {
                obj = {...this.initialState.form.arquivoAplicacao};
                valid = false;
                incomingValue = 'Escolha um arquivo...'
  
                toastr.warning('Atenção', 'Certifique-se de colocar os arquivos em seus devidos campos', {position: 'top-right', timeout: '3000'})
              }
            }
					}
				} else {
					incomingValue = 'Escolha um arquivo...'
				}
			}
		}
		
		obj.valid = valid;
		obj.value = incomingValue;
		obj.touched = true;
		
		console.log(obj);
		
		this.setState(oldState => ({
			form: {
				...oldState.form,
				[key]: obj
			}
		}))
	}
	
	render() {
		
		let pilotos = [];
		
		if (this.props.misc.prestadoras && this.state.form.prestadora.valid) {
			pilotos = this.objectPrestadoras[this.state.form.prestadora.value].pilotos
		}
		
		return (<Container fluid className="main-content-container px-4">
			<Row noGutters className="page-header py-4">
				<PageTitle sm="4" title="Novo Laudo" subtitle="Cadastro" className="text-sm-left"/>
			</Row>
			<Row>
				<Col>
					<Card small>
						<CardHeader className="border-bottom">
							<h6 className="m-0">Dados Cadastrais</h6>
						</CardHeader>
						
						
						<ListGroup flush>
							<ListGroupItem className="p-3">
								<Row>
									<Col>
										<Form>
											<Row form>
												<Col md="4" className="form-group">
													<label htmlFor="inputSelectEmpresa">Empresa</label>
													<FormSelect
														id="inputSelectEmpresa"
														invalid={!this.state.form.empresa.valid && this.state.form.empresa.touched && this.state.form.empresa.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'empresa')}>
														<option>Carregando...</option>
														{this.props.misc.empresas ? this.props.misc.empresas.map(el => (
															<option value={el.id}
																	key={el.id}>{el.nome}</option>)) : null}
													</FormSelect>
												</Col>
												<Col md="4" className="form-group">
													<label htmlFor="inputSelectTratamento">Tratamento</label>
													<FormSelect
														id="inputSelectTratamento"
														invalid={!this.state.form.tratamento.valid && this.state.form.tratamento.touched && this.state.form.tratamento.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'tratamento')}>
														{contentArrays.tratamentos.map((element, index) => (
															<option key={index} value={element}>{element}</option>))}
													</FormSelect>
												</Col>
												<Col md="4" className="form-group">
													<label htmlFor="inputSelectCultura">Cultura</label>
													<FormSelect
														id="inputSelectCultura"
														invalid={!this.state.form.cultura.valid && this.state.form.cultura.touched && this.state.form.cultura.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'cultura')}>
														{contentArrays.culturas.map((element, index) => (
															<option key={index} value={element}>{element}</option>))}
													</FormSelect>
												</Col>
											</Row>
											<Row form>
												<Col md="4" className="form-group">
													<label htmlFor="inputSelectPrestadora">Prestadora</label>
													<FormSelect
														id="inputSelectPrestadora"
														invalid={!this.state.form.prestadora.valid && this.state.form.prestadora.touched && this.state.form.prestadora.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'prestadora')}>
														<option>Escolha...</option>
														{this.props.misc.prestadoras ? this.props.misc.prestadoras.map(element => (
																<option value={element.id}
																		key={element.id}>{element.nome}</option>)) :
															<option>Carregando...</option>}
													</FormSelect>
												</Col>
												<Col md="4" className="form-group">
													<label htmlFor="inputSelectPiloto">Piloto</label>
													<FormSelect id="inputSelectPiloto"
																invalid={!this.state.form.piloto.valid && this.state.form.piloto.touched && this.state.form.piloto.shouldValidate}
																onChange={(event) => this.inputChangedHandler(event,
																	'piloto')}>
														<option>Escolha...</option>
														{pilotos.map(element => (<option value={element.id}
																						 key={element.id}>{element.nome}</option>))}
													</FormSelect>
												</Col>
												<Col md="4" className="form-group">
													<label htmlFor="inputSelectData">Data</label>
													<input type="date"
														   id="inputSelectData"
														   className={(!this.state.form.data.valid && this.state.form.data.touched && this.state.form.data.shouldValidate) ? "form-control is-invalid" : "form-control"}
														   onChange={(event) => this.inputChangedHandler(event,
															   'data')}
													/>
												</Col>
											</Row>
											
											<Row form>
												<Col md="6" className="form-group">
													<label htmlFor="inputNomeExibicao">Nome de Exibição</label>
													<FormInput
														id="inputNomeExibicao"
														placeholder="OS 1234 - Santa Maria"
														invalid={!this.state.form.nomeExibicao.valid && this.state.form.nomeExibicao.touched && this.state.form.nomeExibicao.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'nomeExibicao')}/>
												</Col>
												<Col md="6" className="form-group">
													<label htmlFor="inputCodigo">Codigo</label>
													<FormInput
														id="inputCodigo"
														type="text"
														placeholder="OS 1234"
														invalid={!this.state.form.codigo.valid && this.state.form.codigo.touched && this.state.form.codigo.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'codigo')}/>
												</Col>
											</Row>
											
											<Row form>
												<Col md="3" className="form-group">
													<label htmlFor="inputDistanciaPista">Distancia Pista</label>
													<FormInput
														id="inputDistanciaPista"
														type="text"
														placeholder="0.00"
														invalid={!this.state.form.distanciaPista.valid && this.state.form.distanciaPista.touched && this.state.form.distanciaPista.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'distanciaPista')}/>
												</Col>
												<Col md="2" className="form-group">
													<label htmlFor="inputFaixaContratada">Faixa Contratada</label>
													<FormInput
														id="inputFaixaContratada"
														type="text"
														placeholder="0.00"
														invalid={!this.state.form.faixaContratada.valid && this.state.form.faixaContratada.touched && this.state.form.faixaContratada.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'faixaContratada')}/>
												</Col>
												<Col md="2" className="form-group">
													<label htmlFor="inputFaixaExecutada">Faixa Executada</label>
													<FormInput
														id="inputFaixaExecutada"
														type="text"
														placeholder="0.00"
														invalid={!this.state.form.faixaExecutada.valid && this.state.form.faixaExecutada.touched && this.state.form.faixaExecutada.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'faixaExecutada')}/>
												</Col>
												<Col md="2" className="form-group">
													<label htmlFor="inputVazaoContratada">Vazão Contratada</label>
													<FormInput
														id="inputVazaoContratada"
														type="number"
														step="0.1"
														placeholder="0.00"
														invalid={!this.state.form.vazaoContratada.valid && this.state.form.vazaoContratada.touched && this.state.form.vazaoContratada.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'vazaoContratada')}
													/>
												</Col>
												<Col md="3" className="form-group">
													<label htmlFor="inputInsumos">Insumos</label>
													<FormInput
														id="inputInsumos"
														placeholder="0.00"
														invalid={!this.state.form.insumos.valid && this.state.form.insumos.touched && this.state.form.insumos.shouldValidate}
														onChange={(event) => this.inputChangedHandler(event,
															'insumos')}/>
												</Col>
											</Row>
											
											<hr/>
											
											<Row form>
												<Col md="4" className="form-group">
													<label htmlFor="">Traçado da Área (.kml)</label>
													<div className="custom-file mb-3">
														<input type="file"
															   className={(!this.state.form.arquivoArea.valid && this.state.form.arquivoArea.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
															   onChange={(event) => this.inputChangedHandler(event,
																   'arquivoArea')}/>
														<label className="custom-file-label" htmlFor="arquivoArea">
															{this.state.form.arquivoArea.value}
														</label>
													</div>
												</Col>
												<Col md="4" className="form-group">
													<label htmlFor="">Aplicação (.kml)</label>
													<div className="custom-file mb-3">
														<input type="file" id="arquivoAplicacao"
															   className={(!this.state.form.arquivoAplicacao.valid && this.state.form.arquivoAplicacao.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
															   onChange={(event) => this.inputChangedHandler(event,
																   'arquivoAplicacao')}/>
														<label className="custom-file-label" htmlFor="arquivoAplicacao">
															{this.state.form.arquivoAplicacao.value}
														</label>
													</div>
												</Col>
												<Col md="4" className="form-group">
													<label htmlFor="">Trajeto (.kml)</label>
													<div className="custom-file mb-3">
														<input type="file" id="arquivoTrajeto"
															   className={(!this.state.form.arquivoTrajeto.valid && this.state.form.arquivoTrajeto.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
															   onChange={(event) => this.inputChangedHandler(event,
																   'arquivoTrajeto')}/>
														<label className="custom-file-label" htmlFor="arquivoTrajeto">
															{this.state.form.arquivoTrajeto.value}
														</label>
													</div>
												</Col>
											</Row>
											
											<Row form>
												<Col md="6" className="form-group">
													<FormCheckbox disabled>
														{/*/!* eslint-disable-next-line *!/Notificar ao finalizar processamento.*/}
													</FormCheckbox>
												</Col>
											</Row>
											<Button type="submit" theme="success" disabled={!this.props.sentRequest}
													onClick={event => this.onFormSendHandler(event)}>Cadastrar</Button>
											<small style={{float: "right"}}>Obs: Caso a empresa também tenha acesso ao
												sistema ambiental,
												surgirá um formulário adicional.
											</small>
										
										</Form>
									</Col>
								</Row>
							</ListGroupItem>
						</ListGroup>
					
					
					</Card>
				</Col>
			</Row>
		</Container>)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		onSendNewApplicationReport: (formData, clearForm) => dispatch(actions.sendNewApplicationReport(formData, clearForm)),
		onLoadCompaniesSimpleList: () => dispatch(actions.requestCompaniesSimpleList()),
		onLoadProvidersSimpleList: () => dispatch(actions.requestProvidersSimpleList())
	}
};
const mapStateToProps = state => {
	return {
		misc: state.misc,
    sentRequest: state.progressBar.percentage === 0
	}
};

export default connect(mapStateToProps,
	mapDispatchToProps)(
	NewApplicationReport);
