import React, {Component} from 'react'
import dateFormat from "dateformat";
import FuzzySearch from "fuzzy-search";
import {
  Card, CardBody,
  CardHeader,
  Col,
  Container, FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import ReactTable from "react-table";

class AreaProtecaoLista extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // Data Tabale
      tableData: [
        {
          "date": "2019-01-01",
          "classificacao": "cidade",
          "estado": "São Paulo",
          "municipio": "São João da Boa Vista",
          "identificacaoProprietario": "São João da Boa Vista",
          "contato": "http://www.saojoao.sp.gov.br/",
        },
        {
          "date": "2019-02-04",
          "classificacao": "manancial",
          "estado": "Minas Gerais",
          "municipio": "Poços de Caldas",
          "identificacaoProprietario": "Rio São Pedro",
          "contato": "https://pocosdecaldas.mg.gov.br/",
        },
        {
          "date": "2019-03-09",
          "classificacao": "redeEletrica",
          "estado": "São Paulo",
          "municipio": "Águas da Prata",
          "identificacaoProprietario": "ELECTRO - Águas da Prata",
          "contato": "0800 97500-0034",
        },
        {
          "date": "2019-04-16",
          "classificacao": "vila",
          "estado": "São Paulo",
          "municipio": "Santo Agostinho",
          "identificacaoProprietario": "Santo Agostinho",
          "contato": "santoagostinho@contato.com",
        },
        {
          "date": "2019-05-25",
          "classificacao": "moradiaIsolada",
          "estado": "Minas Gerais",
          "municipio": "Poços de Caldas",
          "identificacaoProprietario": "José de Almeida Machado",
          "contato": "(19) 98761-4545",
        },
      ],
      //Columns Table
      columns: [
        {
          Header: "Data",
          accessor: "date",
          className: "text-center",
          minWidth: 150,
          Cell: row => {
            let date = new Date(row.original.date);
            date.setDate(date.getDate() + 1);
            return dateFormat(date, "dd/mm/yyyy")
          },
          Filter: ({filter, onChange}) =>
            <input
              type={"date"}
              className={"text-center w-auto"}
              onChange={event => onChange(event.target.value)}
              value={filter ? filter.value : "all"}>
            </input>,
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            } else {
              return row[filter.id] === filter.value;
            }
          },
        },
        {
          Header: "Classificação",
          accessor: "classificacao",
          minWidth: 150,
          className: "text-center",
          Cell: row => {
            let text_class;

            if (row.original.classificacao === "povoacao") {
              text_class = "POVOAÇÃO";
            }
            if (row.original.classificacao === "cidade") {
              text_class = "CIDADE";
            }
            if (row.original.classificacao === "vila") {
              text_class = "VILA";
            }
            if (row.original.classificacao === "bairro") {
              text_class = "BAIRRO";
            }
            if (row.original.classificacao === "manancialCaptacao") {
              text_class = "MANANCIAL DE CAPTAÇÃO";
            }
            if (row.original.classificacao === "manancial") {
              text_class = "MANANCIAL";
            }
            if (row.original.classificacao === "moradiaIsolada") {
              text_class = "MORADIA ISOLADA";
            }
            if (row.original.classificacao === "agrupamentoAnimais") {
              text_class = "AGRUPAMENTO DE ANIMAIS";
            }
            if (row.original.classificacao === "urucum") {
              text_class = "URUCUM";
            }
            if (row.original.classificacao === "bichoSeda") {
              text_class = "BICHO DA SEDA";
            }
            if (row.original.classificacao === "culturaVizinha") {
              text_class = "CULTURA VIZINHA.";
            }
            if (row.original.classificacao === "app") {
              text_class = "APP";
            }
            if (row.original.classificacao === "reservaLegal") {
              text_class = "RESERVA LEGAL";
            }
            if (row.original.classificacao === "redeEletrica") {
              text_class = "REDE ELÉTRICA";
            }
            if (row.original.classificacao === "torre") {
              text_class = "TORRE";
            }

            return (<span>{text_class}</span>);
          },
          Filter: ({filter, onChange}) =>
            <select
              onChange={event => onChange(event.target.value)}
              style={{width: "100%"}}
              value={filter ? filter.value : "all"}>
              <option value="all">Todas</option>
              <option value="povoacao">Povoação</option>
              <option value="cidade">Cidade</option>
              <option value="vila">Vila</option>
              <option value="bairro">Bairro</option>
              <option value="manancialCaptacao">Manancial de Captação</option>
              <option value="manancial">Manancial</option>
              <option value="moradiaIsolada">Moradia Isolada</option>
              <option value="agrupamentoAnimais">Agrupamento de Animais</option>
              <option value="urucum">Urucum</option>
              <option value="bichoSeda">Bicho da Seda</option>
              <option value="culturaVizinha">Cultura Vizinha</option>
              <option value="app">APP</option>
              <option value="reservaLegal">Reserva Legal</option>
              <option value="redeEletrica">Rede Elétrica</option>
              <option value="torre">Torre</option>
            </select>,
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            }
            return row[filter.id] === filter.value;
          }
        },
        {
          Header: "Estado",
          accessor: "estado",
          minWidth: 150,
          className: "text-center",
        },
        {
          Header: "Município",
          accessor: "municipio",
          minWidth: 150,
          className: "text-center",
        },
        {
          Header: "Identificação do Proprietário",
          accessor: "identificacaoProprietario",
          minWidth: 150,
          className: "text-center",
        },
        {
          Header: "Contato",
          accessor: "contato",
          className: "text-center",
          minWidth: 150,
        },
      ],
      // Control Table
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      sorted: [],
      expanded: {},
      resized: [],
      filtered: [],
    };

    this.searcher = null;

    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleItemView = this.handleItemView.bind(this);

  };

  componentWillMount() {
  };

  componentDidMount() {
    this.searcher = new FuzzySearch(this.state.tableData, [
      "classificacao",
      "estado",
      "municipio",
      "identificacaoProprietario"
    ], {
      caseSensitive: false
    });
  };

  /**
   * Handles the page size change event.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }

  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {
    this.setState({
      ...this.state,
      tableData: this.searcher.search(e.target.value)
    });
  }

  /**
   * Mock method for details log.
   */
  handleItemView(row) {
    this.props.history.push();
  }

  render() {

    let {tableData, columns, pageSize, pageSizeOptions, sorted, expanded, resized, filtered} = this.state;

    return (

      <Container fluid className="main-content-container px-4 pb-4">

        <Row noGutters className="page-header py-4">
          <PageTitle title="ÁREAS DE PROTEÇÃO" subtitle="Tabelas" className="text-sm-left mb-3"/>
          {/*<Col sm="4" className="d-flex ml-auto my-auto">*/}
          {/*<RangeDatePicker className="justify-content-end"/>*/}
          {/*</Col>*/}
        </Row>

        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>

                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="4">
                  <span>Exibir</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}>
                    {pageSizeOptions.map((size, idx) => (
                      <option key={idx} value={size}>
                        {size} linhas
                      </option>
                    ))}
                  </FormSelect>
                </Col>

                <Col className="d-flex" md="4" style={{textAlign: "center"}}>
                  <em>
                    <small>Obs: Mantenha "Shift" pressionado para ordenar mais de uma coluna.</small>
                  </em>
                </Col>

                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="4">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch}/>
                  </InputGroup>
                </Col>

              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                id={"sp-reports-history-table"}
                // Data
                columns={columns}
                data={tableData}
                // Style
                style={{height: "605px"}}
                // Text
                previousText={'Anterior'}
                nextText={'Próximo'}
                loadingText={'Carregando dados...'}
                noDataText={'Nenhum informação encontrada! :('}
                pageText={'Página'}
                ofText={'de'}
                rowsText={'linhas'}
                // Accessibility Labels
                pageJumpText={'jump to page'}
                rowsSelectorText={'rows per page'}
                // Controlled table
                pivotBy={[]}
                filterable={true}
                resizable={true}
                showPageSizeOptions={false}
                defaultPageSize={this.state.pageSize}
                pageSize={pageSize}
                showPageJump={true}
                loading={false}
                collapseOnSortingChange={true}
                collapseOnPageChange={true}
                collapseOnDataChange={true}
                multiSort={true}
                // Controlled props
                expanded={expanded}
                resized={resized}
                filtered={filtered}
                sorted={sorted}
                className={"-shadow"}
                // Callbacks
                onSortedChange={sorted => this.setState({sorted})}
                onPageChange={page => this.setState({page})}
                onPageSizeChange={(pageSize, page) => this.setState({page, pageSize})}
                onExpandedChange={expanded => this.setState({expanded})}
                onResizedChange={resized => this.setState({resized})}
                onFilteredChange={filtered => this.setState({filtered})}
              />
            </div>
          </CardBody>
        </Card>

      </Container>

    );

  }

}

export default AreaProtecaoLista;
