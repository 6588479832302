import React from "react";
import PropTypes from "prop-types";
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
} from "shards-react";

import Chart from "../../utils/chart";
import colors from "../../utils/colors.js"

class EffectivenessReportPieChart extends React.Component {

    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();
        this.chart = undefined;
    }

    componentDidMount() {

        console.log("EffectivenessReportPieChart [componentDidMount]");

        const chartConfig = {
            type: "pie",
            data: this.props.chartData,
            options: {
                ...{
                    legend: false,
                    cutoutPercentage: 0,
                    tooltips: {
                        enabled: false,
                        mode: "index",
                        position: "nearest"
                    }
                },
                ...this.props.chartOptions
            }
        };

        this.chart = new Chart(this.canvasRef.current, chartConfig);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps !== this.props) {

            console.log("EffectivenessReportPieChart [componentDidUpdate]");

            this.chart.data = this.props.chartData;
            this.chart.update();

        }
    }

    render() {

        console.log("EffectivenessReportPieChart [render]");

        const {title, id} = this.props;
        const labels = this.getParsedLabels();

        return (
            <Card id={id} small className="h-100 shadow-none">
                {title ? (
                    <CardHeader className="border-bottom">
                        <h6 className="m-0">{title}</h6>
                        <div className="block-handle"/>
                    </CardHeader>
                ) : null
                }

                <CardBody className="d-flex py-0">
                    {/*Gráfico de Pizza*/}
                    <canvas
                        height="220"
                        ref={this.canvasRef}
                        className="blog-users-by-device m-auto"
                    />
                </CardBody>

                <CardFooter>
                    {/* Legenda do Gráfico */}
                    <div className="ubd-stats__legend w-75 m-auto pb-4">
                        {labels.map((label, idx) => (
                            <div key={idx} className="ubd-stats__item">
                                {label.icon && (
                                    <span
                                        dangerouslySetInnerHTML={{__html: label.icon}}
                                        style={{color: label.iconColor, cursor: "pointer"}}
                                        onClick={(e) => this.customClickLegend(e, idx)}
                                    />
                                )}
                                <span className="ubd-stats__category">{label.title}</span>
                                <span className="ubd-stats__value">{label.value} <span style={{
                                    fontSize: "0.7rem ",
                                    color: colors.reagentGray.toRGBA(1)
                                }}>({label.porcent}%)</span></span>
                            </div>
                        ))}
                    </div>
                </CardFooter>
            </Card>
        );
    }

    getParsedLabels() {
        const {chartData} = this.props;

        if (!chartData.labels || typeof chartData.labels === "undefined") {
            return [];
        }

        return chartData.labels.map((label, idx) => {
            const dataset = chartData.datasets[0];

            let total = 0;
            // eslint-disable-next-line array-callback-return
            dataset.data.map((value) => {
                total += value;
            });

            return {
                title: label,
                icon: dataset.icons[idx],
                iconColor: dataset.backgroundColor[idx],
                value: dataset.data[idx],
                porcent: parseFloat((dataset.data[idx] * 100 / total).toFixed(2))

            };
        });
    }

    customClickLegend(e, index) {

        var ci = this.chart;
        var meta = ci.getDatasetMeta(0).data[index];

        // See controller.isDatasetVisible comment
        meta.hidden = meta.hidden === null ? true : !ci.getDatasetMeta(0).data[index].hidden;

        // We hid a dataset ... rerender the chart
        ci.update();
    }
}

EffectivenessReportPieChart.propTypes = {
    /**
     * The component's id.
     */
    id: PropTypes.string,
    /**
     * The component's title.
     */
    title: PropTypes.string,
    /**
     * The chart config object.
     */
    chartConfig: PropTypes.object,
    /**
     * The Chart.js options.
     */
    chartOptions: PropTypes.object,
    /**
     * The chart data.
     */
    chartData: PropTypes.object,
    /**
     * The chart labels.
     */
    labels: PropTypes.array
};

EffectivenessReportPieChart.defaultProps = {
    id: undefined,
    title: undefined,
    chartConfig: Object.create(null),
    chartOptions: Object.create(null),
    chartData: {
        labels: ["Alta Efet.", "Média Efet.", "Baixa Efet."],
        datasets: [
            {
                hoverBorderColor: colors.white.toRGBA(1),
                hoverBackgroundColor: [
                    colors.green_default_2.toRGBA(0.8),
                    colors.yellow_default_2.toRGBA(0.8),
                    colors.red_default_2.toRGBA(0.8)
                ],
                data: [33, 33, 33],
                icons: [
                    '<i class="fas fa-circle"/>',
                    '<i class="fas fa-circle"/>',
                    '<i class="fas fa-circle"/>'
                ],
                backgroundColor: [
                    colors.green_default_1.toRGBA(1),
                    colors.yellow_default_1.toRGBA(1),
                    colors.red_default_1.toRGBA(1)
                ]
            }
        ],
    },
};

export default EffectivenessReportPieChart;