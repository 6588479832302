import React, {Component} from "react";
import {
  Button, Card, Col, Container, Form, FormCheckbox, FormInput, FormRadio, FormSelect, ListGroup, ListGroupItem, Row
} from "shards-react";
import {connect} from 'react-redux'

import PageTitle from "../components/common/PageTitle";
import * as actions from '../store/actions'
import contentArrays from "../utils/arrays";


import validator from 'validator'

class NewUser extends Component {

  state = {
    form: {
      nivel: {
        value: 'programacao',
        valid: false,
        shouldValidate: false,
        touched: false
      },
      nome: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 3
        }
      },
      email: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isEmail: true
        }
      },
      senha: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isLength: 6
        }
      },
      confirmarSenha: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          equals: 'senha'
        }
      },
      regiao: {
        value: [],
        valid: false,
        shouldValidate: false,
        touched: false,
      },
    }
  };

  componentDidMount() {
    this.props.onLoadCompaniesSimpleList();
    this.props.onLoadProvidersSimpleList();
  }

  onFormSendHandler = e => {
    e.preventDefault();
    let formData = new FormData();
    for (let key in this.state.form) {
      if (this.state.form.hasOwnProperty(key)) {
        let itemName = key;
        if (this.state.form[key].name) {
          itemName = this.state.form[key].name
        }
        if (this.state.form[key].store) {
          formData.append(itemName, this.state.form[key].store)
        } else {
          formData.append(itemName, this.state.form[key].value);
        }
        console.log(itemName, this.state.form[key]);
      }
    }
    this.props.onSendNewUser(formData);

  };

  inputChangedHandler(e, key) {
    console.log(key);
    const obj = {...this.state.form[key]};
    const incomingValue = e.target.value;

    let valid = true;

    if (obj.validator) {

      if (obj.validator.isEmail) {
        valid = validator.isEmail(incomingValue) && valid;
      }
      if (obj.validator.isLength) {
        valid = validator.isLength(incomingValue,
          {min: obj.validator.isLength}) && valid;
      }
      if (obj.validator.equals) {
        valid = validator.equals(incomingValue,
          this.state.form[obj.validator.equals].value) && valid;
      }
      if (obj.validator.isIn) {
        valid = validator.isIn(incomingValue,
          obj.validator.isIn) && valid;
      }
      if (obj.validator.isInt) {
        valid = validator.isInt(incomingValue) && valid;
      }

    }

    obj.valid = valid;
    obj.value = incomingValue;
    obj.touched = true;

    console.log(obj);

    this.setState(oldState => ({
      form: {
        ...oldState.form,
        [key]: obj
      }
    }))
  }

  render() {
    let mapRegioesCadastradas;
    if (this.props.misc.empresas) {
      const regioesCadastradas = this.props.misc.empresas.filter(el => el.nome.match(/\[\w+\]/));
      mapRegioesCadastradas = regioesCadastradas.map((el, idx) => <FormCheckbox checked={this.state.form.regiao.value.includes(el.nome.match(/(\[\w+\])/)[1])} value={el.nome.match(/\[(\w+)\]/)[1]} onChange={e => this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          regiao: {
            value: this.state.form.regiao.value.includes(el.nome.match(/(\[\w+\])/)[1]) ? this.state.form.regiao.value.filter(elm => elm !== el.nome.match(/(\[\w+\])/)[1]) : [...this.state.form.regiao.value, el.nome.match(/(\[\w+\])/)[1]]
          }
        }
      }) } inline name="regiao" key={el.id}>{el.nome.match(/\[(\w+)\]/)[1]}</FormCheckbox>)
    } else {
      mapRegioesCadastradas = "Carregando..."
    }
    
    const programacao = (
      <Col md="12" className="form-group text-center">
        <label htmlFor="inputRegiao">Regiões</label>
        <fieldset aria-required={true}>
          {mapRegioesCadastradas}
        </fieldset>
      </Col>
    )


    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <Row noGutters className="page-header py-4">
          <PageTitle sm="4" title="Novo Usuário Adecoagro" subtitle="Cadastro" className="text-sm-left"/>
        </Row>

        <Row>
          <Col>
            <Card small>
              <ListGroup flush>
                <ListGroupItem>
                  <Row>
                    <Col>
                      <Form>
                        <ListGroupItem className="p-3">

                          <h6 className="m-0 pb-3">Dados Gerais</h6>
                          
                          <Row form>
                            <Col className="text-center pb-3">
                              <fieldset aria-required={true} onChange={event => this.inputChangedHandler(event,
                                'nivel')}>
                                <FormRadio inline name="nivel" value="programacao" defaultChecked>Programação</FormRadio>
                                <FormRadio inline name="nivel" value="coordenador">Coordenador</FormRadio>
                                <FormRadio inline name="nivel" value="gerente">Gerente</FormRadio>
                              </fieldset>
                            </Col>
                          </Row>
  
                          {this.state.form.nivel.value !== "gerente" ? programacao : null}
  
  
                          <Row form>
                            <Col md="6" className="form-group">
                              <label htmlFor="inputNome">Nome</label>
                              <FormInput
                                id="inputNome"
                                placeholder="John Doe"
                                invalid={!this.state.form.nome.valid && this.state.form.nome.touched && this.state.form.nome.shouldValidate}
                                onChange={event => this.inputChangedHandler(event,
                                  'nome')}/>
                            </Col>
                            <Col md="6" className="form-group">
                              <label htmlFor="inputEmail">Email</label>
                              <FormInput id="inputEmail" type="email" placeholder="pedro@dominio.com"
                                         invalid={!this.state.form.email.valid && this.state.form.email.touched && this.state.form.email.shouldValidate}
                                         onChange={event => this.inputChangedHandler(event,
                                           'email')}/>
                            </Col>
                          </Row>
                          <Row form>
                            <Col md="6" className="form-group">
                              <label htmlFor="inputSenha">Senha</label>
                              <FormInput id="inputSenha" type="password" placeholder="Contendo no mínimo 6 caracteres"
                                         invalid={!this.state.form.senha.valid && this.state.form.senha.touched && this.state.form.senha.shouldValidate}
                                         onChange={event => this.inputChangedHandler(event,
                                           'senha')}/>
                            </Col>
                            <Col md="6" className="form-group">
                              <label htmlFor="inputConfirmarSenha">Confirmar Senha</label>
                              <FormInput id="inputConfirmarSenha" type="password"
                                         placeholder="Contendo no mínimo 6 caracteres"
                                         invalid={!this.state.form.confirmarSenha.valid && this.state.form.confirmarSenha.touched && this.state.form.confirmarSenha.shouldValidate}
                                         onChange={event => this.inputChangedHandler(event,
                                           'confirmarSenha')}/>
                            </Col>
                          </Row>
                        </ListGroupItem>
                        <Row>
                          <Col className="mt-4">
                            <Button type="submit" theme="success"
                                    onClick={event => this.onFormSendHandler(event)}>Cadastrar</Button>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>)
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onLoadCompaniesSimpleList: () => dispatch(actions.requestCompaniesSimpleList()),
    onLoadProvidersSimpleList: () => dispatch(actions.requestProvidersSimpleList()),
    onSendNewUser: (formData) => dispatch(actions.sendNewAmbientalUser(formData))
  }
};
const mapStateToProps = state => {
  return {
    misc: state.misc
  }
};

export default connect(mapStateToProps,
  mapDispatchToProps)(
  NewUser);
