import React, {Component} from 'react'

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Modal,
  ModalFooter,
  Button,
  ModalBody,
  ModalHeader,
  FormCheckbox, Tooltip
} from "shards-react";

import classes from './ExcelSelectColumns.module.css'
import ReactExcel from "../export-formats/ExportDataToExcel"
import ReactPDF from "../export-formats/ExportTableToPDF"

class ExcelSelectColumns extends Component {
  
  state = {
    columns: {
      codigo: {
        name: "Código",
        visible: true,
        key: 'codigo'
      },
      nome: {
        name: "Nome",
        visible: true,
        key: 'nome'
      },
      tratamento: {
        name: "Tratamento",
        visible: true,
        key: 'tratamento'
      },
      cultura: {
        name: "Cultura",
        visible: true,
        key: 'cultura'
      },
      prestadora: {
        name: "Prestadora",
        visible: true,
        key: 'prestadora'
      },
      piloto: {
        name: "Piloto",
        visible: true,
        key: 'piloto'
      },
      data: {
        name: "Data da Aplicação",
        visible: true,
        key: 'data'
      },
      empresa: {
        name: "Empresa",
        visible: false,
        key: 'empresa'
      },
      createdAt: {
        name: "Data de Registro",
        visible: false,
        key: 'createdAt'
      },
      distanciaPista: {
        name: "Distância da Pista (km)",
        visible: false,
        key: 'distanciaPista'
      },
      faixaContratada: {
        name: "Faixa Contratada (ha)",
        visible: false,
        key: 'faixaContratada'
      },
      faixaExecutada: {
        name: "Faixa Executada (ha)",
        visible: false,
        key: 'faixaExecutada'
      },
      larguraFaixa: {
        name: "Largura da Faixa (m)",
        visible: false,
        key: 'larguraFaixa'
      },
      percentLarguraFaixa: {
        name: "Largura da Faixa (%)",
        visible: false,
        key: 'percentLarguraFaixa'
      },
      vazaoContratada: {
        name: "Vazao Contratada (l)",
        visible: false,
        key: 'vazaoContratada'
      },
      areaPlantio: {
        name: "Área de Plantio (ha)",
        visible: false,
        key: 'areaPlantio'
      },
      insumos: {
        name: "Insumos (ha)",
        visible: false,
        key: 'insumos'
      },
      percentInsumos: {
        name: "Insumos (%)",
        visible: false,
        key: 'percentInsumos'
      },
      areaAplicada: {
        name: "Área Aplicada (ha)",
        visible: false,
        key: 'areaAplicada'
      },
      percentAreaAplicada: {
        name: "Área Aplicada (%)",
        visible: false,
        key: 'percentAreaAplicada'
      },
      areaNaoAplicada: {
        name: "Área não Aplicada (ha)",
        visible: false,
        key: 'areaNaoAplicada'
      },
      percentAreaNaoAplicada: {
        name: "Área não Aplicada (%)",
        visible: false,
        key: 'percentAreaNaoAplicada'
      },
      aplicacaoEfetiva: {
        name: "Aplicação Efetiva (ha)",
        visible: false,
        key: 'aplicacaoEfetiva'
      },
      percentAplicacaoEfetiva: {
        name: "Aplicação Efetiva (%)",
        visible: false,
        key: 'percentAplicacaoEfetiva'
      },
      aplicacaoExterna: {
        name: "Aplicação Externa (ha)",
        visible: false,
        key: 'aplicacaoExterna'
      },
      percentAplicacaoExterna: {
        name: "Aplicação Externa (%)",
        visible: false,
        key: 'percentAplicacaoExterna'
      },
      areaSobreposicao: {
        name: "Aplic. Sobreposta (ha)",
        visible: false,
        key: 'areaSobreposicao'
      },
      percentAreaSobreposicao: {
        name: "Aplic. Sobreposta (%)",
        visible: false,
        key: 'percentAreaSobreposicao'
      },
      taxaAplicacao: {
        name: "Taxa de Aplicação (l)",
        visible: false,
        key: 'taxaAplicacao'
      },
      percentTaxaAplicacao: {
        name: "Taxa de Aplicação (%)",
        visible: false,
        key: 'percentTaxaAplicacao'
      }
    }
  };
  
  
  render() {
    const sliced = [];
    const columnsArray = Object.values(this.state.columns);
    const columnsToExport = columnsArray.filter(el => el.visible);
    let items = columnsArray.map((el, index) => {
      if (this.props.authData.nivel === 'funcionario') {
        if (el.key === 'empresa' || el.key === 'createdAt'){
          sliced.push(index);
          return null;
        }
      }
      if (el.key === 'vazaoContratada' || el.key === 'faixaContratada' || el.key === 'faixaExecutada'){
        sliced.push(index);
        return null;
      }
      
      return (<FormCheckbox key={el.name} toggle checked={el.visible} onChange={() => {
        const columnsCopy = {...this.state.columns};
        columnsCopy[el.key].visible = !this.state.columns[el.key].visible;
        this.setState({
          ...this.state,
          columns: columnsCopy
        });
      }} small>{el.name}</FormCheckbox>)
    });
  
    console.log("ITEMS BEFORE", items);
  
    items = items.filter((el, index) => el !== null );
    console.log("ITEMS", items);
    console.log("SLICED", sliced);
    
    
    
    return (
      <Modal open={this.props.open} toggle={this.props.toggle} size="lg">
        <ModalHeader>Selecione as colunas para exportação da tabela:</ModalHeader>
        <ModalBody>
          <fieldset>
            <Row>
              <Col>{items.slice(0, 10)}</Col>
              <Col>{items.slice(10, 20)}</Col>
              <Col>{items.slice(20, items.length)}</Col>
            </Row>
          </fieldset>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          
          <div onClick={this.props.toggle}>
            <ReactExcel
              idButton={"export_excel"}
              buttonText={<p className="p-0 m-0">Excel <i className="fas fa-file-excel"/></p>}
              classButton={"btn btn-success"}
              nameFile={"laudos"}
              nameSheet={"sheet"}
              name={"laudos"}
              columns={columnsToExport}
              data={this.props.data}
              empresa={this.props.empresa}/>
          </div>
          
          <div onClick={this.props.toggle}>
            <ReactPDF
              idButton={"export_pdf"}
              buttonText={<p onClick={this.props.toggle} className="p-0 m-0">PDF <i className="fas fa-file-pdf"/></p>}
              classButton={"btn btn-danger"}
              nameFile={"laudos"}
              nameSheet={"sheet"}
              name={"laudos"}
              columns={columnsToExport}
              data={this.props.data}
              empresa={this.props.empresa}/>
          </div>
        </ModalFooter>
      </Modal>);
  }
  
};

export default ExcelSelectColumns;
