import React, {Component} from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormCheckbox,
  FormInput,
  FormRadio,
  FormTextarea,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {connect} from 'react-redux'

import PageTitle from "../components/common/PageTitle";
import * as actions from '../store/actions'
import contentArrays from "../utils/arrays";


import validator from 'validator'
import {toastr} from "react-redux-toastr";
import showToastr from "../store/actions/toastr";

class NewProtectedArea extends Component {
  
  state = {
    form: {
      classificacoes: {
        povoacao: {
          name: 'Povoação',
          buffer: 500
        },
        cidade: {
          name: 'Cidade',
          buffer: 500
        },
        vila: {
          name: 'Vila',
          buffer: 500
        },
        bairro: {
          name: 'Bairro',
          buffer: 500
        },
        manancialCaptacao: {
          name: 'Manancial de Captação',
          buffer: 500
        },
        manancial: {
          name: 'Manancial',
          buffer: 250
        },
        moradiaIsolada: {
          name: 'Moradia Isolada',
          buffer: 250
        },
        agrupamentoAnimais: {
          name: 'Agrupamento de Animais',
          buffer: 250
        },
        bichoSeda: {
          name: 'Sericicultura',
          buffer: 500
        },
        culturaVizinha: {
          name: 'Cultura Vizinha',
          buffer: null,
          custom: true
        },
        app: {
          name: 'APP',
          buffer: null,
          custom: true
        },
        reservaLegal: {
          name: 'Reserva Legal',
          buffer: null,
          custom: true
        },
        redeEletrica: {
          name: 'Rede Elétrica',
          buffer: 20,
          extra: 'Obstáculo'
        },
        torre: {
          name: 'Torre',
          buffer: 25,
          extra: 'Obstáculo'
        }
      },
      checkedClassification: 'povoacao',
      arquivoArea: {
        value: 'Escolha um arquivo...',
        valid: false,
        shouldValidate: true,
        touched: false,
        store: false,
        name: 'file_area',
        validator: {
          isFile: true
        }
      },
      empresa: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isIn: this.props.arrayEmpresas
        }
      },
      nome: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        name: 'nome',
        validator: {
          isLength: 3
        },
      },
      informacoes: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        name: 'identificacao',
        validator: {
          isLength: 3
        },
      },
      customBuffer: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isFloat: true
        }
      }
    },
  };
  
  componentDidMount() {
    this.props.onLoadCompaniesSimpleList();
  }
  
  inputChangedHandler(e, key) {
    console.log(key);
    console.log(this.props.misc);
    let obj = {...this.state.form[key]};
    let incomingValue = e.target.value;
    
    let valid = true;
    
    if (obj.validator) {
      if (obj.validator.isInObjArray) {
        this.objectPrestadoras = this.props.misc.prestadoras.reduce((arr, cur, i) => {
            const {id, nome, pilotos} = cur;
            console.log(id,
              nome,
              pilotos,
              cur);
            arr[id] = {
              nome: nome,
              pilotos: pilotos
            };
            return arr
          },
          {});
        valid = this.objectPrestadoras[incomingValue] && valid;
      }
      if (obj.validator.isEmail) {
        valid = validator.isEmail(incomingValue) && valid;
      }
      if (obj.validator.isLength) {
        valid = validator.isLength(incomingValue,
          {min: obj.validator.isLength}) && valid;
      }
      if (obj.validator.equals) {
        valid = validator.equals(incomingValue,
          obj.validator.equals) && valid;
      }
      if (obj.validator.isIn) {
        valid = validator.isIn(incomingValue,
          obj.validator.isIn) && valid;
      }
      if (obj.validator.isFloat) {
        valid = validator.isFloat(incomingValue) && valid;
      }
      if (obj.validator.isDate) {
        valid = new Date(incomingValue) < new Date() && valid;
      }
      if (obj.validator.isFile) {
        //incomingValue = incomingValue.files[0].name
        valid = e.target.files.length === 1 && valid;
        if (valid) {
          incomingValue = e.target.files[0].name;
          const splitValue = incomingValue.split('.');
          if (splitValue[splitValue.length - 1].toLowerCase() !== 'kml') {
            valid = false;
            incomingValue = 'Escolha um arquivo...'
          } else {
            obj.store = e.target.files[0]
            console.log(obj.store.size);
          }
        } else {
          incomingValue = 'Escolha um arquivo...'
        }
      }
    }
    
    obj.valid = valid;
    obj.value = incomingValue;
    obj.touched = true;
    
    console.log(obj);
    
    this.setState(oldState => ({
      form: {
        ...oldState.form,
        [key]: obj
      }
    }))
  }
  
  
  onChangeClassification = (key) => {
    const form = {...this.state.form};
    form.checkedClassification = key;
    this.setState({
      form: form
    })
  };
  
  missingFieldError = () => {
    toastr.warning('Verifique... ', 'Certifique-se que todos os campos estejam preenchidos corretamente', {position: 'top-right'});
  }
  
  onFormSendHandler = e => {
    e.preventDefault();
    if (!this.state.form.informacoes.valid){
      return this.missingFieldError();
    }if (!this.state.form.nome.valid){
      return this.missingFieldError();
    }if (!this.state.form.arquivoArea.valid){
      return this.missingFieldError();
    }if (!this.state.form.customBuffer.valid && this.state.form.classificacoes[this.state.form.checkedClassification].custom){
      return this.missingFieldError();
    }if (!this.state.form.empresa.valid || this.state.form.empresa.value === null) {
      return this.missingFieldError();
    }
  
    let formData = new FormData();
  
    formData.append('classificacao', this.state.form.checkedClassification);
    formData.append('nome', this.state.form.nome.value);
    formData.append('identificacao', this.state.form.informacoes.value);
    if (this.state.form.classificacoes[this.state.form.checkedClassification].custom) {
      formData.append('buffer', this.state.form.customBuffer.value);
    }
    formData.append('file_area_protecao', this.state.form.arquivoArea.store);
    formData.append('empresaId', this.state.form.empresa.value);
  
    this.props.onSendNewProtectedArea(formData);
    // this.props.onSendNewApplicationReport(formData, () => {
    //   this.setState({...this.state, ...this.initialState});
    //   const inputsList = document.getElementsByTagName('input');
    //   const selectsList = document.getElementsByTagName('select');
    //
    //   console.log('INPUTS: ', inputsList)
    //
    //   for (let input of inputsList) {
    //     input.value = ''
    //   }
    //
    //   for (let select of selectsList) {
    //     select.selectedIndex = 0;
    //   }
    //
    //   // inputsArray.forEach(input => {
    //   //   input.value = ''
    //   // })
    // });
    toastr.info('Cadastrando...', 'Enviando laudo para o sistema...', {position: 'top-right'});
    
  };
  
  
  render() {
    const itensClassificacao = [];
    for (let key in this.state.form.classificacoes) {
      const el = this.state.form.classificacoes[key];
      itensClassificacao.push(<FormRadio
        name="classificacao"
        value={key}
        key={key}
        onChange={() => this.onChangeClassification(key)}
        checked={this.state.form.checkedClassification === key}>{el.extra ? el.extra + ' - ' : ''}{el.name}
        <small> {el.buffer ? el.buffer + 'm' : 'A definir'}</small>
      </FormRadio>)
    }
    
    return (<Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Área de Proteção / Obstáculo" subtitle="Cadastro" className="text-sm-left"/>
      </Row>
      
      <Row>
        <Col lg={{ size: 8, offset: 2 }} sm={12}>
          <Card small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Dados Cadastrais</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem>
                <Row>
                  <Col>
                    <Form>
                      <ListGroupItem className="p-3">
                        
                        <Row form>
                          <Col className="pb-3" md={6}>
                            <label htmlFor="inputBairro">Classificação</label>
                            {itensClassificacao}
                          </Col>
                          
                          <Col md="6">
                            <Row form>
                              <Col className="form-group">
                                <label htmlFor="inputSelectEmpresa">Empresa</label>
                                <FormSelect
                                  id="inputSelectEmpresa"
                                  invalid={!this.state.form.empresa.valid && this.state.form.empresa.touched && this.state.form.empresa.shouldValidate}
                                  onChange={(event) => this.inputChangedHandler(event,
                                    'empresa')}>
                                  <option>Carregando...</option>
                                  {this.props.misc.empresas ? this.props.misc.empresas.map(el => {
                                    if (el.nome.includes('[MASTER]')) {
                                      return null;
                                    }
                                    return (
                                      <option value={el.id}
                                              key={el.id}>{el.nome}</option>)
                                  }) : null}
                                </FormSelect>
                              </Col>
                            </Row>
                            <Row form>
                              <Col className="form-group">
                                <label htmlFor="inputNomeExibicao">Nome</label>
                                <FormInput
                                  id="inputNomeExibicao"
                                  placeholder="Apiário Sr. João"
                                  invalid={!this.state.form.nome.valid && this.state.form.nome.touched && this.state.form.nome.shouldValidate}
                                  onChange={(event) => this.inputChangedHandler(event,
                                    'nome')}/>
                              </Col>
                            </Row>
                            <Row form>
                              <Col className="form-group">
                                <label htmlFor="inputNomeExibicao">Mais informações</label>
                                <FormTextarea
                                  id="inputNomeExibicao"
                                  style={{minHeight: '70px'}}
                                  placeholder={"Telefone: (99) 9999 9999 \nEmail: sr.joao@email.com \nObservações: ..."}
                                  invalid={!this.state.form.informacoes.valid && this.state.form.informacoes.touched && this.state.form.informacoes.shouldValidate}
                                  onChange={(event) => this.inputChangedHandler(event,
                                    'informacoes')}/>
                              </Col>
                            </Row>
                            {
                              this.state.form.classificacoes[this.state.form.checkedClassification].custom ? <Row form>
                                <Col className="form-group">
                                  <label htmlFor="inputCustomBuffer">Distância em metros</label>
                                  <FormInput
                                    id="inputCustomBuffer"
                                    type="number"
                                    placeholder="250"
                                    step={10}
                                    invalid={!this.state.form.customBuffer.valid && this.state.form.customBuffer.touched && this.state.form.customBuffer.shouldValidate}
                                    onChange={(event) => this.inputChangedHandler(event,
                                      'customBuffer')}/>
                                </Col>
                              </Row> : null
                            }
                            <Row form>
                              <Col className="form-group">
                                <label htmlFor="">Traçado da Área (.kml)</label>
                                <div className="custom-file mb-3">
                                  <input type="file"
                                         className={(!this.state.form.arquivoArea.valid && this.state.form.arquivoArea.touched) ? "custom-file-input is-invalid" : "custom-file-input"}
                                         onChange={(event) => this.inputChangedHandler(event,
                                           'arquivoArea')}/>
                                  <label className="custom-file-label" htmlFor="arquivoArea">
                                    {this.state.form.arquivoArea.value}
                                  </label>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      
                      
                      <ListGroupItem className="p-3">
                        <Row>
                          <small style={{color: 'red'}}>{this.props.misc.submit.user.error}</small>
                        </Row>
                        <Button type="submit" theme="success" disabled={!this.props.sentRequest}
                                onClick={event => this.onFormSendHandler(event)}>Cadastrar</Button>
                      </ListGroupItem>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>)
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onSendNewProtectedArea: (formData) => dispatch(actions.sendNewProtectedArea(formData)),
    onLoadCompaniesSimpleList: () => dispatch(actions.requestCompaniesSimpleList()),
  }
};
const mapStateToProps = state => {
  return {
    misc: state.misc,
    sentRequest: state.progressBar.percentage === 0
  
  }
};

export default connect(mapStateToProps,
  mapDispatchToProps)(
  NewProtectedArea);
