import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
  submit:{
    user: {
      error: false
    },
    company:{
      error: false
    },
    users: []
  },
  ambiental: []
};

const fetchCompaniesSimpleList = (state, action) => {
  return updateObject(state, {
    empresas: action.payload.data
  });
};

const fetchProvidersSimpleList = (state, action) => {
  return updateObject(state, {
    prestadoras: action.payload.data
  });
};

const fetchReportsList = (state, action) => {
  return updateObject(state, action.payload);
};

const fetchPrestadorSimpleList = (state, action) => {
  return updateObject(state, {
    prestadores: action.payload.data
  });
};

const fetchTipoAplicacaoList = (state, action) => {
  return updateObject(state, {
    tipoAplicacoes: action.payload.data
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MISC_FETCH_COMPANIES: return fetchCompaniesSimpleList(state, action);
    case actionTypes.MISC_FETCH_PROVIDERS: return fetchProvidersSimpleList(state, action);
    case actionTypes.MISC_FETCH_REPORTS: return fetchReportsList(state, action);
    case actionTypes.MISC_FETCH_AMBIENTAL: return fetchReportsList(state, action);
    case actionTypes.MISC_FETCH_OS_LIST: return fetchReportsList(state, action);
    case actionTypes.REMOVE_OS_FROM_LIST:
      return {
        ...state,
        osList: state.osList.filter(el => el.uuid !== action.payload)
      };
    case actionTypes.REMOVE_USER_FROM_ID:
      return {
        ...state,
        users: state.users.filter(el => el.id !== action.payload)
      };
    case actionTypes.REMOVE_REPORT_UUID:
      return {
        ...state,
        ambiental: state.ambiental.filter(el => el.uuid !== action.payload)
      };
    case actionTypes.FETCH_USERS:
      return {
        ...state,
        users: action.payload
      }
    case actionTypes.LOCATION_ADD_NEW: return { ...state, empresas: [...action.payload] };
    
    case actionTypes.MISC_START_LOADING_FILTERED_FETCH__REPORTS:
      return updateObject(state, {loading: true});
    case actionTypes.MISC_SUCCESS_LOADING_FILTERED_FETCH__REPORTS:
      return updateObject(state, {...action.payload, loading: false});
    case actionTypes.MISC_ERROR_LOADING_FILTERED_FETCH__REPORTS:
      return updateObject(state, {loading: false});      
    case actionTypes.MISC_FETCH_PRESTADOR: 
      return fetchPrestadorSimpleList(state, action);
    case actionTypes.MISC_FETCH_TIPOAPLICACAO: 
      return fetchTipoAplicacaoList(state, action);
    default: return state;
  }
};

export default reducer;
