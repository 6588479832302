import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../../shared/utility";

const initialState = {
  collections: null,
  list: [],
  selected: ''
};
//
// const reportLoadNew = (state, action) => {
//   return updateObject(state, action.payload);
// };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AMBIENTAL_FETCH_PROTECTION_AREAS: {
      return updateObject(state, action.payload);
    }
    case actionTypes.AMBIENTAL_SUCCESS_LOADING_LIST: {
      return updateObject(state, action.payload)
    }
    case actionTypes.AMBIENTAL_START_LOADING_NEW: {
      return updateObject(state, action.payload)
    }
    case actionTypes.AMBIENTAL_SUCCESS_LOADING_NEW: {
      return updateObject(state, action.payload)
    }
    case actionTypes.AMBIENTAL_DENY_OR_APPROVE: {
      const newState = {...state};
      newState[action.payload.uuid].hidden = action.payload.hidden;
      const elIndex = newState.list.findIndex(el => el.uuid === action.payload.uuid);
      newState.list[elIndex].hidden = action.payload.hidden;
      
      return updateObject(state, newState)
    }
    default:
      return state
  }
};

export default reducer;
