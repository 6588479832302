import React, {Component} from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormCheckbox,
  FormInput,
  FormRadio,
  FormTextarea,
  FormSelect,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import {connect} from 'react-redux'

import PageTitle from "../components/common/PageTitle";
import * as actions from '../store/actions'
import contentArrays from "../utils/arrays";


import validator from 'validator'
import {toastr} from "react-redux-toastr";
import showToastr from "../store/actions/toastr";

class NewLocation extends Component {

  state = {
    form: {
      classificacoes: {
        povoacao: {
          name: 'Povoação',
          buffer: 500
        },
        cidade: {
          name: 'Cidade',
          buffer: 500
        },
        vila: {
          name: 'Vila',
          buffer: 500
        },
        bairro: {
          name: 'Bairro',
          buffer: 500
        },
        manancialCaptacao: {
          name: 'Manancial de Captação',
          buffer: 500
        },
        manancial: {
          name: 'Manancial',
          buffer: 250
        },
        moradiaIsolada: {
          name: 'Moradia Isolada',
          buffer: 250
        },
        agrupamentoAnimais: {
          name: 'Agrupamento de Animais',
          buffer: 250
        },
        bichoSeda: {
          name: 'Sericicultura',
          buffer: 500
        },
        culturaVizinha: {
          name: 'Cultura Vizinha',
          buffer: null,
          custom: true
        },
        app: {
          name: 'APP',
          buffer: null,
          custom: true
        },
        reservaLegal: {
          name: 'Reserva Legal',
          buffer: null,
          custom: true
        },
        redeEletrica: {
          name: 'Rede Elétrica',
          buffer: 20,
          extra: 'Obstáculo'
        },
        torre: {
          name: 'Torre',
          buffer: 25,
          extra: 'Obstáculo'
        }
      },
      checkedClassification: 'povoacao',
      arquivoArea: {
        value: 'Escolha um arquivo...',
        valid: false,
        shouldValidate: true,
        touched: false,
        store: false,
        name: 'file_area',
        validator: {
          isFile: true
        }
      },
      empresa: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isIn: this.props.arrayEmpresas
        }
      },
      nome: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        name: 'nome',
        validator: {
          isLength: 3
        },
      },
      informacoes: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        name: 'identificacao',
        validator: {
          isLength: 3
        },
      },
      customBuffer: {
        value: null,
        valid: false,
        shouldValidate: true,
        touched: false,
        validator: {
          isFloat: true
        }
      },
      regiao: {
        value: "",
        valid: false,
        touched: false
      }
    },
  };

  componentDidMount() {
    this.props.onLoadCompaniesSimpleList();
  }

  inputChangedHandler(e, key) {
    const newObj = {};

    if ((this.props.misc.empresas.findIndex(el => el.nome === "ADECOAGRO ["+e.target.value.toUpperCase()+"]") !== -1) || e.target.value === ""){
      newObj.valid = false;
      newObj.touched = true;
    }else {
      newObj.valid = true;
      newObj.touched = true;
    }
    newObj.value = e.target.value.toUpperCase();

    const matchReg = e.target.value.toUpperCase().match(/[a-z]+/i);
    if (matchReg) {
      if (matchReg[0] === matchReg.input) newObj.valid = true && newObj.valid
      else newObj.valid = false
    }else {
      newObj.valid = false
    }

    console.log(newObj)

    this.setState({
      form: {
        ...this.state.form,
        regiao: newObj
      }
    })
  }


  onChangeClassification = (key) => {
    const form = {...this.state.form};
    form.checkedClassification = key;
    this.setState({
      form: form
    })
  };

  missingFieldError = () => {
    toastr.warning('Verifique... ',
      'Certifique-se que a identificação da nova região esteja preenchida corretamente',
      {position: 'top-right'});
  };

  onFormSendHandler = e => {
    e.preventDefault();
    if (!this.state.form.regiao.valid) {
      return this.missingFieldError();
    }

    let formData = new FormData();

    formData.append('regiao',
      this.state.form.regiao.value);

    this.props.onSendNewLocation(formData);
    //
    // toastr.info('Cadastrando...',
    //   'Enviando região para o sistema...',
    //   {position: 'top-right'});

  };


  render() {
    let mapRegioesCadastradas;
    if (this.props.misc.empresas) {
      console.log(this.props.misc.empresas.filter(el => el.nome.match(/\[[a-z]+\]/i)));
      const regioesCadastradas = this.props.misc.empresas.filter(el => el.nome.match(/\[[a-z]+\]/i));
      mapRegioesCadastradas = regioesCadastradas.map((el, idx) => <ListGroupItem key={idx}>{el.nome}</ListGroupItem>)
    } else {
      mapRegioesCadastradas = <ListGroupItem>Carregando...</ListGroupItem>
    }

    return (<Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title="Cadastro de Regiões Adecoagro" subtitle="Cadastro" className="text-sm-left"/>
      </Row>

      <Row>
        <Col lg={{
          size: 10,
          offset: 1
        }} sm={12}>
          <Card small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Dados</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem>
                <Row>
                  <Col>
                    <Form>
                      <ListGroupItem className="p-3">

                        <Row form>
                          <Col className="pb-3" md={6}>
                            <label htmlFor="inputBairro">Regiões Cadastradas</label>
                            <Row>
                              <Col offset={1} md={10}>
                                <ListGroup style={{textAlign: "center"}}>
                                  {mapRegioesCadastradas}
                                </ListGroup>
                              </Col>
                            </Row>
                          </Col>

                          <Col md="6">
                            <Row form>
                              <Col className="form-group">
                                <label htmlFor="inputNomeExibicao">Identificação da nova região</label>
                                <FormInput
                                  id="inputNomeExibicao"
                                  placeholder="A, B, C, D... X, Y, Z"
                                  invalid={!this.state.form.regiao.valid && this.state.form.regiao.touched}
                                  value={this.state.form.regiao.value}
                                  onChange={(event) => this.inputChangedHandler(event,
                                    'nome')}/>
                                {!this.state.form.regiao.valid && this.state.form.regiao.touched ? <small style={{color: "red"}}>Verifique se esta região já não foi cadastrada ou se o campo está preenchido corretamente</small> : null}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </ListGroupItem>


                      <ListGroupItem className="p-3">
                        <Row>
                          <small style={{color: 'red'}}>{this.props.misc.submit.user.error}</small>
                        </Row>
                        <Button type="submit" theme="success" disabled={!this.props.sentRequest || !this.state.form.regiao.valid}
                                onClick={event => this.onFormSendHandler(event)}>Cadastrar</Button>
                      </ListGroupItem>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    onSendNewLocation: (formData) => dispatch(actions.sendNewLocation(formData)),
    onLoadCompaniesSimpleList: () => dispatch(actions.requestCompaniesSimpleList()),
  }
};
const mapStateToProps = state => {
  return {
    misc: state.misc,
    sentRequest: state.progressBar.percentage === 0

  }
};

export default connect(mapStateToProps,
  mapDispatchToProps)(
  NewLocation);
