import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
} from "shards-react";

import Chart from "../../utils/chart";
import colors from "../../utils/colors";

class LogHistoryBar extends React.Component {
  constructor(props) {
    super(props);

    this.legendRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  buildChart = () => {
    this.chartData = {
      labels: this.props.entireChartData.info.map(value => ("")),
      datasets: [
        {
          label: "Apl. Efetiva",
          fill: "start",
          data: this.props.entireChartData.efetiva,
          icons: '<i class="fas fa-minus"/>',
          backgroundColor: colors.success.toRGBA(0.7),
          borderColor: colors.success.toRGBA(0.7),
          pointBackgroundColor: colors.white.toHex(),
          pointHoverBackgroundColor: colors.success.toRGBA(0.7),
          borderWidth: 1.5,
          hidden: false,
        },
        {
          label: "Apl. Sobreposta",
          fill: "start",
          data: this.props.entireChartData.sobreposta,
          icons: '<i class="fas fa-minus"/>',
          backgroundColor: colors.warning.toRGBA(0.7),
          borderColor: colors.warning.toRGBA(0.7),
          pointBackgroundColor: colors.white.toHex(),
          pointHoverBackgroundColor: colors.warning.toRGBA(0.7),
          borderWidth: 1.5,
          hidden: false,
        },
        {
          label: "Apl. Externa",
          fill: "start",
          data: this.props.entireChartData.externa,
          icons: '<i class="fas fa-minus"/>',
          backgroundColor: colors.danger.toRGBA(0.7),
          borderColor: colors.danger.toRGBA(0.7),
          pointBackgroundColor: colors.white.toHex(),
          pointHoverBackgroundColor: colors.danger.toRGBA(0.7),
          borderWidth: 1.5,
          hidden: false,
        }
      ]
    };


    const chartOptions = {
      ...{
        responsive: true,
        legend: false,
        // Uncomment the next line in order to disable the animations.
        // animation: false,
        tooltips: {
          enabled: false,
          mode: "index",
          position: "nearest",
          callbacks: {
            title: (tooltipItems, fieldData) => {
              const {codigo, data} = this.props.entireChartData.info[tooltipItems[0].index];
              const dataObj = new Date(data + " 00:00:01");
              return ['CÓDIGO: <strong>' + codigo + '</strong>', '<p class="mb-0 pb-0 mt-0 pt-0 font-weight-normal">' + dataObj.toLocaleDateString('pt-BR') + '</p>']
            },
            label: (tooltipItem, data) => {
              var label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label) {
                label += ': ';
              }
              label += tooltipItem.yLabel + '%';
              return label;
            },
            footer: () => {
              return 'Clique para visualizar laudo.'
            }
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: false
            }
          ],
          yAxes: [
            {
              stacked: true,
              ticks: {
                max: 100,
                callback: (value, index, values) => {
                  return value + '%';
                }
              }
            }
          ]
        },
        onClick: this.onChartClick
      },
      ...this.props.chartOptions
    };


    const SalesReportChart = new Chart(this.canvasRef.current, {
      type: "bar",
      data: this.chartData,
      options: chartOptions
    });

    // Generate the chart labels.
    this.legendRef.current.innerHTML = SalesReportChart.generateLegend();

    // Hide initially the first and last chart points.
    // They can still be triggered on hover.
    const meta = SalesReportChart.getDatasetMeta(0);
    meta.data[0]._model.radius = 0;
    meta.data[
    this.chartData.datasets[0].data.length - 1
      ]._model.radius = 0;


    // Render the chart.
    SalesReportChart.render();

    this.setState({...this.state, chartCtx: SalesReportChart});
  }

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.entireChartData !== prevProps.entireChartData) {
      this.state.chartCtx.destroy();
      this.buildChart();
    }
  }

  onChartClick = (evt, item) => {
    if (!item[0]) {
      return
    }
    console.log('position', item[0]._index);
    console.log(this.props, this)
    this.props.onItemClick(this.props.entireChartData.info[item[0]._index].uuid);
  };

  render() {
    const {title} = this.props;

    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
          <div className="block-handle"/>
        </CardHeader>

        <CardBody className="pt-0">
          {/*<Row className="border-bottom py-2 bg-light">*/}
          {/*/!* Time Interval *!/*/}
          {/*<Col sm="6" className="col d-flex mb-2 mb-sm-0">*/}
          {/*<ButtonGroup>*/}
          {/*<Button theme="white">Hour</Button>*/}
          {/*<Button theme="white">Day</Button>*/}
          {/*<Button theme="white">Week</Button>*/}
          {/*<Button theme="white" active>*/}
          {/*Month*/}
          {/*</Button>*/}
          {/*</ButtonGroup>*/}
          {/*</Col>*/}

          {/*/!* DatePicker *!/*/}
          {/*<Col sm="6" className="col">*/}
          {/*<RangeDatePicker className="justify-content-end"/>*/}
          {/*</Col>*/}
          {/*</Row>*/}
          <div ref={this.legendRef}/>
          <canvas
            height="120"
            ref={this.canvasRef}
            style={{maxWidth: "100% !important"}}
            className=""
          />
        </CardBody>
      </Card>
    );
  }
}

LogHistoryBar.propTypes = {
  /**
   * The title of the component.
   */
  title: PropTypes.string,
  /**
   * The chart data.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};
const fakeData = {
  "efetiva": [
    90.56,
    87.52,
    86.74,
    90.56,
    90.56,
    74.66,
    92.06,
    91.81,
    87.52,
    91.81,
    89.26,
    90.56,
    92.96
  ],
  "sobreposta": [
    4.19,
    5.36,
    3.53,
    4.19,
    4.19,
    2.43,
    4.57,
    5.41,
    5.36,
    5.41,
    4.74,
    4.19,
    4.99
  ],
  "externa": [
    5.25,
    7.11,
    9.73,
    5.25,
    5.25,
    22.91,
    3.37,
    2.78,
    7.11,
    2.78,
    6,
    5.25,
    2.05
  ],
  "info": [
    {
      "data": "1999-02-10",
      "codigo": "ASDASDASD",
      "uuid": "345dd030-912d-11e9-a673-47120fd066d3"
    },
    {
      "data": "2019-05-28",
      "codigo": "DHASBKHJDHKJAS",
      "uuid": "d17925e0-8d18-11e9-a3e0-5d2026a9ae18"
    },
    {
      "data": "2019-06-02",
      "codigo": "D321SA32D13A21D32A23SD1A23",
      "uuid": "67b3c9a0-8f8c-11e9-bd15-1d46dfc71a4d"
    },
    {
      "data": "2019-06-02",
      "codigo": "DASDASDASD",
      "uuid": "6ed094f0-912d-11e9-ac21-7b61f39d7055"
    },
    {
      "data": "2019-06-02",
      "codigo": "TRAJETO",
      "uuid": "67515800-9131-11e9-ac21-7b61f39d7055"
    },
    {
      "data": "2019-06-03",
      "codigo": "ASDASDASD",
      "uuid": "fcaea1c0-8f8b-11e9-bd15-1d46dfc71a4d"
    },
    {
      "data": "2019-06-03",
      "codigo": "'3D21AS32D1A32S1D",
      "uuid": "3faaeab0-8f8c-11e9-bd15-1d46dfc71a4d"
    },
    {
      "data": "2019-06-03",
      "codigo": "D32AS132DA13S2D1A32SD13",
      "uuid": "8bdd4b80-8f8c-11e9-bd15-1d46dfc71a4d"
    },
    {
      "data": "2019-06-05",
      "codigo": "D321AS32D1AS32D1A32SD1",
      "uuid": "7c1e3a60-8f8c-11e9-bd15-1d46dfc71a4d"
    },
    {
      "data": "2019-06-05",
      "codigo": "ASDASDASDAS",
      "uuid": "0fc0ecb0-9125-11e9-bbbd-6bdb1ea761c3"
    },
    {
      "data": "2019-06-09",
      "codigo": "3D2A132D1A3S2D1A32D1",
      "uuid": "550b0610-8f8c-11e9-bd15-1d46dfc71a4d"
    },
    {
      "data": "2019-06-10",
      "codigo": "COM TRAJETO",
      "uuid": "6bb63260-9129-11e9-bbbd-6bdb1ea761c3"
    },
    {
      "data": "2019-06-11",
      "codigo": "231321321",
      "uuid": "1603ec20-8f8c-11e9-bd15-1d46dfc71a4d"
    }
  ]
}

LogHistoryBar.defaultProps = {
  title: "Indices / Laudos",
}
export default LogHistoryBar;
