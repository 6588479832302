import React, {Component} from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormInput,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import RangeDatePicker from "../components/common/RangeDatePicker";
import {connect} from 'react-redux'
import ReactTable from "react-table";
import dateFormat from "dateformat";
import FuzzySearch from "fuzzy-search";
import * as actions from '../store/actions'
import {toastr} from "react-redux-toastr";


class ShowAmbientalReportsList extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      // Data Tabale
      tableData: [
      //   {
      //   "id": 1,
      //   "codigo": "OS 1234",
      //   "propriedade": "FAZ. SANTA MARIA", // "os": "ordem-servico-1",
      //   "date": "2019-01-01",
      //   "umidadeRelativa": 10,
      //   "velocidadeVento": 20,
      //   "dpv": "nne",
      //   "temperatura": 40,
      // }, {
      //   "id": 2,
      //   "codigo": "OS 4567",
      //   "propriedade": "FAZ. RIO DOCE", // "os": "ordem-servico-2",
      //   "date": "2019-04-20",
      //   "umidadeRelativa": 20,
      //   "velocidadeVento": 30,
      //   "dpv": "sso",
      //   "temperatura": 50,
      // }, {
      //   "id": 3,
      //   "codigo": "OS 7890",
      //   "propriedade": "FAZ. LUIZ FIGUEIRO", // "os": "ordem-servico-3",
      //   "date": "2019-03-09",
      //   "umidadeRelativa": 30,
      //   "velocidadeVento": 40,
      //   "dpv": "o",
      //   "temperatura": 60,
      // }, {
      //   "id": 4,
      //   "codigo": "OS 3456",
      //   "propriedade": "FAZ. SOL NASCENTE", // "os": "ordem-servico-4",
      //   "date": "2019-04-16",
      //   "umidadeRelativa": 40,
      //   "velocidadeVento": 50,
      //   "dpv": "ese",
      //   "temperatura": 70,
      // }, {
      //   "id": 5,
      //   "codigo": "OS 6789",
      //   "propriedade": "FAZ. LAGOA AZUL", // "os": "ordem-servico-5",
      //   "date": "2019-05-25",
      //   "umidadeRelativa": 50,
      //   "velocidadeVento": 60,
      //   "dpv": "e",
      //   "temperatura": 80,
      // },
      ], //Columns Table
      columns: [{
        Header: "ID",
        accessor: "id",
        minWidth: 150,
        show: false,
        className: "text-center",
      }, {
        Header: "OS",
        accessor: "os",
        minWidth: 150,
        className: "text-center",
      }, {
        Header: "Propriedade",
        accessor: "propriedade",
        minWidth: 150,
        className: "text-center",
      }, // {
        //   Header: "Ordem de Serviço",
        //   accessor: "os",
        //   minWidth: 150,
        //   className: "text-center",
        // },
        {
          Header: "Data",
          accessor: "data",
          className: "text-center",
          minWidth: 150,
          Cell: row => {
            let date = new Date(row.original.data);
            date.setDate(date.getDate() + 1);
            return date.toLocaleDateString('pt-BR');
          },
          Filter: ({filter, onChange}) => <input
            type={"date"}
            className={"text-center w-auto"}
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : "all"}>
          </input>,
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            } else {
              return row[filter.id] === filter.value;
            }
          },
        }, {
          Header: "Umidade Relativa",
          accessor: "umidadeRelativa",
          minWidth: 150,
          className: "text-center",
          Cell: row => {
            return (<span>{row.original.umidadeRelativa}%</span>);
          },
        }, {
          Header: "Velocidade do Vento",
          accessor: "velocidadeVento",
          minWidth: 150,
          className: "text-center",
          Cell: row => {
            return (<span>{row.original.velocidadeVento} km/h</span>);
          },
        }, {
          Header: "DPV",
          accessor: "dpv",
          minWidth: 150,
          className: "text-center",
          Cell: row => {
            return (<span>{row.original.dpv.toUpperCase()}</span>);
          },
          Filter: ({filter, onChange}) => <select
            onChange={event => onChange(event.target.value)}
            style={{width: "100%"}}
            value={filter ? filter.value : "all"}>
            <option value="all">Todos</option>
            <option value="n">N</option>
            <option value="nne">NNE</option>
            <option value="ne">NE</option>
            <option value="ene">ENE</option>
            <option value="l">L</option>
            <option value="ese">ESE</option>
            <option value="se">SE</option>
            <option value="sse">SSE</option>
            <option value="s">S</option>
            <option value="sso">SSO</option>
            <option value="so">SO</option>
            <option value="oso">OSO</option>
            <option value="o">O</option>
            <option value="ono">ONO</option>
            <option value="no">NO</option>
            <option value="nno">NNO</option>
          </select>,
          filterMethod: (filter, row) => {
            if (filter.value === "all") {
              return true;
            }
            return row[filter.id] === filter.value;
          }
        }, {
          Header: "Temperatura (°C)",
          accessor: "temperatura",
          minWidth: 150,
          className: "text-center",
          Cell: row => {
            return (<span>{row.original.temperatura}°</span>);
          },
        }, {
          Header: "Action",
          accessor: "actions",
          className: "text-center",
          minWidth: 150,
          sortable: false,
          filterable: false,
          show: this.props.isGM,
          Cell: row => {
            if (this.state.tableData.length > 0) {
              return (<ButtonGroup size="sm" className="d-table mx-auto">
                <Button theme="white" onClick={() => this.handleItemView(row)}>
                  <i className="material-icons">visibility</i>
                </Button>
                <Button theme="white" onClick={() => this.handleItemApprove(row)}>
                  <i className="material-icons">done</i>
                </Button>
                <Button theme="danger" onClick={() => this.handleItemDeny(row)}>
                  <i className="material-icons">not_interested</i>
                </Button>
              </ButtonGroup>)
            } else {
              return null;
            }
          },
        },], // Control Table
      pageSizeOptions: [5, 10, 15, 20, 25, 30],
      pageSize: 10,
      sorted: [],
      expanded: {},
      resized: [],
      filtered: [],
    };
    
    this.searcher = null;
    
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);
    this.handleItemView = this.handleItemView.bind(this);
    this.handleItemApprove = this.handleItemApprove.bind(this);
    this.handleItemDeny = this.handleItemDeny.bind(this);
    
  };
  
  componentWillMount() {
  };
  
  handleItemApprove(row) {
    const row_key = row.row._index;
    const newData = this.state.tableData;
  
    this.props.onDenyOrApprove(newData[row_key].uuid, 'approve');
  
    const log_deleted = newData.splice(row_key,
      1);
  
  
    this.setState({
      ...this.state,
      tableData: newData
    });
  
    toastr.success('Aprovado!',
      'A aplicação '+ log_deleted[0].os +' - ' + log_deleted[0].propriedade + ' foi liberada para visualização!');
    
  }
  handleItemDeny(row) {
    const row_key = row.row._index;
    const newData = this.state.tableData;
  
    //this.props.onDeleteReport(newData[row_key].uuid,newData[row_key].hidden);
    this.props.onDenyOrApprove(newData[row_key].uuid, 'deny');
  
    const log_deleted = newData.splice(row_key,
      1);
  
  
    this.setState({
      ...this.state,
      tableData: newData
    });
  
    toastr.info('Aprovado!',
      'A aplicação '+ log_deleted[0].os +' - ' + log_deleted[0].propriedade + ' foi recusada!');
    
  }
  
  
  componentDidMount() {
    this.props.onFetchAmbientalList();
    this.searcher = new FuzzySearch(this.state.tableData,
      ["os", "propriedade", "dpv",],
      {
        caseSensitive: false
      });
  };
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.ambientalList !== prevProps.ambientalList) {
      this.setState(
        {tableData: this.props.ambientalList}
      )
    }
  }
  
  /**
   * Handles the page size change event.
   */
  handlePageSizeChange(e) {
    this.setState({
      ...this.state,
      pageSize: e.target.value
    });
  }
  
  /**
   * Handles the global search.
   */
  handleFilterSearch(e) {
    this.setState({
      ...this.state,
      tableData: this.searcher.search(e.target.value)
    });
  }
  
  /**
   * Mock method for details log.
   */
  handleItemView(row) {
    this.props.history.push('/map/ambiental');
    const row_key = row.row._index;
    this.props.onLoadAmbientalFromUUID(this.state.tableData[row_key].uuid, !this.props.ambiental[this.state.tableData[row_key].uuid])
  }
  
  render() {
    
    let {tableData, columns, pageSize, pageSizeOptions, sorted, expanded, resized, filtered} = this.state;
    
    return (<Container fluid className="main-content-container px-4 pb-4">
        
        <Row noGutters className="page-header py-4">
          <PageTitle title={this.props.isGM ? "Aplicações Pendentes" : "Aplicações"} subtitle="ADECOAGRO" className="text-sm-left mb-3"/>
          {/*<Col sm="4" className="d-flex ml-auto my-auto">*/}
          {/*<RangeDatePicker className="justify-content-end"/>*/}
          {/*</Col>*/}
        </Row>
        
        <Card className="p-0">
          <CardHeader className="p-0">
            <Container fluid className="file-manager__filters border-bottom">
              <Row>
                
                {/* Filters :: Page Size */}
                <Col className="file-manager__filters__rows d-flex" md="4">
                  <span>Exibir</span>
                  <FormSelect
                    size="sm"
                    value={this.state.pageSize}
                    onChange={this.handlePageSizeChange}>
                    {pageSizeOptions.map((size, idx) => (<option key={idx} value={size}>
                        {size} linhas
                      </option>))}
                  </FormSelect>
                </Col>
                
                <Col className="d-flex" md="4" style={{textAlign: "center"}}>
                  <em>
                    <small>Obs: Mantenha "Shift" pressionado para ordenar mais de uma coluna.</small>
                  </em>
                </Col>
                
                {/* Filters :: Search */}
                <Col className="file-manager__filters__search d-flex" md="4">
                  <InputGroup seamless size="sm" className="ml-auto">
                    <InputGroupAddon type="prepend">
                      <InputGroupText>
                        <i className="material-icons">search</i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <FormInput onChange={this.handleFilterSearch}/>
                  </InputGroup>
                </Col>
              
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-0">
            <div className="">
              <ReactTable
                id={"sp-reports-history-table"}
                // Data
                columns={columns}
                data={tableData}
                // Style
                style={{height: "605px"}}
                // Text
                previousText={'Anterior'}
                nextText={'Próximo'}
                loadingText={'Carregando dados...'}
                noDataText={'Nenhum informação encontrada! :('}
                pageText={'Página'}
                ofText={'de'}
                rowsText={'linhas'}
                // Accessibility Labels
                pageJumpText={'jump to page'}
                rowsSelectorText={'rows per page'}
                // Controlled table
                pivotBy={[]}
                filterable={true}
                resizable={true}
                showPageSizeOptions={false}
                defaultPageSize={this.state.pageSize}
                pageSize={pageSize}
                showPageJump={true}
                loading={false}
                collapseOnSortingChange={true}
                collapseOnPageChange={true}
                collapseOnDataChange={true}
                multiSort={true}
                // Controlled props
                expanded={expanded}
                resized={resized}
                filtered={filtered}
                sorted={sorted}
                className={"-shadow"}
                // Callbacks
                onSortedChange={sorted => this.setState({sorted})}
                onPageChange={page => this.setState({page})}
                onPageSizeChange={(pageSize, page) => this.setState({
                  page,
                  pageSize
                })}
                onExpandedChange={expanded => this.setState({expanded})}
                onResizedChange={resized => this.setState({resized})}
                onFilteredChange={filtered => this.setState({filtered})}
              />
            </div>
          </CardBody>
        </Card>
      
      </Container>);
  }
  
}

const mapStateToProps = state => {
  return {
    ambientalList: state.misc.ambiental,
    ambiental: state.ambiental,
    isGM: String(state.auth.userInfo.funcao).includes('[2]')
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchAmbientalList: () => dispatch(actions.fetchAmbientalList()),
    onDenyOrApprove: (uuid, state) => dispatch(actions.denyOrApproveAmbientalData(uuid, state)),
    onLoadAmbientalFromUUID: (uuid, state) => dispatch(actions.getAmbientalFromUUID(uuid, state))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowAmbientalReportsList);
